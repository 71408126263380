import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Select, Tag,
} from 'antd';
import {
  GroupOutlined,
  FormOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import AnalyticsChartTypePicker from './AnalyticsChartTypePicker';
import AnalyticsBreakDownTag from './AnalyticsBreakDownTag';
import AnalyticsGroups from './AnalyticsGroups';
import AnalyticsDatePicker from './AnalyticsDatePicker';
import AnalyticsDateFieldBreakdownSelector from './AnalyticsDateFieldBreakdownSelector';

import { getIdMap } from '../helpers/helpers';

import {
  getBoards,
  getBoardCardTemplates,
} from '../boards/state/boards.actions';
import { getTemplateDetails, getStatuses } from '../forms/state/forms.actions';
import {
  setAnalyticsConfig,
  updateAnalyticsFilters,
} from './state/analytics.actions';

import {
  BASE_FIELDS,
  FIELD_TYPES,
  AGGREGATE_OPTS,
  BREAKDOWN_OPTS,
  FORM_FIELD_BREAKDOWN_OPTS,
  BREAKDOWN_FILTER_SET,
  FIELD_BREAKDOWN_CHARTS,
  FIELDS_WITH_NO_BREAKDOWN,
  FIELD_IDS_WITH_NO_AGGREGATE,
  FORM_BASE_FIELDS,
} from './analytics.constants';
import {
  getValidCharts,
  getDateRangeFromConfig,
  canBreakdownByField,
  getFieldOptions,
  getFieldType,
} from './analytics.helpers';
import { getFormOptions } from '../forms/formHelpers';
import useFilteredBoardList from '../common/hooks/useFilteredBoardList';

function AnalyticsHeader({
  onExport,
  analyticsConfig,
  type,
  currentBoardId,
}) {
  const dispatch = useDispatch();

  const boards = useSelector((state) => state.boards.boards);
  const formTemplates = useSelector((state) => state.forms.templates);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const projects = useSelector((state) => state.projects.projects);
  const {
    boardId: selectedBoardId,
    formTemplateId,
    dateRange: selectedRange,
    datePreset,
    dateBreakdown: selectedDateBreakdown,
    fieldBreakdown,
    chartType: selectedChartType,
    fieldId,
    aggregate,
    breakdown,
  } = analyticsConfig;

  const isCardInStatus = type === 'newCardsInStatus';

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const filteredBoardOptions = useFilteredBoardList({ asOptions: true });
  const boardOptions = useMemo(() => {
    if (type === 'boardCard') {
      return [{ value: currentBoardId, label: 'Card Data' }];
    }
    return filteredBoardOptions;
  }, [filteredBoardOptions, type, currentBoardId]);

  const formOptions = useMemo(() => getFormOptions({
    formTemplates,
    selectedDivisions,
    projectIdMap,
  }), [formTemplates, selectedDivisions, projectIdMap]);

  const {
    fields: sections = [],
  } = useMemo(() => {
    if (selectedBoardId) {
      const {
        [selectedBoardId]: { cardTypeId } = {},
      } = boards;
      const {
        [cardTypeId]: ourTemplate = {},
      } = cardTemplates;
      return ourTemplate;
    }
    if (formTemplateId) {
      const {
        [formTemplateId]: {
          formData: {
            sections: formSections = [],
          } = {},
        } = {},
      } = formTemplates;
      return { fields: formSections };
    }
    return {};
  }, [boards, selectedBoardId, formTemplateId, cardTemplates, formTemplates]);

  const dateRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange: selectedRange, datePreset })
  ), [selectedRange, datePreset]);

  const {
    fieldOptions,
    fieldMap = {},
    fieldBreakdownOptions = [],
  } = useMemo(() => {
    let opts = [];

    if (selectedBoardId && type !== 'boardCard') {
      opts = [...BASE_FIELDS]
    }

    if (formTemplateId && type !== 'boardCard') {
      opts = [...FORM_BASE_FIELDS];
    }

    const fmap = {};
    const fieldBreakdowns = [];
    sections.forEach((section) => {
      const { fields = [], name: sectionName } = section;
      fields.forEach((field) => {
        const {
          id,
          selectedType,
        } = field;
        fmap[id] = field;

        if (FIELD_TYPES.has(selectedType)) {
          const fieldOptions = getFieldOptions({
            sectionName,
            field,
          });
          const addToFieldBreakdown = canBreakdownByField(field);
          fieldOptions.forEach((opt) => {
            if (addToFieldBreakdown && opt) {
              if (fieldId !== id) fieldBreakdowns.push(opt);
            }
            opts.push(opt);
          });
        }
      });
    });
    return { fieldOptions: opts, fieldMap: fmap, fieldBreakdownOptions: fieldBreakdowns };
  }, [selectedBoardId, sections, fieldId, type, formTemplateId]);

  const breakdownOpts = useMemo(() => {
    if (selectedBoardId) {
      switch (fieldId) {
        case 'conversion':
          return BREAKDOWN_OPTS.slice(0, 2);
        case 'newCardsInStatus':
          return BREAKDOWN_OPTS.filter(({ value }) => value === 'status');
        default:
          return BREAKDOWN_OPTS;
      }
    }

    if (formTemplateId && fieldId === 'numberOfSubmissions') {
      return BREAKDOWN_OPTS.slice(0, 1);
    }

    if (type === 'boardCard') return BREAKDOWN_OPTS;
    return FORM_FIELD_BREAKDOWN_OPTS;
  }, [selectedBoardId, fieldId, type]);

  const fieldType = getFieldType(fieldId, fieldMap);

  const onSelectConfig = useCallback((key) => (value) => {
    const update = { [key]: value };
    if (key === 'boardId' || key == 'formTemplateId') {
      update.fieldId = null;
      update.groups = {};
      if (key === 'boardId') {
        update.formTemplateId = null;
        update.fieldBreakdown = null;
        if (breakdown) update.breakdown = breakdown.filter((b) => !b.startsWith('field'));
      } else {
        update.boardId = null;
        update.breakdown = [];
        update.fieldBreakdown = null;
      }
    } else if (key === 'fieldId') {
      const newFieldType = getFieldType(value, fieldMap);
      const newValidCharts = getValidCharts(newFieldType);
      if (!newValidCharts.has(selectedChartType)) {
        update.chartType = newValidCharts.size > 0 ? newValidCharts.values().next().value : null;
        if (fieldBreakdown && !FIELD_BREAKDOWN_CHARTS.has(update.chartType)) {
          update.fieldBreakdown = null;
          update.dateBreakdown = 'day';
        }
      }
      if (breakdown) {
        if (value === 'conversion') {
          update.breakdown = breakdown.filter((b) => b !== 'color' && !b.startsWith('field'));
        } else if (!value.startsWith('field')) {
          update.breakdown = breakdown.filter((b) => !b.startsWith('field'));
        }
      }
    } else if (key === 'chartType') {
      if (fieldBreakdown && !FIELD_BREAKDOWN_CHARTS.has(value)) {
        update.fieldBreakdown = null;
        update.dateBreakdown = 'none';
      }
      if (value === 'conversion') {
        update.breakdown = breakdown.filter((b) => b !== 'color');
      } else if (value === 'stackedLine' && !selectedDateBreakdown) {
        update.dateBreakdown = 'day';
      }
    } else if (key === 'breakdown' && breakdown && breakdown.length > 0) {
      const newBreakdown = new Set(value);
      const deletedBreakdown = breakdown.filter((b) => !newBreakdown.has(b));
      if (deletedBreakdown.length > 0) {
        /*
          We need to clear out filters if the breakdown is removed.
          Otherwise when we add the breakdown back the filters will still be there.
        */
        const filterUpdate = deletedBreakdown.reduce((acc, key) => {
          acc[key] = [];
          return acc;
        }, {});
        dispatch(updateAnalyticsFilters({
          type,
          payload: filterUpdate,
        }));
      }
    }
    dispatch(setAnalyticsConfig({
      type,
      payload: update,
    }));
  }, [
    dispatch,
    fieldMap,
    selectedChartType,
    selectedDateBreakdown,
    breakdown,
    fieldBreakdown,
    formTemplates,
    type,
  ]);

  const onSelectBoardForm = useCallback((value) => {
    let key;
    if (value in boards) {
      key = 'boardId';
    } else if (value in formTemplates) {
      key = 'formTemplateId';
    } else {
      Sentry.withScope(() => {
        Sentry.captureException(new Error(`Invalid analytics board form selected ${value}`));
      });
      return;
    }
    onSelectConfig(key)(value);
  }, [boards, formTemplates, onSelectConfig]);

  useEffect(() => {
    dispatch(getBoards());
    dispatch(getBoardCardTemplates());
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (!formTemplateId) return;
    if (!(formTemplateId in formTemplates) || !formTemplates[formTemplateId].formData) {
      // Get form data from the backend
      dispatch(getTemplateDetails(formTemplateId));
    }
  }, [dispatch, formTemplates, formTemplateId]);

  useEffect(() => {
    // If date range changes, selected breakdown may be invalid
    // e.g. date range is one week, can't breakdown by quarter
    // Reset to day
    if (
      (
        (!selectedRange || selectedRange.length < 2)
        && !datePreset
      )
      || selectedDateBreakdown === 'day'
      || !selectedDateBreakdown
    ) return;
    const [startMoment, endMoment] = dateRange;
    const diff = moment.duration(endMoment.diff(startMoment));
    if (selectedDateBreakdown === 'year' && diff.asYears > 1) return;
    if (selectedDateBreakdown === 'quarter' && diff.asMonths() > 3) return;
    if (selectedDateBreakdown === 'month' && diff.asMonths() > 1) return;
    if (selectedDateBreakdown === 'week' && diff.asWeeks() > 1) return;
    dispatch(setAnalyticsConfig({
      type,
      payload: {
        dateBreakdown: 'day',
      },
    }));
  }, [dispatch, selectedRange, datePreset, selectedDateBreakdown, type]);

  useEffect(() => {
    if (isCardInStatus && breakdownOpts.length === 1 && onSelectConfig) {
      onSelectConfig('breakdown')([breakdownOpts[0].value]);
    }
  }, [fieldId, breakdownOpts, isCardInStatus]);

  const targetId = selectedBoardId || formTemplateId;

  return (
    <div className="analytics-header">
      <Row justify="space-between">
        <Col>
          <Row align="middle" gutter={10}>
            <Col>
              <Select
                style={{ width: 250 }}
                showSearch
                optionFilterProp="label"
                onSelect={onSelectBoardForm}
                placeholder={`Select ${type === 'boardCard' ? 'Card Data' : 'Board'}/Form`}
                value={targetId}
                dropdownMatchSelectWidth={false}
              >
                {
                  boardOptions.map((opt) => (
                    <Select.Option {...opt} key={opt.value}>
                      <div>
                        <GroupOutlined style={{ marginRight: 10 }} />
                        {opt.label}
                      </div>
                    </Select.Option>
                  ))
                }
                {
                  formOptions.map((opt) => (
                    <Select.Option {...opt} key={opt.value}>
                      <div>
                        <FormOutlined style={{ marginRight: 10 }} />
                        {opt.label}
                      </div>
                    </Select.Option>
                  ))
                }
              </Select>
            </Col>
            {
              targetId
              && (
                <Col>
                  <AnalyticsDatePicker
                    type={type}
                    analyticsConfig={analyticsConfig}
                  />
                </Col>
              )
            }
            {
              (selectedRange || datePreset)
              && (
                <Col>
                  <AnalyticsDateFieldBreakdownSelector
                    fieldBreakdownOptions={fieldBreakdownOptions}
                    fieldMap={fieldMap}
                    analyticsConfig={analyticsConfig}
                    type={type}
                  />
                </Col>
              )
            }
            {
              (selectedRange || datePreset) && (selectedDateBreakdown || fieldBreakdown || FIELDS_WITH_NO_BREAKDOWN.has(fieldType))
              && (
              <Col>
                <AnalyticsChartTypePicker
                  selectedChartType={selectedChartType}
                  onSelect={onSelectConfig('chartType')}
                  fieldType={fieldType}
                />
              </Col>
              )
            }
          </Row>
        </Col>
        <Col>
          {targetId
            && fieldId
            && (selectedRange || datePreset)
            && selectedDateBreakdown
            && (
            <OnTraccrButton
              title="Export"
              onClick={onExport}
            />
            )}
        </Col>
      </Row>

      {targetId
        && (
        <Row align="top" gutter={10} style={{ marginTop: 10 }}>
          <Col>
            <Select
              options={fieldOptions}
              value={fieldId}
              style={{ minWidth: 250, maxWidth: 500 }}
              dropdownMatchSelectWidth={false}
              onSelect={onSelectConfig('fieldId')}
              placeholder="Select Field"
            />
          </Col>
          {
            fieldId
            && (
            <Col>
              <Select
                options={AGGREGATE_OPTS}
                style={{ width: 100 }}
                value={aggregate}
                onSelect={onSelectConfig('aggregate')}
                disabled={selectedChartType === 'list' || selectedChartType === 'pie' || FIELD_IDS_WITH_NO_AGGREGATE.has(fieldId)}
              />
            </Col>
            )
          }
          {
            (selectedBoardId || formTemplateId) && fieldId
            && (
            <Col>
              <Select
                options={breakdownOpts}
                style={{ minWidth: 250 }}
                value={breakdown}
                onChange={onSelectConfig('breakdown')}
                mode="tags"
                placeholder="Breakdown by"
                disabled={isCardInStatus && breakdownOpts?.length === 1}
                tagRender={(props) => (
                  BREAKDOWN_FILTER_SET.has(props.value)
                    ? (
                      <AnalyticsBreakDownTag
                        key={targetId}
                        analyticsConfig={analyticsConfig}
                        type={type}
                        isClosable={!isCardInStatus || breakdownOpts?.length !== 1}
                        {...props}
                      />
                    )
                    : <Tag {...props}>{props.label}</Tag>
                )}
              />
            </Col>
            )
          }
          {
            selectedBoardId && breakdown && breakdown.length > 0 && breakdown.includes('status')
            && (
            <Col>
              <AnalyticsGroups
                analyticsConfig={analyticsConfig}
                type={type}
              />
            </Col>
            )
          }
        </Row>
        )}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
AnalyticsHeader.propTypes = {
  onExport: PropTypes.func.isRequired,
  type: PropTypes.string,
  analyticsConfig: PropTypes.object,
  currentBoardId: PropTypes.string,
};

AnalyticsHeader.defaultProps = {
  type: null,
  analyticsConfig: {},
  currentBoardId: undefined,
};

export default AnalyticsHeader;
