import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Card, Row, Col, Divider, Tooltip, Popover,
} from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import { TaskHelpers, FormHelpers } from 'ontraccr-common';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

import BoardField from '../../boards/BoardField';

import { parseCompletedForm } from '../../forms/formHelpers';
import { getAllTimesText } from './manualEntryHelpers';
import ManualEntryCardDetails from './ManualEntryCardDetails';
import { generateConfigPropsMap, generateResponseMap } from '../../forms/ResponderHelpers';
import { getIdMap } from '../../helpers/helpers';

const stopPropagation = (e) => e.stopPropagation();

function ManualEntryCard({
  task = {},
  onOpenLocation,
  fileMap = {},
  isDisplay,
  onDelete,
  onClick,
  onFileOpen,
  onFileDetailChange,
  userMap = {},
  projectIdMap = {},
  settings = {},
}) {
  const {
    id,
    date,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    projectId,
    phaseId,
    costcodeId,
    classId,
    sageShiftId,
    note,
    type,
    hourBased,
    customData: customFieldResponses,
    canEdit,
    startLatitude,
    startLongitude,
    endLatitude,
    endLongitude,
    divisionId,
    timezone,
    geofenceViolation,
    outsideWorkingHours,
    userId,
    isAutoRounded,
    approvers,
  } = task;

  const customFields = useSelector((state) => state.timecards.customFields);
  const enableLocationStamps = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            enableLocationStamps: els,
          } = {},
        } = {},
      } = {},
    } = state;
    return els;
  });
  const unionClasses = useSelector((state) => state.unions.classes);

  const unionClassMap = useMemo(() => getIdMap(unionClasses), [unionClasses]);
  const { localId } = unionClassMap[classId] ?? {};
  const taskDate = TaskHelpers.getTaskDate(task);

  const onDeleteClicked = useCallback((e) => {
    e.stopPropagation();
    onDelete(id);
  }, [id, onDelete]);
  const onClickPressed = useCallback(() => {
    onClick(id);
  }, [id, onClick]);
  const onCopyClicked = useCallback((e) => {
    e.stopPropagation();
    onClick(id, true);
  }, [id, onClick]);
  const timeText = useMemo(() => getAllTimesText(task, { returnComponent: false }), [
    task,
  ]);

  const usersName = useMemo(() => userMap?.[userId]?.name, [userMap, userId]);

  const onFileClick = useCallback((files) => (index) => {
    onFileOpen(files[index]);
    onFileDetailChange({ response: { files }, index });
  }, [onFileOpen, onFileDetailChange]);

  const onLocationClicked = useCallback(() => {
    let title = taskDate?.toLocaleString(DateTime.DATE_MED);
    if (timeText) title += ` ${timeText}`;
    onOpenLocation({
      projectId,
      startLatitude,
      startLongitude,
      endLatitude,
      endLongitude,
      title,
    });
  }, [
    onOpenLocation,
    projectId,
    startLatitude,
    startLongitude,
    endLatitude,
    endLongitude,
    timeText,
    taskDate,
  ]);

  const ourCustomFields = useMemo(() => {
    const {
      [divisionId]: { fields = [] } = {},
    } = customFields;
    return fields;
  }, [customFields, divisionId]);

  const showMap = useMemo(() => {
    const hasLocation = (startLatitude && startLongitude)
    || (endLatitude && endLongitude);
    return enableLocationStamps && hasLocation;
  }, [enableLocationStamps, startLatitude, startLongitude, endLatitude, endLongitude]);

  const customFieldViews = useMemo(() => {
    const isEditData = !Array.isArray(customFieldResponses);
    if (isEditData) {
      if (!ourCustomFields || ourCustomFields.length === 0) return null;

      const responsesWithTimeCardData = {
        ...customFieldResponses ?? {},
        project: { value: projectId },
        costcode: { value: costcodeId },
      };
      return (
        <div>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          {
            ourCustomFields.map((section) => {
              const {
                fields = [],
                name: sectionName,
                hidden,
              } = section;
              return !hidden ? (
                <div key={sectionName}>
                  <b
                    style={{
                      marginBottom: 5,
                      fontSize: 16,
                    }}
                  >
                    {sectionName}
                  </b>
                  {
                    fields.map((field) => {
                      const {
                        id: fieldId,
                        configProps: {
                          hasConditionalRendering,
                          conditionalRenderingFormula,
                        } = {},
                      } = field;

                      if (
                        hasConditionalRendering
                        && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
                        && !FormHelpers.executeConditionalRenderingFormula({
                          formula: conditionalRenderingFormula,
                          responses: responsesWithTimeCardData,
                        })
                      ) {
                        return null;
                      }

                      const {
                        [fieldId]: response = {},
                      } = customFieldResponses;
                      const fullResponse = { ...response, onClick: onFileClick(response.files) };
                      return (
                        <BoardField
                          key={field.id}
                          field={{ ...field, response: fullResponse }}
                          projectIdMap={projectIdMap}
                          settings={settings}
                          showCondensedView
                        />
                      );
                    })
                  }
                </div>
              ) : null;
            })
          }
        </div>
      );
    }
    if (!customFieldResponses?.length) return null;
    const parsedFields = parseCompletedForm({
      sections: customFieldResponses,
      fileMap,
      setSelectedFile: onFileOpen,
      setSelectedFileDetails: onFileDetailChange,
    });

    const configPropMap = generateConfigPropsMap(ourCustomFields);
    const customFieldResponseMap = generateResponseMap(customFieldResponses);
    customFieldResponseMap.project = { value: projectId };
    customFieldResponseMap.costcode = { value: costcodeId };

    return (
      <div>
        <Divider
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        {
          parsedFields.map((section) => {
            const {
              fields = [],
              name: sectionName,
              id: sectionId,
            } = section;
            return sectionId !== 'timecard' ? (
              <div key={sectionName}>
                <b
                  style={{
                    marginBottom: 5,
                    fontSize: 16,
                  }}
                >
                  {sectionName}
                </b>
                {
                  fields.map((field) => {
                    const {
                      configProps = {},
                      response = {},
                    } = field;

                    const {
                      hasConditionalRendering,
                      conditionalRenderingFormula,
                      dataType: configMapDataType,
                    } = configPropMap[field.id] ?? {};

                    if (
                      hasConditionalRendering
                      && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
                      && !FormHelpers.executeConditionalRenderingFormula({
                        formula: conditionalRenderingFormula,
                        responses: customFieldResponseMap,
                      })
                    ) {
                      return null;
                    }

                    return (
                      <BoardField
                        key={field.fieldId}
                        field={{
                          ...field,
                          configProps: {
                            ...configPropMap[field.fieldId] ?? {},
                            ...configProps,
                            dataType: response.dataType
                              ?? configMapDataType
                              ?? configProps?.dataType,
                            columns: response.columns,
                          },
                        }}
                        projectIdMap={projectIdMap}
                        settings={settings}
                        showCondensedView
                      />
                    );
                  })
                }
              </div>
            ) : null;
          })
        }
      </div>
    );
  }, [customFieldResponses, ourCustomFields, fileMap, onFileClick, projectId, costcodeId]);

  const disable = !isDisplay && !canEdit;

  return (
    <Card
      hoverable={canEdit && !isDisplay}
      onClick={canEdit && !isDisplay ? onClickPressed : null}
      className={
        disable
          ? 'manual-entry-card-disabled'
          : 'manual-entry-card'
      }
      bodyStyle={{
        padding: '2px 5px',
      }}
    >
      <Row justify="space-between">
        <ManualEntryCardDetails
          date={date}
          startTime={startTime}
          endTime={endTime}
          otStartTime={otStartTime}
          otEndTime={otEndTime}
          doubleOTStartTime={doubleOTStartTime}
          doubleOTEndTime={doubleOTEndTime}
          breakStartTime={breakStartTime}
          breakEndTime={breakEndTime}
          timezone={timezone}
          projectId={projectId}
          phaseId={phaseId}
          costcodeId={costcodeId}
          localId={localId}
          classId={classId}
          sageShiftId={sageShiftId}
          type={type}
          hourBased={hourBased}
          usersName={usersName}
          approvers={approvers}
        />
        <Col>
          <Row justify="end" gutter={10}>
            <Col onClick={stopPropagation}>
              {
                showMap
                && (
                  <BorderlessButton
                    title="Location"
                    style={{
                      color: geofenceViolation ? colors.ONTRACCR_RED : 'auto',
                    }}
                    iconNode={
                      geofenceViolation
                        ? <ExclamationCircleOutlined style={{ color: colors.ONTRACCR_RED }} />
                        : <EnvironmentOutlined />
                  }
                    onClick={onLocationClicked}
                  />
                )
              }
            </Col>
            <Col onClick={stopPropagation}>
              {
                outsideWorkingHours
                && (
                  <Popover
                    content="This entry is outside of working hours"
                  >
                    <ClockCircleOutlined style={{ color: colors.ONTRACCR_RED, cursor: 'help', marginTop: 8 }} />
                  </Popover>
                )
              }
              { !!isAutoRounded && (
                <Popover
                  content="This entry has been auto-rounded"
                >
                  <FieldTimeOutlined style={{
                    color: colors.ONTRACCR_RED, cursor: 'help', marginTop: 8, marginLeft: 8,
                  }}
                  />
                </Popover>
              )}
            </Col>
            <Col style={{ width: 60, paddingLeft: 0, paddingRight: 0 }}>
              {
                !isDisplay && canEdit
                && (
                  <Tooltip title="Copy Entry">
                    <BorderlessButton
                      style={{ width: 30, height: 30, padding: 0 }}
                      iconNode={<CopyOutlined style={{ color: 'black', marginLeft: 0 }} />}
                      onClick={onCopyClicked}
                    />
                  </Tooltip>
                )
              }
              {
                !isDisplay && canEdit
                && (
                  <BorderlessButton
                    style={{ width: 30, height: 30, padding: 0 }}
                    iconNode={<DeleteOutlined style={{ color: 'red', marginLeft: 0 }} />}
                    onClick={onDeleteClicked}
                  />
                )
              }
            </Col>
          </Row>
        </Col>
      </Row>

      {
        note && <div>{note}</div>
      }
      {customFieldViews}
    </Card>
  );
}

/* eslint-disable react/forbid-prop-types */
ManualEntryCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    projectId: PropTypes.string,
    phaseId: PropTypes.string,
    costcodeId: PropTypes.string,
    localId: PropTypes.string,
    classId: PropTypes.string,
    sageShiftId: PropTypes.string,
    note: PropTypes.string,
    type: PropTypes.string,
    hourBased: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    customData: PropTypes.object,
    canEdit: PropTypes.bool,
    startLatitude: PropTypes.number,
    startLongitude: PropTypes.number,
    endLatitude: PropTypes.number,
    endLongitude: PropTypes.number,
    geofenceViolation: PropTypes.bool,
    divisionId: PropTypes.string,
    timezone: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  onOpenLocation: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onFileOpen: PropTypes.func.isRequired,
  onFileDetailChange: PropTypes.func.isRequired,
  fileMap: PropTypes.object.isRequired,
  isDisplay: PropTypes.bool,
  userMap: PropTypes.object,
  projectIdMap: PropTypes.shape({}),
  settings: PropTypes.shape({}),
};

ManualEntryCard.defaultProps = {
  isDisplay: false,
  userMap: {},
  projectIdMap: {},
  settings: {},
};

export default ManualEntryCard;
