export const GET_ALL_USERS = '[Users] Get All';
export const GET_USERS_BY_ID = '[Users] Get By Id';
export const CREATE_USER = '[Users] Create';
export const UPDATE_USER_BY_ID = '[Users] Update By Id ';
export const DELETE_USER_BY_ID = '[Users] Delete By Id';
export const ARCHIVE_USER = '[Users] Archive';
export const MASS_ARCHIVE_USERS = '[Users] Mass Archive';
export const GET_USER_TEAMS = '[Users] Get User Teams';
export const GET_USER_LABELS = '[Users] Get Labels';
export const GET_USER_FILES = '[Users] Get Files';
export const GET_USER_NOTES = '[Users] Get Notes';
export const ADD_USER_NOTE = '[Users] Add Note';

export const GET_USER_CUSTOM_FIELD_TEMPLATE = '[Users] Get Custom Field Template';
export const UPDATE_USER_CUSTOM_FIELD_TEMPLATE = '[Users] Update Custom Field Template';
export const GET_USER_CUSTOM_DATA = '[Users] Get Custom Data';

export const GET_USER_SETTINGS = '[Users] Get Settings';
export const UPDATE_USER_SETTINGS = '[Users] Update Settings';

export const USER_LOGOUT = '[Auth] Logout';

export const GET_ALL_CERTIFICATIONS = '[Certifications] Get All Certifications';
export const GET_ALL_CERTIFICATION_FOR_ENTITY = '[Certifications] Get All Certifications For User';
export const GET_CERTIFICATION = '[Certifications] Get Certification';
export const CREATE_CERTIFICATION = '[Certifications] Create Certification';
export const UPDATE_CERTIFICATION = '[Certifications] Update Certification';
export const DELETE_CERTIFICATION = '[Certifications] Delete Certification';
export const GET_CERTIFICATION_CUSTOM_FIELD_TEMPLATE = '[Certifications] Get Certifications Custom Field Template';
export const UPDATE_CERTIFICATION_CUSTOM_FIELD_TEMPLATE = '[Certifications] Update Certifications Custom Field Template';
export const GET_CERTIFICATION_CUSTOM_DATA = '[Certifications] Get Certifications Custom Data';

export const GET_ALL_TEAMS = '[Teams] Get All';
export const CREATE_TEAM = '[Teams] Create';
export const UPDATE_TEAM = '[Teams] Update';
export const ARCHIVE_TEAM = '[Teams] Archive';
export const DELETE_TEAM = '[Teams] Delete';

export const GET_PROFILE = '[Profile] Get';
export const UPDATE_PROFILE = '[Profile] Update';
export const CHANGE_PASSWORD = '[Profile] Change Password';
export const CONNECT_GMAIL = '[Profile] Connect Gmail';
export const GET_GMAIL_CODE = '[Profile] Get Code';
export const CYCLE_GMAIL_CODE = '[Profile] Cycle Gmail Code';
export const DISCONNECT_GMAIL = '[Profile] Disconnect Gmail';

export const SET_BREADCRUMB = '[Breadcrumb] Set';

export const GET_ALL_EVENTS = '[Events] Get All';
export const CREATE_EVENT = '[Event] Create';

export const GET_ALL_NOTIFICATIONS = '[Notifications] Get All';
export const CREATE_NOTIFICATION = '[Notification] Create';
export const DELETE_NOTIFICATION = '[Notification] Delete';
export const MARK_READ_NOTIFICATION = '[Notification] Mark As Read';

export const GET_COMPANY = '[Company] Get';
export const GET_COMPANY_IMAGE = '[Company] Get Image';
export const SAVE_COMPANY_IMAGE = '[Company] Save Image';
export const GET_COMPANY_SETTINGS = '[Company] Get Settings';
export const SAVE_COMPANY_SETTINGS = '[Company] Save Settings';
export const UPDATE_COMPANY_OWNER = '[Company] Update Owner';
export const GET_COMPANY_POSITIONS = '[Company] Get Positions';
export const GET_COMPANY_AC_POSITIONS = '[Company] Get AC Positions';
export const REORDER_AC_POSITIONS = '[Company] Reorder AC Positions';
export const RESET_PERMISSIONS = '[Company] Reset Permissions';
export const TOGGLE_PERMISSIONS = '[Company] Toggle Permissions';
export const TOGGLE_AC_PERMISSIONS = '[Company] Toggle AC Permissions';
export const CREATE_POSITION = '[Company] Create Position';
export const RENAME_POSITION = '[Company] Rename Position';
export const DELETE_POSITION = '[Company] Delete Position';
export const VIEW_BILLING = '[Company] View Billing';
export const UPDATE_USER_LIMIT = '[Company] Update User Limit';
export const CONNECT_QUICKBOOKS = '[Company] Connect Quickbooks';
export const CONNECT_PROCORE = '[Company] Connect Procore';
export const CONNECT_MICROSOFT365 = '[Company] Connect Microsoft 365';
export const CHECK_INTEGRATION_CONNECTION_STATUS = '[Company] Check Integration Connection Status';
export const DECREASE_COMPANY_STORAGE_USAGE = '[Company] Decrease Storage Usage';
export const INCREASE_COMPANY_STORAGE_USAGE = '[Company] Increase Storage Usage';
export const SET_COMPANY_STORAGE_USAGE = '[Company] Set Storage Usage';
export const GET_DIVISIONS = '[Company] Get Divisions';
export const SET_SELECTED_DIVISIONS = '[Company] Set Selected Divisions';
export const CREATE_DIVISIONS = '[Company] Create Divisions';
export const DELETE_DIVISIONS = '[Company] Delete Divisions';
export const TRANSFER_DIVISIONS = '[Company] Transfer Divisions';
export const RENAME_DIVISION = '[Company] Rename Division';
export const UPDATE_DIVISION_CODE = '[Company] Update Division Code';
export const DEACTIVATE_INTEGRATION = '[Company] Deactivate Integration';
export const GET_INTEGRATED_EMAILS = '[Company] Get Integrated Emails';
export const GET_QUICKBOOKS_INTEGRATIONS = '[Company] Get QuickBooks Integrations';
export const UPDATE_QUICKBOOKS_INTEGRATION = '[Company] Update QuickBooks Integrations';
export const GET_WORKING_HOURS = '[Company] Get Working Hours';
export const UPDATE_POSITION_WORKING_HOURS = '[Company] Update position working hours';
export const GET_QR_CODES = '[Company] Get QR Codes';
export const CONNECT_DOCUSIGN = '[Company] Connect Docusign';

export const CREATE_PROJECT = '[Projects] Create';
export const GET_ALL_PROJECTS = '[Projects] Get All';
export const ARCHIVE_PROJECT = '[Projects] Archive';
export const UPDATE_PROJECT = '[Projects] Update';
export const DELETE_PROJECT = '[Projects] Delete';
export const GET_PROJECT_EQUIPMENT = '[Projects] Get Equipment';
export const UPDATE_PROJECT_CUSTOMER = '[Projects] Update Customer';
export const GET_PROJECT_SCHEDULE_OF_VALUES = '[Projects] Get Schedule Of Values';
export const UPDATE_PROJECT_SCHEDULE_OF_VALUES = '[Projects] Update Schedule Of Values';
export const SUBMIT_PROJECT_SCHEDULE_OF_VALUES = '[Projects] Submit Schedule Of Values';
export const SWAP_ROW_PROJECT_SCHEDULE_OF_VALUES = '[Projects] Swap Row Schedule Of Values';
export const UPDATE_PROJECT_SCHEDULE_OF_VALUES_ROW = '[Projects] Update Schedule Of Values Row';
export const DELETE_PROJECT_SCHEDULE_OF_VALUES_ROW = '[Projects] Delete Schedule Of Values Row';
export const GET_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS = '[Projects] Get Schedule Of Values PDF Rows';
export const UPDATE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS = '[Projects] Update Schedule Of Values PDF Rows';
export const ADD_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS = '[Projects] Add Schedule Of Values PDF Rows';
export const DELETE_PROJECT_SCHEDULE_OF_VALUES_PDF_ROWS = '[Projects] Delete Schedule Of Values PDF Rows';
export const UPDATE_INDIVIDUAL_PROJECT = '[Projects] Update Individual Project';
export const GET_ALL_PROJECT_COSTCODE_DETAILS = '[Projects] Get Project Costcode Details';
export const ADD_PROJECT_PROGRESS_HISTORY_FILES = '[Projects] Update Project Progress History';
export const GET_SCHEDULE_OF_VALUE_TEMPLATES = '[Projects] Get Schedule Of Value Templates';
export const CREATE_SCHEDULE_OF_VALUE_TEMPLATE = '[Projects] Create Schedule Of Value Template';
export const UPDATE_SCHEDULE_OF_VALUE_TEMPLATE = '[Projects] Update Schedule Of Value Template';
export const DELETE_SCHEDULE_OF_VALUE_TEMPLATE = '[Projects] Delete Schedule Of Value Template';
export const APPLY_SCHEDULE_OF_VALUE_TEMPLATE = '[Projects] Apply Schedule Of Value Template';
export const GET_SCHEDULE_OF_VALUE_SECTIONS = '[Projects] Get Schedule Of Value Sections';
export const CREATE_SCHEDULE_OF_VALUE_SECTION = '[Projects] Create Schedule Of Value Section';
export const UPDATE_SCHEDULE_OF_VALUE_SECTION = '[Projects] Update Schedule Of Value Section';
export const DELETE_SCHEDULE_OF_VALUE_SECTION = '[Projects] Delete Schedule Of Value Section';
export const GET_PROJECT_FILE_TEMPLATES = '[Projects] Get Project File Template';
export const ADD_PROJECT_FILE_TEMPLATE = '[Projects] Add Project File Template';
export const UPDATE_PROJECT_FILE_TEMPLATE = '[Projects] Update Project File Template';
export const DELETE_PROJECT_FILE_TEMPLATE = '[Projects] Delete Project File Template';
export const APPLY_PROJECT_FILE_TEMPLATE = '[Projects] Apply Project File Template';
export const UPDATE_PROJECT_PROGRESS_FILTERS = '[Progress] Update Project Progress Filters';
export const GET_PROJECT_GROUPS = '[Projects] Get Project Groups';
export const CREATE_PROJECT_GROUP = '[Projects] Create Project Group';
export const UPDATE_PROJECT_GROUP = '[Projects] Update Project Group';
export const DELETE_PROJECT_GROUP = '[Projects] Delete Project Group';
export const GET_PROJECT_GROUP_NOTES = '[Projects] Get Project Group Notes';
export const ADD_PROJECT_GROUP_NOTE = '[Projects] Add Project Group Note';
export const GET_PROJECT_TYPES = '[Projects] Get Project Types';
export const GET_SAVED_PROJECT_ANALYTICS_REPORTS = '[Projects] Get Saved Project Analytics Reports';
export const DELETE_PROJECT_ANALYTICS_REPORT = '[Projects] Delete Project Analytics Report';
export const CREATE_PROJECT_ANALYTICS_REPORT = '[Projects] Create Project Analytics Report';
export const UPDATE_PROJECT_ANALYTICS_REPORT = '[Projects] Update Project Analytics Report';
export const SET_PROJECT_ANALYTICS_CONFIG = '[Projects] Set Project Analytics Config';
export const SELECT_SAVED_PROJECT_REPORT = '[Projects] Select Project Analytics Report';
export const UPDATE_PROJECT_ANALYTICS_FILTERS = '[Projects] Update Project Analytics Filters';
export const GET_PROJECT_ANALYTICS_DATA = '[Projects] Get Project Analytics Data';
export const CLEAR_PROJECT_ANALYTICS_CONFIG = '[Projects] Clear Project Analytics Config';
export const GET_SAVED_PROJECT_DASHBOARDS = '[Projects] Get Saved Project Dashboards';
export const SET_PROJECT_DASHBOARD_EDIT = '[Projects] Edit Project Dashboard';
export const SELECT_PROJECT_DASHBOARD = '[Projects] Select Project Dashboard';
export const CREATE_PROJECT_DASHBOARD = '[Projects] Create Project Dashboard';
export const UPDATE_PROJECT_DASHBOARD = '[Projects] Update Project Dashboard';
export const DELETE_PROJECT_DASHBOARD = '[Projects] Delete Project Dashboard';
export const GET_PROJECT_DASHBOARD_ANALYTICS_DATA = '[Projects] Get Project Dashboard Analytics Data';
export const FAVORITE_PROJECT_DASHBOARD = '[Projects] Favorite Project Dashboard';
export const SET_INITIAL_PROJECT_DASHBOARD = '[Projects] Select Initial Project Dashboard';
export const GET_PROJECT_ATTACHMENTS = '[Project] Get Project Attachments';
export const GET_PROJECT_COST_UPDATES = '[Project] Get Project Cost Updates';
export const GET_PROGRESS_SUBCONTRACT_UPDATES = '[Project] Get Progress Subcontract Updates';
export const UPDATE_PROGRESS_SUBCONTRACT = '[Project] Update Progress Subcontract';
export const DELETE_PROGRESS_SUBCONTRACT_UPDATE = '[Project] Delete Progress Subcontract Update';
export const UPDATE_PROGRESS_SUBCONTRACT_ROW = '[Project] Update Progress Subcontract Row';
export const SWAP_ROW_PROGRESS_SUBCONTRACT = '[Project] Swap Row Progress Subcontract';
export const GET_PROJECT_CUSTOM_FIELD_TEMPLATE = '[Project] Get Custom Field Template';
export const UPDATE_PROJECT_CUSTOM_FIELD_TEMPLATE = '[Project] Update Custom Field Template';
export const GET_PROJECT_CUSTOM_DATA = '[Project] Get Custom Data';
export const GET_PROJECT_NOTES = '[Project] Get Notes';
export const ADD_PROJECT_NOTE = '[Project] Add Note';

export const CREATE_COSTCODE = '[CostCodes] Create';
export const CREATE_MANY_COSTCODE = '[CostCodes] Create Many';
export const GET_ALL_COSTCODES = '[CostCodes] Get All';
export const DELETE_COSTCODE_BY_ID = '[CostCode] Delete by Id';
export const UPDATE_COSTCODE = '[CostCode] Update';
export const ARCHIVE_COSTCODE = '[CostCode] Archive';
export const GET_COSTCODE_CATEGORIES = '[CostCode] Categories';
export const CREATE_COSTCODE_TEMPLATE = '[CostCodes] Create Template';
export const GET_COSTCODE_TEMPLATES = '[CostCodes] Get Template';
export const UPDATE_COSTCODE_TEMPLATE = '[CostCodes] Update Template';
export const DELETE_COSTCODE_TEMPLATE_BY_ID = '[CostCodes] Delete Template';
export const GET_COSTCODE_BILLING_DATA = '[CostCodes] Get Billing Data';
export const GET_COSTCODE_CUSTOM_FIELD_TEMPLATE = '[CostCodes] Get Custom Field Template';
export const UPDATE_COSTCODE_CUSTOM_FIELD_TEMPLATE = '[CostCodes] Update Custom Field Template';
export const GET_COSTCODE_CUSTOM_DATA = '[CostCodes] Get Custom Data';

export const GET_PHASES = '[Phase] Get All';

export const SET_MENU = '[Menu] Set';
export const SET_MENU_OPEN_KEYS = '[Menu] Set Open Keys';

export const GET_ALL_APPROVALS = '[Clockin] Get All Approvals';

export const SET_TIMECARD_CALENDAR_TYPE = '[Timecards] Set Calendar Type';
export const SET_TIMECARD_TIME_RANGE = '[Timecards] Set Time Range';
export const GET_SIGNATURE_URL = '[Timecards] Get Signature URL';
export const GET_CUSTOM_TIMECARD_FIELDS = '[Timecards] Get Custom Fields';
export const SET_CUSTOM_TIMECARD_FIELDS = '[Timecards] Set Custom Fields';
export const GET_TASK_PHOTOS = '[Timecards] Get Facial Capture Photos';
export const GET_TIMECARD_HISTORY = '[Timecards] Get Timecard History';
export const UPDATE_TIMECARD_FILTERS = '[Timecards] Update Time Card Filters';
export const UPDATE_CURRENT_TIME_ENTRIES_RANGE = '[Timecards] Update Current Time Entries Range';

export const TOGGLE_FULLSCREEN = '[Main] Toggle FullScreen';
export const TOGGLE_MENU_COLLAPSE = '[Main] Toggle Menu Collapse';
export const SET_MAIN_LOADED = '[Main] Set Main Loaded';

export const SAVE_REPORT = '[Reports] Save';
export const UPDATE_REPORT = '[Reports] Update';
export const GET_REPORTS = '[Reports] Get All';
export const DELETE_REPORT = '[Reports] Delete';
export const UPDATE_PROGRESS = '[Reports] Update Progress';
export const GET_PROGRESS = '[Reports] Get Progress';

export const CREATE_CUSTOM_EXPORT = '[Custom Exports] Create';
export const GET_CUSTOM_EXPORTS = '[Custom Exports] Get';
export const UPDATE_CUSTOM_EXPORT = '[Custom Exports] Update';
export const DELETE_CUSTOM_EXPORT = '[Custom Exports] Delete';

export const GET_ALL_TABLETS = '[Tablets] Get All';
export const CREATE_TABLET = '[Tablets] Create';
export const UPDATE_TABLET = '[Tablets] Update';
export const DELETE_TABLET = '[Tablets] Delete';

export const GET_NUX = '[NUX] Get';
export const CREATE_NUX = '[NUX] Create';
export const DELETE_NUX = '[NUX] Delete';
export const START_NUX_ACTION = '[Nux] Start Action';
export const FINISH_NUX_ACTION = '[Nux] Finish Action';

export const GET_QUICKBOOKS_EMPLOYEES = '[Quickbooks] Get Employees';
export const GET_QUICKBOOKS_PROJECTS = '[Quickbooks] Get Projects';
export const GET_QUICKBOOKS_COSTCODES = '[Quickbooks] Get Costcodes';
export const GET_QUICKBOOKS_MATERIALS = '[Quickbooks] Get Materials';
export const GET_QUICKBOOKS_CLASSES = '[Quickbooks] Get Classes';
export const GET_QUICKBOOKS_CUSTOMERS = '[Quickbooks] Get Customers';
export const GET_QUICKBOOKS_VENDORS = '[Quickbooks] Get Vendors';
export const IMPORT_QUICKBOOKS_DATA = '[Quickbooks] Import Data';
export const DISCONNECT_QUICKBOOKS = '[Quickbooks] Disconnect';

export const DISCONNECT_PROCORE = '[Procore] Disconnect';
export const GET_PROCORE_COMPANIES = '[Procore] Get Companies';
export const GET_PROCORE_GLOBAL_CODES_LIST = '[Procore] Get Global Codes List';
export const PROCORE_IMPORT = '[Procore] Import';
export const UPDATE_PROCORE_COMPANY = '[Procore] Update Company';

export const GET_SAGE_INTEGRATIONS = '[Sage] Get Integrations';
export const GET_SAGE_DATA = '[Sage] Get Data';
export const GET_SAGE_SHIFTS = '[Sage] Get Shifts';
export const CREATE_SAGE_SHIFT = '[Sage] Create Shift';
export const UPDATE_SAGE_SHIFT = '[Sage] Update Shift';
export const DELETE_SAGE_SHIFT = '[Sage] Delete Shift';
export const GET_SAGE_PAY_IDS = '[Sage] Get Pay IDs';
export const CREATE_SAGE_PAY_MAPPING = '[Sage] Create Pay Mapping';
export const UPDATE_SAGE_PAY_MAPPING = '[Sage] Update Pay Mapping';
export const DELETE_SAGE_PAY_MAPPING = '[Sage] Delete Pay Mapping';
export const UPDATE_SAGE_DEFAULT_DIVISION = '[Sage] Update Default Division';
export const UPDATE_SAGE_DIVISIONS = '[Sage] Update Divisions';
export const UPDATE_SAGE_NAME = '[Sage] Update Name';
export const UPDATE_SAGE_JOB_STATUSES = '[Sage] Update Job Statuses';
export const GET_SAGE_TIME_SYNC = '[Sage] Get Sage Time Sync';
export const WRITE_SAGE_TIME = '[Sage] Write Sage Time';
export const RESOLVE_SAGE_TIME = '[Sage] Resolve Sage Time';
export const GET_SAGE_JOB_MAPPINGS = '[Sage] Get Sage Job Mappings';
export const UPDATE_SAGE_JOB_MAPPINGS = '[Sage] Update Sage Job Mappings';

export const DISCONNECT_DOCUSIGN = '[Docusign] Disconnect';

export const IMPORT_INTEGRATION_DATA = '[Integration] Import';

export const ACCEPT_TOS = '[ToS] Accept';

export const GET_LIVE_FEED_EVENTS = '[LiveFeed] Get Events';
export const ADD_LIVE_FEED_EVENT = '[LiveFeed] Add Event';
export const GET_POSTS = '[LiveFeed] Get Posts';
export const CREATE_POST = '[LiveFeed] Create Post';
export const DELETE_POSTS = '[LiveFeed] Delete Posts';
export const GET_CHANNELS = '[LiveFeed] Get Channels';
export const CREATE_CUSTOM_CHANNEL = '[LiveFeed] Create Custom Channel';
export const EDIT_CUSTOM_CHANNEL = '[LiveFeed] Edit Custom Channel';
export const DELETE_CUSTOM_CHANNEL = '[LiveFeed] Delete Custom Channel';

export const GET_FILE_URLS = '[Files] Get URLs';
export const GET_FILE_STRUCTURE = '[Files] Get Structure';
export const CREATE_FOLDERS = '[Files] Create Folders';
export const DELETE_FILE_ITEMS = '[Files] Delete Items';
export const UPLOAD_FILES = '[Files] Upload';
export const MOVE_FILES = '[Files] Move';
export const LOAD_IMAGE = '[Files] Load Image';
export const HIDE_IMAGE = '[Files] Hide Image';
export const DESCRIBE_FILE = '[Files] Describe File';
export const CLOSE_FILE_DRAWER = '[Files] Close Drawer';
export const RENAME_FOLDER = '[Files] Rename Folder';
export const GET_FILE_ACCESS_LIST = '[Files] Get Access List';
export const GRANT_FILE_ACCESS = '[Files] Grant Access';
export const REVOKE_FILE_ACCESS = '[Files] Revoke Access';
export const TOGGLE_FILE_PUBLIC_STATUS = '[Files] Toggle Public Status';

export const CREATE_FORM_TEMPLATE = '[Forms] Create Form Template';
export const UPDATE_SELECT_FORM_FILES = '[Forms] Update Selected Form Files';
export const GET_FORM_TEMPLATES = '[Forms] Get Form Templates';
export const ARCHIVE_FORM_TEMPLATE = '[Forms] Archive';
export const GET_FORM_TEMPLATE_DETAILS = '[Forms] Get Form Template Details';
export const UPDATE_FORM_TEMPLATE = '[Forms] Update Form Template';
export const DELETE_FORM_TEMPLATE = '[Forms] Delete Form Template';
export const GET_FORMS = '[Forms] Get All';
export const GET_FORM_BY_ID = '[Forms] Get By Id';
export const GET_FORM_APPROVALS = '[Forms] Get Approvals';
export const APPROVE_FORM = '[Forms] Approve';
export const REJECT_FORM = '[Forms] Reject';
export const FORM_SOCKET_UPDATE = '[Forms] Socket Update';
export const FORM_APPROVAL_SOCKET_UPDATE = '[Forms] Approval Socket Update';
export const FORM_NUMBER_SOCKET_UPDATE = '[Forms] Number Socket Update';
export const GET_ASSIGNED_FORMS = '[Forms] Get Assigned Forms';
export const GET_ASSIGNED_FORM_TEMPLATES = '[Forms] Get Assigned Form Templates';
export const SUBMIT_FORM = '[Forms] Submit Form';
export const GET_FORM_STATUSES = '[Forms] Get Statuses';
export const GET_FORM_TYPES = '[Forms] Get Types';
export const SAVE_FORM_DRAFT = '[Forms] Save Draft';
export const GET_FORM_DRAFTS = '[Forms] Get Drafts';
export const DELETE_FORM_DRAFT = '[Forms] Delete Draft';
export const MARK_INVOICE_AS_PAID = '[Forms] Mark Invoice as Paid';
export const DELETE_FORM = '[Forms] Delete Form';
export const CANCEL_FORM = '[Forms] Cancel Form';
export const FORM_APPROVAL_DELETE = '[Forms] Approval Delete';
export const CLEAR_SELECTED_FORM = '[Forms] Clear Selected Form';
export const COPY_FORM_TEMPLATE = '[Forms] Copy Form Template';
export const FAVORITE_FORM_TEMPLATE = '[Forms] Favorite Form Template';
export const GET_FORM_INVOICES_COST_TO_DATE = '[Forms] Get Form Invoices Cost to Date';
export const CREATE_CUSTOM_FORM_TABLE = '[Forms] Create Custom Table';
export const GET_CUSTOM_FORM_TABLES = '[Forms] Get Custom Tables';
export const UPDATE_CUSTOM_FORM_TABLE = '[Forms] Update Custom Table';
export const DELETE_CUSTOM_FORM_TABLE = '[Forms] Delete Custom Table';
export const GET_FORM_CHILDREN = '[Forms] Get Form Children';
export const REASSIGN_FORM = '[Forms] Reassign Form';
export const REASSIGN_DRAFT = '[Forms] Reassign Draft';
export const CREATE_CUSTOM_TYPE = '[Forms] Create Custom Type';
export const DELETE_CUSTOM_TYPE = '[Forms] Delete Custom Type';
export const UPDATE_CUSTOM_TYPE = '[Forms] Update Custom Type';
export const GET_EXTERNAL_FORM_TEMPLATES = '[Forms] Get External Form Templates';
export const GET_EXTERNAL_FORM_TEMPLATE_BY_ID = '[Forms] Get External Form Template By Id';
export const GET_USER_FORM_FILTER_VIEWS = '[Forms] Get Form Filter Views for User';
export const CREATE_USER_FORM_FILTER_VIEW = '[Forms] Create Form Filter View for User';
export const UPDATE_USER_FORM_FILTER_VIEW = '[Forms] Update Form Filter View for User';
export const DELETE_USER_FORM_FILTER_VIEW = '[Forms] Delete Form Filter View for User';
export const UPDATE_FORM_FILTERS = '[Forms] Update Form Filters';
export const GET_LIGHTWEIGHT_FORMS = '[Forms] Get Lightweight Forms';
export const GET_CHILD_FORMS = '[Forms] Get Child Forms';
export const GET_FORM_SNAPSHOTS = '[Forms] Get Form Snapshots';
export const GET_SUB_CONTRACT_DETAILS = '[Forms] Get Sub Contract Details';

export const GET_FORM_LIBRARY = '[Form Library] Get Library';
export const GET_FORM_LIBRARY_TEMPLATE = '[Form Library] Get Form Library Template';

export const GET_EMAILS = '[Emails] Get Emails';
export const RESUBMIT_EMAIL = '[Emails] Resubmit Emails';

export const GET_SCHEDULE = '[Schedule] Get Schedule';
export const GET_SCHEDULE_GROUP = '[Schedule] Get Schedule Group';
export const CREATE_SHIFT = '[Schedule] Create Shift';
export const UPDATE_SHIFT = '[Schedule] Update Shift';
export const DELETE_SHIFT = '[Schedule] Delete Shift';
export const UPDATE_SHIFT_AFTER_DRAG = '[Schedule] Update Shift After Drag';
export const SET_SCHEDULE_SCALE = '[Schedule] Set Scale';
export const HOVER_MONTHLY_SCHEDULE_ENTRY = '[Schedule] Hover Monthly Entry';
export const VIEW_SHIFT = '[Schedule] View Shift';
export const GET_SHIFT_EVENT_FORM_DATA = '[Schedule] Get Shift Event Form Data';
export const SET_BIWEEKLY_MODAL_CONFIG = '[Schedule] Set Biweekly Modal Config';
export const RESET_GANTT_STATE = '[Schedule] Reset Gantt State';
export const GET_GANTT_SCHEDULES = '[Schedule] Get Gantt Schedules';
export const CREATE_GANTT_SCHEDULE = '[Schedule] Create Gantt Schedule';
export const UPDATE_GANTT_SCHEDULE = '[Schedule] Update Gantt Schedule';
export const DELETE_GANTT_SCHEDULE = '[Schedule] Delete Gantt Schedule';
export const GET_GANTT_SCHEDULE_ROWS = '[Schedule] Get Gantt Schedule Rows';
export const ADD_GANTT_SCHEDULE_ROW = '[Schedule] Add Gantt Schedule Row';
export const UPDATE_GANTT_SCHEDULE_ROW = '[Schedule] Update Gantt Schedule Row';
export const DELETE_GANTT_SCHEDULE_ROW = '[Schedule] Delete Gantt Schedule Row';
export const GET_GANTT_SCHEDULE_SETTINGS = '[Schedule] Get Gantt Schedule Settings';
export const UPDATE_GANTT_SCHEDULE_SETTINGS = '[Schedule] Update Gantt Schedule Settings';
export const GET_GANTT_SCHEDULE_HOLIDAYS = '[Schedule] Get Gantt Schedule Holidays';
export const UPDATE_GANTT_SCHEDULE_HOLIDAYS = '[Schedule] Update Gantt Schedule Holidays';
export const ADD_GANTT_SCHEDULE_HOLIDAYS = '[Schedule] Add Gantt Schedule Holidays';
export const DELETE_GANTT_SCHEDULE_HOLIDAYS = '[Schedule] Delete Gantt Schedule Holidays';
export const IMPORT_GANTT_SCHEDULE = '[Schedule] Import Gantt Schedule';
export const GET_GANTT_SCHEDULE_LABELS = '[Schedule] Get Gantt Schedule Labels';
export const UPDATE_GANTT_SCHEDULE_FILTERS = '[Schedule] Update Gantt Schedule Filters';
export const GET_USER_GANTT_SCHEDULE_FILTER_VIEWS = '[Schedule] Get Gantt Schedule Filter Views for User';
export const CREATE_USER_GANTT_SCHEDULE_FILTER_VIEW = '[Schedule] Create Gantt Schedule Filter View for User';
export const UPDATE_USER_GANTT_SCHEDULE_FILTER_VIEW = '[Schedule] Update Gantt Schedule Filter View for User';
export const DELETE_USER_GANTT_SCHEDULE_FILTER_VIEW = '[Schedule] Delete Gantt Schedule Filter View for User';
export const UPDATE_FIELD_SCHEDULE_FILTERS = '[Schedule] Update Field Schedule Filters';
export const GET_USER_FIELD_SCHEDULE_FILTER_VIEWS = '[Schedule] Get Field Schedule Filter Views for User';
export const CREATE_USER_FIELD_SCHEDULE_FILTER_VIEW = '[Schedule] Create Field Schedule Filter View for User';
export const UPDATE_USER_FIELD_SCHEDULE_FILTER_VIEW = '[Schedule] Update Field Schedule Filter View for User';
export const DELETE_USER_FIELD_SCHEDULE_FILTER_VIEW = '[Schedule] Delete Field Schedule Filter View for User';

export const GET_EQUIPMENT = '[Equipment] Get';
export const CREATE_EQUIPMENT = '[Equipment] Create';
export const ARCHIVE_EQUIPMENT = '[Equipment] Archive';
export const DELETE_EQUIPMENT = '[Equipment] Delete';
export const UPDATE_EQUIPMENT = '[Equipment] Update';
export const GET_EQUIPMENT_HISTORY = '[Equipment] Get History';
export const UPDATE_EQUIPMENT_AVAILABILITY = '[Equipment] Update Availability';
export const CHECK_IN_EQUIPMENT = '[Equipment] Check In';
export const CHECK_OUT_EQUIPMENT = '[Equipment] Check Out';
export const GET_EQUIPMENT_FILES = '[Equipment] Get Files';
export const GET_EQUIPMENT_NOTES = '[Equipment] Get Notes';
export const ADD_EQUIPMENT_NOTE = '[Equipment] Add Note';
export const GET_EQUIPMENT_STATUSES = '[Equipment] Get Statuses';
export const GET_EQUIPMENT_TYPES = '[Equipment] Get Equipment Types';
export const GET_EQUIPMENT_CUSTOM_DATA = '[Equipment] Get Custom Data';
export const GET_EQUIPMENT_CUSTOM_DATA_MAP = '[Equipment] Get Custom Data Map';
export const GET_GLOBAL_ADDRESS_BOOK = '[Contacts] Get Global Address Book';

export const UPDATE_ADDRESS_BOOK = '[Contacts] Update entry in Address Book';
export const ADD_TO_GLOBAL_ADDRESS_BOOK = '[Contacts] Added entries to Global Address Book';
export const MASS_ADD_TO_LOCAL_ADDRESS_BOOKS = '[Contacts] Shared entries to Contact Address Books';
export const MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK = '[Contacts] Deleted entries from Global Address Book';
export const MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS = '[Contacts] Removed entries from Contact Address Book';

export const CREATE_CUSTOMER = '[Customers] Create';
export const GET_CUSTOMERS = '[Customers] Get';
export const ARCHIVE_CUSTOMERS = '[Customers] Archive';
export const UPDATE_CUSTOMER = '[Customers] Update';
export const DELETE_CUSTOMER = '[Customers] Delete';
export const GET_CUSTOMER_NOTES = '[Customers] Get Notes';
export const ADD_CUSTOMER_NOTE = '[Customers] Add Note';
export const GET_CUSTOMER_CLIENT_COMMS = '[Customers] Get Client Comms';
export const ADD_CUSTOMER_CLIENT_COMMS = '[Customers] Add Client Comms';
export const GET_CUSTOMER_CLIENT_COMMS_UNREAD = '[Customers] Get Unread Client Comms';
export const ADD_CUSTOMER_CLIENT_UNREAD_COMM = '[Customers] Add Unread Client Comm';
export const MARK_CUSTOMER_CLIENT_COMM_AS_READ = '[Customers] Mark Client Comms as Read';
export const GET_CLIENT_BROADCASTS = '[Customers] Get Client Broadcasts';
export const GET_CUSTOMER_LABELS = '[Customers] Get Customer labels';

export const CREATE_VENDOR = '[Vendors] Create';
export const GET_VENDORS = '[Vendors] Get';
export const UPDATE_VENDOR = '[Vendors] Update';
export const DELETE_VENDOR = '[Vendors] Delete';
export const ARCHIVE_VENDOR = '[Vendors] Archive';
export const GET_VENDOR_NOTES = '[Vendors] Get Notes';
export const ADD_VENDOR_NOTE = '[Vendors] Add Note';
export const GET_VENDOR_LABELS = '[Vendors] Get Vendor Labels';

export const GET_MATERIALS = '[Materials] Get';
export const CREATE_MATERIALS = '[Materials] Create';
export const ARCHIVE_MATERIALS = '[Materials] Archive';
export const UPDATE_MATERIAL = '[Materials] Update';
export const DELETE_MATERIAL = '[Materials] Delete';
export const BULK_UPDATE_MATERIALS = '[Materials] Bulk Update';
export const CREATE_MATERIALS_FOLDER = '[Materials] Create Folder';
export const UPDATE_MATERIALS_FOLDER = '[Materials] Update Folder';
export const MOVE_MATERIALS = '[Materials] Move';
export const COPY_MATERIALS = '[Materials] Copy';
export const GET_MATERIAL_LOCATIONS = '[Materials] Get Locations';
export const MASS_UPLOAD_MATERIALS = '[Materials] Mass Upload';
export const MASS_ARCHIVE_MATERIALS = '[Materials] Mass Archive';
export const GET_MATERIAL_UPDATE_HISTORY = '[Materials] Get Update History';
export const MATERIAL_DEBIT = '[Materials] Debit';
export const GET_MATERIAL_ACCESS_LIST = '[Materials] Get Access List';
export const GRANT_MATERIAL_ACCESS = '[Materials] Grant Access';
export const REVOKE_MATERIAL_ACCESS = '[Materials] Revoke Access';

export const GET_GLOBAL_MATERIAL_LOCATIONS = '[Global Material Locations] Get';
export const CREATE_GLOBAL_MATERIAL_LOCATION = '[Global Material Locations] Create';
export const UPDATE_GLOBAL_MATERIAL_LOCATION = '[Global Material Locations] Update';
export const DELETE_GLOBAL_MATERIAL_LOCATION = '[Global Material Locations] Delete';

export const GET_BILLING_RATES = '[Billing Rates] Get';
export const CREATE_BILLING_RATE = '[Billing Rates] Create';
export const DELETE_BILLING_RATE = '[Billing Rates] Delete';
export const UPDATE_BILLING_RATE = '[Billing Rates] Update';

export const GET_UNIONS = '[Unions] Get';
export const UPDATE_UNION = '[Unions] Update';
export const UPDATE_UNION_LOCAL = '[Unions] Update Local';
export const UPDATE_UNION_CLASS = '[Unions] Update Class';
export const CREATE_UNION = '[Unions] Create Union';
export const CREATE_UNION_LOCAL = '[Unions] Create Local';
export const CREATE_UNION_CLASS = '[Unions] Create Class';
export const DELETE_UNION = '[Unions] Delete Union';
export const DELETE_UNION_LOCAL = '[Unions] Delete Local';
export const DELETE_UNION_CLASS = '[Unions] Delete Class';

export const GET_LABELS = '[Labels] Get';
export const CREATE_LABEL = '[Labels] Create Label';
export const UPDATE_LABEL = '[Labels] Update Label';
export const DELETE_LABEL = '[Labels] Delete Label';

export const CREATE_STATUS = '[Statuses] Create Status';
export const UPDATE_STATUS = '[Statuses] Update Status';
export const DELETE_STATUS = '[Statuses] Delete Status';

export const CREATE_BOARD_TEMPLATE = '[Boards] Create Board Template';
export const GET_BOARD_TEMPLATES = '[Boards] Get Board Template';
export const DELETE_BOARD_TEMPLATE = '[Boards] Delete Board Template';
export const UPDATE_BOARD_TEMPLATE = '[Boards] Update Board Template';
export const CREATE_BOARD_CARD_TEMPLATE = '[Boards] Create Board Card Template';
export const GET_BOARD_CARD_TEMPLATES = '[Boards] Get Board Card Templates';
export const DELETE_BOARD_CARD_TEMPLATE = '[Boards] Delete Board Card Template';
export const UPDATE_BOARD_CARD_TEMPLATE = '[Boards] Update Board Card Template';
export const CREATE_BOARD = '[Boards] Create Board';
export const GET_BOARDS = '[Boards] Get Boards';
export const DELETE_BOARD = '[Boards] Delete Board';
export const UPDATE_BOARD = '[Boards] Update Board';
export const GET_BOARD_DETAILS = '[Boards] Get Board Details';
export const GET_BOARD_CARD_FILES = '[Boards] Get Card Files';
export const GET_CARD_BY_ID = '[Boards] Get Card By Id';
export const CLEAR_SELECTED_CARD = '[Boards] Clear Selected Card';
export const CREATE_CARD = '[Boards] Create Card';
export const MASS_UPLOAD_CARDS = '[Boards] Upload Cards';
export const MOVE_CARD = '[Boards] Move Card';
export const UPDATE_CARD = '[Boards] Update Card';
export const DELETE_CARD = '[Boards] Delete Card';
export const TOGGLE_BOARD_LOCK = '[Boards] Toggle Lock';
export const GET_BOARD_CARD_NOTES = '[Boards] Get Card Notes';
export const ADD_BOARD_CARD_NOTE = '[Boards] Add Card Note';
export const GET_CARD_LINKS = '[Boards] Get Card Links';
export const GET_CARDS_BY_LINK = '[Boards] Get Card By Link';
export const GET_MY_CARD_TAGS = '[Boards] Get My Card Tags';
export const MARK_CARD_TAGS_AS_READ = '[Boards] Mark Card Tags as Read';
export const NEW_TAGS_FROM_SOCKET = '[Boards] Received New Tags from Socket';
export const GET_CARD_EVENTS = '[Boards] Get Card Events';
export const GET_CARD_ATTACHMENTS = '[Boards] Get Card Attachments';
export const REORDER_CARDS = '[Boards] Reorder Cards';
export const SWITCH_BOARD_VIEW_TYPE = '[Boards] Switch View Type';
export const SWITCH_BOARD_GANTT_VIEW_TYPE = '[Boards] Switch Gantt View Type';
export const SWITCH_BOARD_GANTT_ZOOM_SCALE = '[Boards] Switch Gantt Zoom Scale';
export const OPEN_CARD_DRAWER = '[Boards] Open Card Drawer';
export const CLOSE_CARD_DRAWER = '[Boards] Close Card Drawer';
export const OPEN_FORM_MAP_DRAWER = '[Boards] Open Card Form Mapping Drawer';
export const CLOSE_FORM_MAP_DRAWER = '[Boards] Close Card Form Mapping Drawer';
export const OPEN_FORM_DRAWER = '[Boards] Open Card Form Drawer';
export const CLOSE_FORM_DRAWER = '[Boards] Close Card Form Drawer';
export const FIX_BOARD_INDICES = '[Boards] Fix Card Indices';
export const GET_CARD_EMAILS = '[Boards] Get Card Emails';
export const GET_CARD_EMAIL_THREAD = '[Boards] Get Card Email Thread';
export const GET_CARD_EMAIL_FILES = '[Boards] Get Card Email Files';
export const SET_BOARD_TARGET_CARD = '[Boards] Set Board Target Card';
export const GET_BOARD_FORM_MAPPINGS = '[Boards] Get Board Form Mappings';
export const CREATE_BOARD_FORM_MAPPING = '[Boards] Create Board Form Mapping';
export const UPDATE_BOARD_FORM_MAPPING = '[Boards] Update Board Form Mapping';
export const DELETE_BOARD_FORM_MAPPING = '[Boards] Delete Board Form Mapping';
export const GET_SAVED_BOARD_CARD_ANALYTICS_REPORTS = '[Boards] Get Saved Board Card Analytics Reports';
export const DELETE_BOARD_CARD_ANALYTICS_REPORT = '[Boards] Delete Board Card Analytics Report';
export const CREATE_BOARD_CARD_ANALYTICS_REPORT = '[Boards] Create Board Card Analytics Report';
export const UPDATE_BOARD_CARD_ANALYTICS_REPORT = '[Boards] Update Board Card Analytics Report';
export const SET_BOARD_CARD_ANALYTICS_CONFIG = '[Boards] Set Board Card Analytics Config';
export const UPDATE_BOARD_CARD_ANALYTICS_FILTERS = '[Boards] Update Board Card Analytics Filters';
export const SELECT_SAVED_BOARD_CARD_REPORT = '[Boards] Select Saved Board Card Report';
export const GET_BOARD_CARD_ANALYTICS_DATA = '[Boards] Get Board Card Analytics Data';
export const CLEAR_BOARD_CARD_ANALYTICS_CONFIG = '[Boards] Clear Board Card Analytics Config';
export const GET_SAVED_BOARD_CARD_DASHBOARDS = '[Boards] Get Saved Board Card Dashboards';
export const SET_BOARD_CARD_DASHBOARD_EDIT = '[Boards] Edit Board Card Dashboard';
export const SELECT_BOARD_CARD_DASHBOARD = '[Boards] Select Board Card Dashboard';
export const CREATE_BOARD_CARD_DASHBOARD = '[Boards] Create Board Card Dashboard';
export const UPDATE_BOARD_CARD_DASHBOARD = '[Boards] Update Board Card Dashboard';
export const DELETE_BOARD_CARD_DASHBOARD = '[Boards] Delete Board Card Dashboard';
export const GET_BOARD_CARD_DASHBOARD_ANALYTICS_DATA = '[Boards] Get Board Card Dashboard Analytics Data';
export const FAVORITE_BOARD_CARD_DASHBOARD = '[Boards] Favorite Board Card Dashboard';
export const SET_INITIAL_BOARD_CARD_DASHBOARD = '[Boards] Select Initial Board Card Dashboard';
export const GET_CARD_SNAPSHOTS = '[Boards] Get Card Snapshots';
export const CLEAR_SNAPSHOT_CARD_DATA = '[Boards] Clear Snapshot Card Data';
export const SET_SNAPSHOT_CARD_DATA = '[Boards] Set Snapshot Card Data';
export const SET_BOARD_FILTERS = '[Boards] Set Filters';
export const GET_ALL_BOARDS_DETAILS = '[Boards] Get Details';
export const CLEAR_CARD_SHADOW = '[Boards] Clear Shadow';
export const SET_CARD_DRAGGING = '[Boards] Set Dragging';

export const GET_BOARDS_LIBRARY = '[Boards Library] Get Library';
export const GET_BOARDS_LIBRARY_TEMPLATE = '[Boards Library] Get Board Library Template';
export const IMPORT_FROM_BOARD_LIBRARY = '[Boards Library] Import Library Template';

export const GET_COMPANY_CUSTOMIZATION_TABS = '[Settings] Get Company Customization Tabs';
export const UPDATE_COMPANY_CUSTOMIZATION_TABS = '[Settings] Update Company Customization Tabs';
export const GET_COMPANY_TRANSLATIONS = '[Settings] Get Company Translations';
export const UPDATE_COMPANY_TRANSLATIONS = '[Settings] Update Company Translations';

export const GET_COMPANY_ROUNDING_POLICIES = '[Settings] Get Company Rounding Policies';
export const CREATE_COMPANY_ROUNDING_POLICY = '[Settings] Create Company Rounding Policy';
export const UPDATE_COMPANY_ROUNDING_POLICY = '[Settings] Update Company Rounding Policy';
export const DELETE_COMPANY_ROUNDING_POLICY = '[Settings] Delete Company Rounding Policy';

export const UPDATE_SCHEDULE_RESTRICTION = '[Settings] Update Schedule Restriction';
export const GET_SCHEDULE_RESTRICTION = '[Settings] Get Schedule Restriction';

export const UPDATE_CLIENT_PORTAL_SETTINGS = '[Client Portal] Update Settings';
export const GET_CLIENT_PORTAL_SETTINGS = '[Client Portal] Get Settings';
export const CLIENT_PORTAL_SELECT_CARD = '[Client Portal] Select Card';
export const GET_CLIENT_PORTAL_ACCOUNTS = '[Client Portal] Get Accounts';
export const CREATE_CLIENT_PORTAL_ACCOUNT = '[Client Portal] Create Account';
export const DELETE_CLIENT_PORTAL_ACCOUNT = '[Client Portal] Delete Account';
export const UPDATE_CLIENT_PORTAL_ACCOUNT = '[Client Portal] Update Account';

export const CLIENT_PORTAL_LOGIN = '[Client Portal] Login';
export const CLIENT_PORTAL_LOGOUT = '[Client Portal] Logout';
export const CLIENT_PORTAL_GET_DATA = '[Client Portal] Get Data';
export const CLIENT_PORTAL_SEND_MESSAGE = '[Client Portal] Send Message';

export const GET_TUTORIALS = '[Tutorials] Get Tutorials';

export const SET_ANALYTICS_CONFIG = '[Analytics] Set Config';
export const GET_ANALYTICS_DATA = '[Analytics] Get Data';
export const GET_SAVED_ANALYTICS_REPORTS = '[Analytics] Get Saved Reports';
export const CREATE_ANALYTICS_REPORT = '[Analytics] Create Report';
export const UPDATE_ANALYTICS_REPORT = '[Analytics] Update Report';
export const SELECT_SAVED_REPORT = '[Analytics] Select Report';
export const DELETE_ANALYTICS_REPORT = '[Analytics] Delete Report';
export const UPDATE_ANALYTICS_FILTERS = '[Analytics] Update Filters';

export const CREATE_NEW_DASHBOARD = '[Dashboard] Create New';
export const SELECT_DASHBOARD = '[Dashboard] Select';
export const SET_DASHBOARD_EDIT = '[Dashboard] Set Edit';
export const CREATE_DASHBOARD = '[Dashboard] Create';
export const UPDATE_DASHBOARD = '[Dashboard] Update';
export const GET_SAVED_DASHBOARDS = '[Dashboard] Get Saved';
export const DELETE_SAVED_DASHBOARD = '[Dashboard] Delete Saved';
export const GET_DASHBOARD_ANALYTICS_DATA = '[Dashboard] Get Dashboard Analytics Data';
export const FAVORITE_DASHBOARD = '[Dashboard] Set Favourite';

export const CREATE_SUBTASK = '[Subtask] Create';
export const GET_SUBTASKS = '[Subtask] Get';
export const UPDATE_SUBTASK = '[Subtask] Update';
export const DELETE_SUBTASK = '[Subtask] Delete';
export const GET_SUBTASK_FILES = '[Subtask] Get Files';
export const GET_SUBTASK_REMINDERS = '[Subtask] Get Reminders';
export const GET_SUBTASK_FORM_DATA = '[Subtask] Get Form Data';
export const CHANGE_SUBTASK_STATUS = '[Subtask] Change Status';

export const CREATE_SUBTASK_TEMPLATE = '[Subtask] Create Template';
export const GET_SUBTASK_TEMPLATES = '[Subtask] Get Templates';
export const UPDATE_SUBTASK_TEMPLATE = '[Subtask] Update Template';
export const DELETE_SUBTASK_TEMPLATE = '[Subtask] Delete Template';
export const GET_SUBTASK_TEMPLATE_FORM_DATA = '[Subtask] Get Template Form Data';
export const RESET_SUBTASK_FORM_DATA = '[Subtask] Reset Form Data';

export const CLEAR_SELECTED_INVOICE_FILES = '[Invoice] Clear Selected Invoice Files';
export const GET_INVOICE_FILES_BY_ID = '[Invoice] Get Invoice Files by Id';

export const CREATE_INVOICE = '[Invoice] Create';
export const DELETE_INVOICE = '[Invoice] Delete';
export const UPDATE_INVOICE = '[Invoice] Update';
export const GET_INVOICES = '[Invoice] Get';
export const GET_INVOICE_STATUS_LABELS = '[Invoice] Get Status Labels';

export const CREATE_INVOICE_COSTCODE_DISTRIBUTION = '[Invoice] Create Invoice Costcode Distribution';
export const UPDATE_INVOICE_COSTCODE_DISTRIBUTION = '[Invoice] Update Invoice Costcode Distribution';
export const DELETE_INVOICE_COSTCODE_DISTRIBUTION = '[Invoice] Delete Invoice Costcode Distribution';

export const GET_SALES_RABBIT_WORKFLOWS = '[SalesRabbit] Get Workflows';
export const CREATE_SALES_RABBIT_WORKFLOW = '[SalesRabbit] Create Workflow';
export const UPDATE_SALES_RABBIT_WORKFLOWS = '[SalesRabbit] Update Workflow';
export const DELETE_SALES_RABBIT_WORKFLOWS = '[SalesRabbit] Delete Workflow';

export const GET_SALES_RABBIT_REGIONS = '[SalesRabbit] Get Regions';
export const CREATE_SALES_RABBIT_REGION = '[SalesRabbit] Create Region';
export const UPDATE_SALES_RABBIT_REGION = '[SalesRabbit] Update Region';
export const DELETE_SALES_RABBIT_REGION = '[SalesRabbit] Delete Region';

export const GET_ECLIPSE_DIVISIONS = '[Eclipse] Get Divisions';
export const ECLIPSE_LINK_DIVISION = '[Eclipse] Link Division';
export const ECLIPSE_IMPORT_DIVISION = '[Eclipse] Import Division';
export const ECLIPSE_SET_DIVISION = '[Eclipse] Set Selected Division';

export const GET_ECLIPSE_WORKFLOWS = '[Eclipse] Get Workflows';
export const CREATE_ECLIPSE_WORKFLOW = '[Eclipse] Create Workflow';
export const UPDATE_ECLIPSE_WORKFLOW = '[Eclipse] Update Workflow';
export const DELETE_ECLIPSE_WORKFLOW = '[Eclipse] Delete Workflow';

export const GET_BUCKET_TEMPLATES = '[Bucket] Get Templates';
export const CREATE_BUCKET_TEMPLATE = '[Bucket] Create Template';
export const UPDATE_BUCKET_TEMPLATE = '[Bucket] Update Template';
export const DELETE_BUCKET_TEMPLATE = '[Bucket] Delete Template';
export const GET_BUCKETS = '[Bucket] Get Buckets';
export const CREATE_BUCKET = '[Bucket] Create Bucket';
export const UPDATE_BUCKET = '[Bucket] Update Bucket';
export const DELETE_BUCKET = '[Bucket] Delete Bucket';
export const GET_BUCKET_NOTES = '[Bucket] Get Bucket Notes';
export const ADD_BUCKET_NOTE = '[Bucket] Add Bucket Note';
export const GET_BUCKET_CUSTOM_DATA = '[Bucket] Get Custom Data';

export const GET_SEARCH_QUERIES = '[Search] Get Queries';
export const CREATE_SEARCH_QUERY = '[Search] Create Query';
export const UPDATE_SEARCH_QUERY = '[Search] Update Query';
export const DELETE_SEARCH_QUERY = '[Search] Delete Query';
export const SEARCH_FORMS = '[Search] Search Forms';
export const SEARCH_BOARDS = '[Search] Search Boards';

export default {};
