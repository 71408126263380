import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Table,
  notification,
} from 'antd';
import { DateTime, Duration } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';
import * as Sentry from '@sentry/react';

import { getContactAddressBooks } from 'ontraccr-common/lib/Contacts';
import ManualEntryFormDrawer from '../../../../clock/ManualEntry/ManualEntryFormDrawer';
import OnTraccrButton from '../../../../common/buttons/OnTraccrButton';
import TimeEntryTableTeamSelection from './TimeEntryTableTeamSelection';
import { convertLowerCamelCaseToText, getIdMap } from '../../../../helpers/helpers';
import {
  UNPHASED,
  getAllTimesText,
  loadManualEntryData,
  foundTaskConflict,
  loadTasks,
} from '../../../../clock/ManualEntry/manualEntryHelpers';
import FilteredUserSelection from '../../../../common/inputs/FilteredUserSelector';
import Permissions from '../../../../auth/Permissions';
import DisplayText from '../../../../common/text/DisplayText';
import { generateId, getLinkId } from '../../../formHelpers';
import { getTablets } from '../../../../tablets/state/tablets.actions';
import TimeEntryTableInput from './TimeEntryTableInput';
import BorderlessButton from '../../../../common/buttons/BorderlessButton';
import {
  defaultFormatter,
  getColumnWidth,
  getSimpleSelectOptions,
  getTableInputParameters,
  parseCustomDataFromTemplate,
  simpleEquality,
} from './TimeEntryTable.helpers';
import {
  DEFAULT_FIELD_TYPES,
  EDITABLE_DEFAULT_FIELD_TYPES,
  EDITABLE_CUSTOM_FIELD_TYPES,
  customRenderMap,
} from './TimeEntryTable.constants';
import { TIME_TYPE_FLAG } from '../../../../constants/Flags';
import { prepareResponsePayload } from '../../../ResponderHelpers';
import { getEnteredViaTableText } from '../../../../helpers/tasks';
import { sortEntries } from '../../../../timecards/timecardListSummaryView.helpers';

const getProjectsTasks = async ({
  preloadDateField,
  preloadDateModifier,
  preloadDateCount,
  linkedStart,
  linkedEnd,
  linkedProjectId,
}) => {
  try {
    const now = DateTime.local();
    const startTimeToFilter = preloadDateField
      ? linkedStart
      : now.startOf('day').minus({ [preloadDateModifier]: preloadDateCount });
    const endTimeToFilter = preloadDateField ? linkedEnd : now.endOf('day');

    const projectTasks = await loadTasks({
      projectId: linkedProjectId,
      startTime: startTimeToFilter.toMillis(),
      endTime: endTimeToFilter.toMillis(),
    });

    return projectTasks;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

const defaultTimeEntry = {
  // user - dynamic default
  // date - dynamic default
  type: 'work',
  // division - dynamic default
  project: null, // potentially dynamic based on selected project
  phase: null,
  costcode: null,
  local: null,
  class: null,
  sageShift: null,
  // hourBased: true,
  note: '',
  status: 'new',
};

const getEnteredViaColumn = (_, record) => getEnteredViaTableText(record.enteredViaMetadata);

const columnTitleMap = {
  formattedDuration: 'Duration',
  formattedStartTime: 'Start Time',
  formattedEndTime: 'End Time',
};

const getColumnTitle = ({
  requiredColumns,
  title,
}) => (
  <div className={requiredColumns && 'form-required-field'}>
    {convertLowerCamelCaseToText(columnTitleMap[title] ?? title)}
  </div>
);

const getTimeEntryColumnMap = ({
  requiredColumns,
  timezone,
  selectOptionProps,
  customDropdownProps,
  onUpdate,
  enableWarningThreshold,
  warningThreshold,
  preventEdits,
  settings = {},
}) => {
  const timeEntryColumnMap = {};

  DEFAULT_FIELD_TYPES.forEach((fieldType) => {
    const col = {
      title: getColumnTitle({ requiredColumns, title: fieldType }),
      dataIndex: fieldType,
      width: getColumnWidth(fieldType),
      key: fieldType,
    };

    if (customRenderMap[fieldType]) {
      col.setRender = customRenderMap[fieldType];
    }

    if (EDITABLE_DEFAULT_FIELD_TYPES.includes(fieldType)) {
      col.setRender = (record) => (value, tableRecord) => {
        const {
          displayFormatter = defaultFormatter,
          areEqual = simpleEquality,
          getSelectOptions = getSimpleSelectOptions(fieldType),
          value: parsedValue = value,
          inputType = 'text',
          property = fieldType,
          readOnly = false,
          allowClear = true,
        } = getTableInputParameters({
          value,
          entry: record ?? tableRecord,
          type: fieldType,
          enableWarningThreshold,
          warningThreshold,
          settings,
        });

        return (
          <TimeEntryTableInput
            title={`Update ${convertLowerCamelCaseToText(fieldType)}`}
            task={record ?? tableRecord}
            value={parsedValue}
            fieldType={fieldType}
            inputType={inputType}
            property={property}
            displayFormatter={displayFormatter}
            getSelectOptions={getSelectOptions}
            selectOptionProps={selectOptionProps}
            customDropdownProps={customDropdownProps}
            timezone={timezone}
            areEqual={areEqual}
            width={getColumnWidth(fieldType)}
            onUpdate={onUpdate}
            readOnly={preventEdits || readOnly}
            allowClear={allowClear}
          />
        );
      };
    }

    timeEntryColumnMap[fieldType] = col;
  });

  return timeEntryColumnMap;
};

const timeEntryData = ({
  onDelete,
  onEdit,
  onUpdate,
  divisionId,
  columns,
  customFieldMap,
  defaultCustomData,
  isDisplay,
  requiredColumns,
  customDropdownProps,
  selectOptionProps,
  timezone,
  t,
  responding,
  dataSource,
  shouldTransposeData,
  shouldShowEmptyCustomFields,
  enableWarningThreshold,
  warningThreshold,
  preventEdits,
}) => {
  const { settings = {} } = customDropdownProps;
  const cols = [];
  const timeEntryColumnMap = getTimeEntryColumnMap({
    requiredColumns,
    timezone,
    t,
    selectOptionProps,
    customDropdownProps,
    onUpdate,
    enableWarningThreshold,
    warningThreshold,
    preventEdits: preventEdits || isDisplay,
    settings,
  });

  columns.forEach((col) => {
    if (col.key in timeEntryColumnMap) {
      cols.push(timeEntryColumnMap[col.key]);
    } else if (col.isCalculation) {
      cols.push({
        title: <div>{col.name}</div>,
        width: 100,
        dataIndex: col.name,
      });
    } else if (col.key.startsWith('field-')) {
      // We only want to show custom fields if they have a value
      // We trim them because the date/time field will always have a value as its an empty space
      if (
        !shouldShowEmptyCustomFields
        && !dataSource?.some((entry) => !!entry[col.key]?.toString()?.trim?.())
      ) return;

      cols.push({
        title: <div className={requiredColumns && 'form-required-field'}>{col.name}</div>,
        width: 260,
        dataIndex: col.key,
        setRender: (record) => (value, tableRecord) => {
          const {
            [col.key]: {
              type = '',
              id = '',
              configProps = {},
            } = {},
          } = customFieldMap;

          const task = record ?? tableRecord;

          const {
            divisionId: taskDivId,
          } = task;

          if (EDITABLE_CUSTOM_FIELD_TYPES.has(type) && divisionId === taskDivId) {
            const {
              displayFormatter = defaultFormatter,
              areEqual = simpleEquality,
              getSelectOptions = getSimpleSelectOptions(type),
              value: parsedValue = value,
              inputType = 'text',
              readOnly = false,
              allowClear = true,
            } = getTableInputParameters({
              value, entry: task, type, id, settings,
            });

            const { title } = configProps;

            return (
              <TimeEntryTableInput
                title={`Update ${title}`}
                task={task}
                fieldType={type}
                inputType={inputType}
                value={parsedValue}
                property={id}
                displayFormatter={displayFormatter}
                areEqual={areEqual}
                getSelectOptions={getSelectOptions}
                customDropdownProps={customDropdownProps}
                selectOptionProps={selectOptionProps}
                defaultCustomData={defaultCustomData}
                width={250}
                onUpdate={onUpdate}
                configProps={configProps}
                timezone={timezone}
                responding={responding}
                readOnly={preventEdits || readOnly || isDisplay}
                allowClear={allowClear}
              />
            );
          }
          return value;
        },
      });
    }
  });

  if (!isDisplay && !preventEdits) {
    cols.push({
      title: '',
      width: 10,
      dataIndex: '',
      setRender: (record) => (_, tableRecord) => (
        <BorderlessButton
          iconNode={<EditOutlined />}
          onClick={() => onEdit((record ?? tableRecord).id)}
          style={shouldTransposeData ? { width: 'unset' } : {}}
        />
      ),
    });
    cols.push({
      title: '',
      width: 10,
      dataIndex: '',
      setRender: (record) => (_, tableRecord) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete((record ?? tableRecord).id)}
          style={shouldTransposeData ? { width: 'unset' } : {}}
        />
      ),
    });
  }

  if (shouldTransposeData) {
    const rows = [{}, ...dataSource].map((entry, entryIndex) => ({
      title: '',
      dataIndex: '',
      width: 260,
      render: (_value, _record, index) => {
        // 0th column is the column title row
        if (!entryIndex) {
          return (
            <span style={{ fontWeight: 650, backgroundColor: '#fafafa' }}>
              {cols[index].title}
            </span>
          );
        }

        const relevantCol = cols[index];
        if (!relevantCol) return null;

        const { setRender, dataIndex } = relevantCol;
        if (!setRender) return entry[dataIndex];
        return setRender(entry)(entry[dataIndex], entry);
      },
    }));

    return {
      columns: rows,
      rows: cols,
    };
  }

  const ourColumns = cols.map((col) => {
    let render = col.setRender ? col.setRender() : (value) => value;
    if (col.key === 'enteredVia') render = getEnteredViaColumn;

    return {
      ...col,
      render,
    };
  });

  return {
    rows: dataSource,
    columns: ourColumns,
  };
};

export default function TimeEntryTablePreview({
  columns = [],
  previewProps = {},
  id,
  setPreviewProps,
  isDisplay,
  setResponses,
  responses = {},
  responding = false,
  projectId: formProjectId,
  divisions = [],
  configProps = {},
  showCondensedView,
  t,
  createTimeTableIds,
  subContractMap = {},
  subContractCoMap = {},
  scheduleOfValues = {},

  customerToLabel = {},
  userToLabel = {},
  vendorToLabel = {},

  setCustomerIds,
  setProjectIds,
  setVendorIds,
  setContactIds,
  setCostcodeIds,
  setUserIds: setDropdownUserIds,
  setBucketIds,
  setSubContractIds,

  templateId: dropdownTemplateId,
  customerId,
  projectId,
  vendorId,

  selectedBucket = null,
  selectedBucketTypeToIdMap = {},
  selectedBucketTypes = [],
}) {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const teams = useSelector((state) => state.teams.teams);
  const divisionMap = useSelector((state) => state.settings.divisions);
  const userDivisions = useSelector((state) => state.users.userDivisions);
  const projects = useSelector((state) => state.projects.projects);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const { locals = [], classes = [] } = useSelector((state) => state.unions);
  const sageShifts = useSelector((state) => state.sage.shifts);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const formTemplates = useSelector((state) => state.forms.templates);
  const tablets = useSelector((state) => state.tablets);
  const customFields = useSelector((state) => state.timecards.customFields);

  const customers = useSelector((state) => state.customers.customers);
  const equipment = useSelector((state) => state.equipment.equipment);
  const projectEquipment = useSelector((state) => state.equipment.projectEquipment);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);
  const vendors = useSelector((state) => state.vendors.vendors);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const labels = useSelector((state) => state.labels);
  const forms = useSelector((state) => state.forms.lightWeightForms);
  const subContractForms = useSelector((state) => state.forms.lightWeightSubContracts);
  const bucketTemplateMap = useSelector((state) => state.buckets.bucketTemplateMap);
  const buckets = useSelector((state) => state.buckets.buckets);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);
  const { settings = {}, paidFlags = [] } = useSelector((state) => state.settings.company);

  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const localMap = useMemo(() => getIdMap(locals), [locals]);
  const phaseMap = useMemo(() => getIdMap(phases), [phases]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const bucketIdMap = useMemo(() => getIdMap(buckets), [buckets]);
  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const tabletMap = useMemo(() => getIdMap(tablets), [tablets]);
  const activeCostcodes = useMemo(() => (costcodes.filter((cc) => cc.active)), [costcodes]);
  const activeProjects = useMemo(() => (projects.filter((project) => project.active)), [projects]);

  const contactAddressBooks = useMemo(() => (
    getContactAddressBooks(globalAddressBooks)
  ), [globalAddressBooks]);

  const [showDrawer, setShowDrawer] = useState(false);
  const [showTeamSelect, setShowTeamSelect] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [lastProjectId, setLastProjectId] = useState();
  const [lastDateValue, setLastDateValue] = useState({});
  const [hasPreloaded, setHasPreloaded] = useState(false);
  const [fileMap, setFileMap] = useState({});
  const [updateEntry, setUpdateEntry] = useState();

  const {
    hideAddNewButton,
    hideAddFromTeam,
    requiredColumns,
    lockEntryToAuthor,
    preloadExistingEntries,
    preloadDateCount = 1,
    preloadDateModifier = 'days',
    preloadProject,
    preloadDropdown,
    preloadStatuses = [],
    preloadDateField,
    shouldTransposeData = false,
    shouldShowEmptyCustomFields = true,
    warnOnConflict = false,
    enableWarningThreshold = false,
    warningThreshold,
    preventEdits = false,
  } = configProps;
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
    filterLabels = [],
    timezone: previewTimezone,
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
      timezone: responseTimezone,
    } = {},
  } = responses;

  const timezone = useMemo(() => {
    if (responseTimezone) return responseTimezone;
    if (previewTimezone) return previewTimezone;
    return DateTime.local().timezone;
  }, [responseTimezone, previewTimezone]);

  const ourCustomFields = useMemo(() => {
    const { [divisions?.[0]]: { fields = [] } = {} } = customFields;
    return fields || {};
  }, [customFields, divisions]);

  const { responses: defaultCustomData } = prepareResponsePayload({
    sections: ourCustomFields,
  });

  const selected = useMemo(() => {
    if (!responding) return previewSelected;

    responseSelected.forEach((entry) => {
      const entryRef = entry;

      // Revert formatting changes caused by constructFormPayloadForAPI
      if (Array.isArray(entryRef.customData)) {
        const parsedCustomData = {};
        entryRef.customData.forEach(({ fields }) => {
          fields.forEach(({
            response, sectionId, fieldId, ...rest
          }) => {
            if (sectionId !== 'timecard') {
              parsedCustomData[fieldId] = {
                ...response,
                sectionId,
                fieldId,
                ...rest,
              };
            }
          });
        });
        delete entryRef.customData;
        entryRef.customData = parseCustomDataFromTemplate(parsedCustomData, defaultCustomData);
      }
    });
    return responseSelected;
  }, [responseSelected, previewSelected, defaultCustomData]);

  const {
    id: userId,
    classId: defaultClassId,
  } = selectedUser || {};

  const customFieldMap = useMemo(() => {
    const columnIds = columns.map(({ key }) => key);

    const relevantSections = ourCustomFields.filter(({ id: sectionId }) => sectionId !== 'timecard');
    const relevantCustomFields = [];

    relevantSections?.forEach(({ fields: sectionFields }) => {
      const filteredSection = sectionFields?.filter((field) => {
        const {
          id: fieldId,
        } = field;
        return columnIds.includes(fieldId);
      }).map((field) => ({
        id: field.id,
        type: field?.selectedType,
        configProps: field.configProps,
      }));
      relevantCustomFields.push(...filteredSection);
    });

    return getIdMap(relevantCustomFields);
  }, [ourCustomFields]);

  const closeDrawer = useCallback(() => setShowDrawer(false), []);
  const closeTeamSelect = useCallback(() => setShowTeamSelect(false), []);
  const onAddFromTeamClicked = useCallback(() => setShowTeamSelect(true), []);

  const createTimeEntry = useMemo(() => (
    createTimeTableIds?.includes(id)
  ), [createTimeTableIds, id]);

  const linkedProjectId = useMemo(() => (
    getLinkId({ responses, defaultValue: null, linkField: preloadDropdown })
  ), [responses, preloadDropdown]);

  const defaultProjectId = preloadProject ?? linkedProjectId ?? formProjectId;

  const linkedDateValue = useMemo(() => {
    if (!preloadDateField) return null;
    const {
      [preloadDateField]: dateResponse = {},
    } = responses;

    const { startTime, endTime, date } = dateResponse;

    if (date) { // date-time
      return {
        startTime: DateTime.fromMillis(date).startOf('day'),
        endTime: DateTime.fromMillis(date).endOf('day'),
      };
    }

    if (startTime && endTime) { // date-time range
      return {
        startTime: DateTime.fromMillis(startTime).startOf('day'),
        endTime: DateTime.fromMillis(endTime).endOf('day'),
      };
    }

    return null;
  }, [responses, preloadDateField]);

  const userOpts = useMemo(() => {
    const allUsers = divisions?.map((divId) => {
      const {
        [divId]: {
          users: divisionUsers = new Set(),
        } = {},
      } = divisionMap;
      return Array.from(divisionUsers);
    }) ?? [];
    const userSet = new Set(allUsers.flat());
    return users.filter((user) => user.active && userSet.has(user.id));
  }, [users, divisionMap, divisions]);

  const onAddFromUserClicked = useCallback((newUserId) => {
    const newSelected = userMap[newUserId];
    if (!newSelected) return;
    setSelectedUser(newSelected);
    setSelectedEntry();
    setShowDrawer(true);
  }, [userMap]);

  const onAddNewClicked = useCallback(() => {
    setSelectedEntry();
    onAddFromUserClicked(userId || Permissions.id);
  }, [userId]);

  const updateResponses = useCallback((newData = {}) => {
    const fullResponses = {
      ...responses,
      [id]: {
        ...(responses[id]),
        ...newData,
        timezone: timezone ?? DateTime.local().zoneName,
        createTimeEntry,
      },
    };
    setResponses(fullResponses);
  }, [
    responses,
    id,
    setResponses,
    createTimeEntry,
  ]);

  const onSubmitEntry = useCallback(async ({ task: manualEntry } = {}) => {
    if (!manualEntry) return;

    const newEntry = TaskHelpers.formatTimeEntryTableEntry({
      entry: manualEntry,
      userId,
      selectedEntry,
      classesMap: classMap,
      phaseMap,
      userMap,
      divisions: divisionMap,
      projectMap,
      costcodeMap,
      localMap,
      sageShifts,
      generatedId: generateId(),
      getAllTimesText,
    });

    newEntry.time = getAllTimesText(
      newEntry,
      {
        returnComponent: false,
        joinText: '\n',
        showRegularTypeText: false,
        addTypeAsSuffix: true,
      },
    );

    let newSelected;

    if (selectedEntry) {
      newSelected = selected.map((e) => (e.id === selectedEntry.id ? newEntry : e));
    } else {
      newSelected = selected.concat([newEntry]);
    }

    if (warnOnConflict && !newEntry.hourBased) {
      const {
        id: entryId,
        userId: taskUserId,
      } = newEntry;

      const taskDate = TaskHelpers.getTaskDate(newEntry);

      const startTime = taskDate.startOf('day').toMillis();
      const endTime = taskDate.endOf('day').toMillis();
      const existingUserEntries = await loadTasks({
        startTime,
        endTime,
        userId,
      });

      const conflictMessage = foundTaskConflict({
        editIds: new Set([entryId]),
        currentTasks: existingUserEntries,
        newTasks: newSelected.filter((e) => e.userId === taskUserId),
        phases,
        projectIdMap: projectMap,
        userMap,
        costcodeIdMap: costcodeMap,
      });

      if (conflictMessage) {
        notification.error({
          message: conflictMessage,
          getContainer: null,
          placement: 'bottomRight',
          bottom: 50,
          duration: 10,
        });
        return;
      }
    }

    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
    setSelectedEntry(null);
    setUpdateEntry(null);
    setShowDrawer(false);
  }, [
    selected,
    userId,
    selectedEntry,
    responding,
    columns,
    userMap,
    divisionMap,
    projectMap,
    phaseMap,
    costcodeMap,
    localMap,
    classMap,
    sageShifts,
    updateResponses,
    phases,
    warnOnConflict,
  ]);

  const handleHourBasedEntry = ({ entry, formattedEntry }) => {
    const {
      dateTimestamp: entryDate,
      hours = 0,
      minutes = 0,
      otHours,
      otMinutes,
      breakHours,
      breakMinutes,
      doubleOTHours,
      doubleOTMinutes,
      startTime,
      endTime,
      otStartTime,
      otEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
      breakStartTime,
      breakEndTime,
    } = entry;

    const formattedEntryRef = formattedEntry;

    const durationMs = Duration.fromObject({ hours, minutes }).as('milliseconds');
    formattedEntryRef.startTime = startTime ?? DateTime.fromMillis(entryDate).startOf('day').toMillis();
    formattedEntryRef.endTime = endTime ?? formattedEntry.startTime + durationMs;

    if (otHours || otMinutes) {
      const otDurationMs = Duration.fromObject({ hours: otHours, minutes: otMinutes }).as('milliseconds');
      formattedEntryRef.otStartTime = otStartTime ?? formattedEntry.endTime;
      formattedEntryRef.otEndTime = otEndTime ?? formattedEntry.otStartTime + otDurationMs;
    }

    if (breakHours || breakMinutes) {
      const breakDurationMs = Duration.fromObject({ hours: breakHours, minutes: breakMinutes }).as('milliseconds');
      formattedEntryRef.breakStartTime = breakStartTime ?? formattedEntry.endTime;
      formattedEntryRef.breakEndTime = breakEndTime
      ?? formattedEntry.breakStartTime + breakDurationMs;
    }

    if (doubleOTHours || doubleOTMinutes) {
      const doubleOTDurationMs = Duration.fromObject({ hours: doubleOTHours, minutes: doubleOTMinutes }).as('milliseconds');
      formattedEntryRef.doubleOTStartTime = doubleOTStartTime ?? formattedEntry.endTime;
      formattedEntryRef.doubleOTEndTime = doubleOTEndTime
      ?? formattedEntry.doubleOTStartTime + doubleOTDurationMs;
    }
  };

  const handleNonHourBasedEntry = ({ entry, formattedEntry }) => {
    const formattedEntryRef = formattedEntry;

    const {
      startTime,
      endTime,
      otStartTime,
      otEndTime,
      breakStartTime,
      breakEndTime,
      doubleOTStartTime,
      doubleOTEndTime,
    } = entry;
    formattedEntryRef.startTime = startTime;
    formattedEntryRef.endTime = endTime;
    formattedEntryRef.otStartTime = otStartTime;
    formattedEntryRef.otEndTime = otEndTime;
    formattedEntryRef.breakStartTime = breakStartTime;
    formattedEntryRef.breakEndTime = breakEndTime;
    formattedEntryRef.doubleOTStartTime = doubleOTStartTime;
    formattedEntryRef.doubleOTEndTime = doubleOTEndTime;
  };

  const updateFormattedEntryAndUser = useCallback((editId) => {
    const entry = selected.find(({ id: eId }) => eId === editId);
    if (!entry) return;

    const {
      id: entryId,
      userId: entryUserId,
      divisionId: entryDivisionId,
      type,
      dateTimestamp: entryDate,
      hourBased,
      projectId: entryProjectId,
      phaseId: entryPhaseId,
      costcodeId: entryCostcodeId,
      localId: entryLocalId,
      classId: entryClassId,
      sageShiftId: entrySageShiftId,
      note,
      customData,
      customDataArray,
    } = entry;

    const formattedEntry = {
      id: entryId,
      userId: entryUserId || userId || Permissions.id,
      divisionId: entryDivisionId,
      type,
      date: entryDate
        ? DateTime.fromMillis(entryDate, { zone: timezone }).toISODate()
        : undefined,
      hourBased,
      projectId: entryProjectId,
      phaseId: entryPhaseId || (entryProjectId && entryCostcodeId ? 'Unphased' : undefined),
      costcodeId: entryCostcodeId,
      localId: entryLocalId,
      classId: entryClassId,
      sageShiftId: entrySageShiftId,
      note,
      customData: customDataArray ?? customData, // array is used when editing a pre-loaded entry
      timezone,
    };

    if (hourBased) {
      handleHourBasedEntry({ entry, formattedEntry });
    } else {
      handleNonHourBasedEntry({ entry, formattedEntry });
    }

    const entryUser = userMap[formattedEntry.userId];

    setSelectedUser(entryUser);
    setSelectedEntry(formattedEntry);
  }, [selected, userId, userMap, setSelectedUser, setSelectedEntry]);

  const onEdit = useCallback((editId) => {
    updateFormattedEntryAndUser(editId);
    setShowDrawer(true);
  }, [updateFormattedEntryAndUser, setShowDrawer]);

  const onDelete = useCallback((deletedId) => {
    const newSelected = selected.filter((item) => item.id !== deletedId);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: selected.filter((item) => item.id !== deletedId),
      });
    }
  }, [responding, columns, previewProps, selected, updateResponses]);

  const onUpdate = useCallback((updateId, entry) => {
    updateFormattedEntryAndUser(updateId);
    setUpdateEntry(entry);
  }, [updateFormattedEntryAndUser, setUpdateEntry]);

  useEffect(() => {
    if (selectedEntry && updateEntry) {
      onSubmitEntry({ task: updateEntry });
    }
  }, [updateEntry, selectedEntry, onSubmitEntry]);

  /*
   * After selecting a team Id,
   * Generate a row with default vaules for each team member
   */
  const onAddFromTeam = useCallback((teamId) => {
    const selectedTeam = teams.find(({ id: tId }) => tId === teamId);
    const { divisionId: teamDivision, members = [] } = selectedTeam;
    const { enableHourBasedTracking = false } = settings;
    if (!members.length) {
      closeTeamSelect();
      return;
    }
    const newEntries = [];
    const now = generateId();
    const filteredMembers = members.filter(({ id: mId }) => userMap[mId]?.active);
    filteredMembers.forEach((member, idx) => {
      const classId = userMap[member.id]?.classId;
      const localId = classMap[classId]?.localId;
      const localName = localMap[localId]?.name;
      const className = classMap[classId]?.name;

      newEntries.push({
        ...defaultTimeEntry,
        id: now * 10 + idx,
        dateTimestamp: now,
        date: TaskHelpers.formatDate(now),
        userId: member.id,
        user: userMap[member.id]?.name,
        divisionId: teamDivision,
        division: divisionMap[teamDivision]?.name,
        hourBased: enableHourBasedTracking,
        projectId: defaultProjectId,
        project: defaultProjectId ? projectMap[defaultProjectId]?.name : undefined,
        localId,
        classId,
        local: localId ? localName : null,
        class: classId ? className : null,
      });
    });

    const newSelected = selected.concat(newEntries);
    if (responding) {
      updateResponses({
        values: newSelected,
        columns,
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected,
      });
    }
    closeTeamSelect();
  }, [
    selected,
    previewProps,
    responding,
    defaultProjectId,
    linkedProjectId,
    columns,
    updateResponses,
    teams,
    userMap,
    divisionMap,
    projectMap,
  ]);

  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);

  const customerList = useMemo(() => Object.values(customers), [customers]);
  const vendorList = useMemo(() => Object.values(vendors), [vendors]);
  const parsedFormTemplates = useMemo(() => Object.values(formTemplates), [formTemplates]);

  const customDropdownProps = useMemo(() => ({
    settings,
    filterLabels,
    // *** Data Lists ***
    customers: customerList,
    vendors: vendorList,
    projects,
    users,
    equipment,
    costcodes,
    labels,
    forms,
    phases,
    formTemplates: parsedFormTemplates,
    subContractForms,
    divisions,

    // *** Subtypes ***
    projectTypes,
    equipmentTypes,

    // *** Maps ***
    bucketTemplateMap,
    boardDetailsMap,
    subContractMap,
    subContractCoMap,
    contactAddressBooks,
    globalAddressBooks,
    projectEquipment,
    costcodeIdMap,
    projectIdMap,
    phaseIdMap,
    userIdMap: userMap,
    vendorIdMap: vendors,
    bucketIdMap,
    equipmentTypeMap,
    divisionMap,
    scheduleOfValues,

    // *** Ids ***
    customerId,
    projectId,
    templateId: dropdownTemplateId,
    vendorId,

    // *** Set Ids ***
    setCustomerIds,
    setProjectIds,
    setVendorIds,
    setBucketIds,
    setUserIds: setDropdownUserIds,
    setContactIds,
    setCostcodeIds,
    setSubContractIds,

    // *** x to label ***
    customerToLabel,
    userToLabel,
    vendorToLabel,

    // *** Buckets ***
    selectedBucket,
    selectedBucketTypeToIdMap,
    selectedBucketTypes,
  }), [
    settings,

    customerList,
    vendorList,
    projects,
    users,
    equipment,
    costcodes,
    labels,
    forms,
    phases,
    parsedFormTemplates,
    subContractForms,
    divisions,

    projectTypes,
    equipmentTypes,

    bucketTemplateMap,
    boardDetailsMap,
    subContractMap,
    contactAddressBooks,
    projectEquipment,
    costcodeIdMap,
    projectIdMap,
    phaseIdMap,
    userMap,
    bucketIdMap,
    equipmentTypeMap,
    divisionMap,
    scheduleOfValues,

    customerToLabel,
    userToLabel,
    vendorToLabel,

    setCustomerIds,
    setProjectIds,
    setVendorIds,
    setBucketIds,
    setDropdownUserIds,
    setContactIds,
    setCostcodeIds,
    setSubContractIds,

    customerId,
    projectId,
    vendorId,

    selectedBucket,
    selectedBucketTypeToIdMap,
    selectedBucketTypes,
  ]);

  const selectOptionProps = useMemo(() => ({
    activeCostcodes,
    activeProjects,
    activePhases: phases,
    user: selectedUser,
    users,
    teams,
    userDivisions,
    phaseId: UNPHASED,
    settings,
    localIdMap: localMap,
    classIdMap: classMap,
  }), [
    activeCostcodes,
    activeProjects,
    phases,
    selectedUser,
    users,
    teams,
    userDivisions,
    settings,
    localMap,
    classMap,
  ]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    columns: tableColumns,
    rows: tableRows,
  } = useMemo(() => (
    timeEntryData({
      onDelete,
      onEdit,
      onUpdate,
      divisionId: divisions?.[0],
      columns,
      customFieldMap,
      defaultCustomData,
      isDisplay,
      requiredColumns,
      selectOptionProps,
      customDropdownProps,
      t,
      timezone,
      responding,
      dataSource,
      shouldTransposeData,
      shouldShowEmptyCustomFields,
      enableWarningThreshold,
      warningThreshold,
      preventEdits,
    })
  ), [
    onEdit,
    onDelete,
    onUpdate,
    columns,
    customFieldMap,
    isDisplay,
    requiredColumns,
    timezone,
    responding,
    responses,
    dataSource,
    shouldTransposeData,
    shouldShowEmptyCustomFields,
    enableWarningThreshold,
    warningThreshold,
    preventEdits,
  ]);

  // pre-load entries
  useEffect(() => {
    // early returns
    if (!responding) return;
    if (!preloadExistingEntries) return;
    if (!(preloadProject || linkedProjectId) || !preloadStatuses?.length) return;
    if (preloadDateField && !linkedDateValue) return;

    const { startTime: linkedStart, endTime: linkedEnd } = linkedDateValue ?? {};
    const { startTime: lastStart, endTime: lastEnd } = lastDateValue ?? {};

    const hasSameDate = linkedDateValue
      && linkedStart?.toMillis() === lastStart?.toMillis()
      && linkedEnd?.toMillis() === lastEnd?.toMillis();

    const hasSameProject = linkedProjectId && linkedProjectId === lastProjectId;

    const hasAlreadyPreloaded = preloadProject && hasPreloaded;

    // conditional returns
    if (
      (preloadDateField && hasSameDate && (hasSameProject || hasAlreadyPreloaded))
      || (!preloadDateField && (hasSameProject || hasAlreadyPreloaded))
    ) {
      return;
    }

    const getTaskCustomData = async (taskIds) => {
      try {
        const data = await loadManualEntryData(taskIds, true);
        return data;
      } catch (err) {
        Sentry.captureException(err);
        return {};
      }
    };

    const handleCustomDataField = ({ field, taskCustomFields }) => {
      const taskCustomFieldsRef = taskCustomFields;
      const newResponse = { ...field?.response ?? {} };
      if (field.type === 'yes-no') {
        const newValue = TaskHelpers.parseYesNoResponse(newResponse, false);
        newResponse.value = newValue;
      }
      const newField = {
        ...field,
        ...newResponse,
      };
      delete newField.response;
      taskCustomFieldsRef[field.fieldId] = newField;
    };

    const handleCustomDataFields = ({ customDataArray, taskCustomFields }) => {
      customDataArray.forEach(({ fields = [] }) => {
        fields.forEach((field) => {
          handleCustomDataField({ field, taskCustomFields });
        });
      });
    };

    const handleCustomData = ({ task, customDataMap }) => {
      const { data: customDataArray = [], divisionId } = customDataMap?.[task.id] ?? {};
      const taskCustomFields = {};

      if (customDataArray.length) {
        handleCustomDataFields({ customDataArray, taskCustomFields });
      }

      return {
        ...task,
        customData: { ...taskCustomFields },
        customDataArray,
        divisionId,
      };
    };

    const getTasksWithCustomData = ({ ourTasks, customDataMap }) => {
      const tasksWithCustomData = ourTasks.map((task) => handleCustomData({ task, customDataMap }));

      return tasksWithCustomData;
    };

    const preloadTasks = async () => {
      const projectTasks = await getProjectsTasks({
        preloadDateField,
        preloadDateModifier,
        preloadDateCount,
        linkedStart,
        linkedEnd,
        linkedProjectId,
      });
      let tasksToUse = timeEntryUserMap;
      if (projectTasks?.length) {
        // tasks key is used since getPreloadedEntries expects a map
        tasksToUse = { tasks: projectTasks };
      }

      const ourTasks = TaskHelpers.getPreloadedEntries({
        preloadDateCount,
        preloadDateModifier,
        preloadStatuses,
        preloadProject,
        preloadDateField,
        linkedProjectId,
        linkedStart,
        linkedEnd,
        timeEntryUserMap: tasksToUse,
      });

      const taskIds = ourTasks.map((task) => task.id);

      let customDataMap = {};
      let customFileMap = {};

      if (taskIds.length) {
        const {
          dataMap = {},
          fileMap: newFileMap = {},
        } = await getTaskCustomData(taskIds);
        customDataMap = dataMap;
        customFileMap = newFileMap;
      }

      const tasksWithCustomData = getTasksWithCustomData({ ourTasks, customDataMap });

      let minTime = Number.MAX_SAFE_INTEGER;
      let maxTime = Number.MIN_SAFE_INTEGER;
      const formattedTasks = tasksWithCustomData.map((task) => {
        const newEntry = TaskHelpers.formatTimeEntryTableEntry({
          entry: task,
          userId: task.userId,
          selectedEntry: task,
          classesMap: classMap,
          phaseMap,
          userMap,
          divisions: divisionMap,
          projectMap,
          costcodeMap,
          localMap,
          tabletMap,
          sageShifts,
          isPreload: true,
          generatedId: generateId(),
          getAllTimesText,
        });

        newEntry.time = getAllTimesText(
          newEntry,
          {
            returnComponent: false,
            joinText: '\n',
            showRegularTypeText: false,
            addTypeAsSuffix: true,
          },
        );

        const { startTime, endTime } = newEntry ?? {};
        if (startTime && startTime < minTime) minTime = startTime;
        if (endTime && endTime > maxTime) maxTime = endTime;

        return newEntry;
      });

      const newOrEditedEntries = selected.filter((task) => task.status === 'new');

      const startDt = DateTime.fromMillis(minTime).startOf('day');
      const endDt = DateTime.fromMillis(maxTime).endOf('day');

      setResponses((prev) => ({
        ...(prev ?? {}),
        [id]: {
          ...(prev?.[id] ?? {}),
          values: newOrEditedEntries.concat(
            sortEntries({
              startDt,
              endDt,
              payrollTasks: formattedTasks,
              userMap,
            }),
          ),
          columns,
          timezone: DateTime.local().zoneName,
          createTimeEntry,
        },
      }));
      setFileMap(customFileMap);
      setLastProjectId(linkedProjectId);
      setLastDateValue(linkedDateValue);
      setHasPreloaded(true);
    };

    preloadTasks();
  }, [
    configProps,
    id,
    setResponses,
    columns,
    linkedProjectId,
    timeEntryUserMap,
    phaseMap,
    userMap,
    divisionMap,
    projectMap,
    costcodeMap,
    localMap,
    classMap,
    sageShifts,
    tabletMap,
    lastProjectId,
    hasPreloaded,
    selected,
    createTimeEntry,
    linkedDateValue,
  ]);

  useEffect(() => {
    dispatch(getTablets());
  }, []);

  return (
    <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
      {!isDisplay && !preventEdits && (
        <Row style={{ marginBottom: 10, width: '100%' }} gutter={20}>
          <Col style={{ minWidth: 250 }}>
            {!hideAddFromTeam && !lockEntryToAuthor
            && (
            <OnTraccrButton
              title="Add from Team"
              icon={<PlusOutlined />}
              onClick={onAddFromTeamClicked}
            />
            )}
          </Col>
          {!lockEntryToAuthor
          && (
          <Col span={8} style={{ position: 'relative', top: -50 }}>
            <FilteredUserSelection
              users={userOpts}
              mode="single"
              onChange={onAddFromUserClicked}
              value={[]}
              placeholder="Add User Row"
            />
          </Col>
          )}
          <Col span={8} style={{ position: 'relative', top: !lockEntryToAuthor ? 0 : -42 }}>
            { !hideAddNewButton && (
              <OnTraccrButton
                title="Add New"
                icon={<PlusOutlined />}
                onClick={onAddNewClicked}
              />
            )}
          </Col>
        </Row>
      )}
      { !showCondensedView || dataSource?.length ? (
        <Table
          className={shouldTransposeData ? 'transposed-table' : ''}
          style={{
            width: '100%',
            position: 'relative',
            top: isDisplay ? 0 : -50,
            paddingBottom: 50,
            overflow: 'auto',
          }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={tableRows}
        />
      ) : (
        <DisplayText title="No Time Entries Selected" style={{ marginBottom: 0 }} />
      )}
      {!isDisplay && (
        <>
          {!hideAddFromTeam && !lockEntryToAuthor
          && (
          <TimeEntryTableTeamSelection
            visible={showTeamSelect}
            onClose={closeTeamSelect}
            onAdd={onAddFromTeam}
            divisions={divisions}
          />
          )}
          <ManualEntryFormDrawer
            visible={showDrawer}
            onClose={closeDrawer}
            onSubmit={onSubmitEntry}
            selectedEntry={selectedEntry}
            defaultClassId={defaultClassId}
            defaultProjectId={defaultProjectId}
            userId={userId}
            lockedDivisionId={divisions ? divisions[0] : null}
            user={selectedUser}
            hideUserSelector
            fileMap={fileMap}
            allowInlineTimes={paidFlags?.includes(TIME_TYPE_FLAG)}
          />
        </>
      )}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeEntryTablePreview.propTypes = {
  columns: PropTypes.array,
  previewProps: PropTypes.object,
  id: PropTypes.string,
  setPreviewProps: PropTypes.func,
  isDisplay: PropTypes.bool,
  setResponses: PropTypes.func,
  responses: PropTypes.object,
  responding: PropTypes.bool,
  projectId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  configProps: PropTypes.shape({
    hideAddNewButton: PropTypes.bool,
    requiredColumns: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
  t: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  createTimeTableIds: PropTypes.arrayOf(PropTypes.string),
  bucketTemplateMap: PropTypes.shape({}),
  boardDetailsMap: PropTypes.shape({}),
  subContractMap: PropTypes.shape({}),
  contactAddressBooks: PropTypes.shape({}),
  projectEquipment: PropTypes.shape({}),
  projectIdMap: PropTypes.shape({}),
  costcodeIdMap: PropTypes.shape({}),
  phaseIdMap: PropTypes.shape({}),
  subContractCoMap: PropTypes.shape({}),
  scheduleOfValues: PropTypes.shape({}),
  customerToLabel: PropTypes.shape({}),
  userToLabel: PropTypes.shape({}),
  vendorToLabel: PropTypes.shape({}),
  setCustomerIds: PropTypes.func,
  setProjectIds: PropTypes.func,
  setVendorIds: PropTypes.func,
  setContactIds: PropTypes.func,
  setCostcodeIds: PropTypes.func,
  setUserIds: PropTypes.func,
  setBucketIds: PropTypes.func,
  setSubContractIds: PropTypes.func,
  customerId: PropTypes.string,
  vendorId: PropTypes.string,
  selectedBucket: PropTypes.shape({}),
  selectedBucketTypeToIdMap: PropTypes.shape({}),
  selectedBucketTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

TimeEntryTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  id: null,
  setPreviewProps: null,
  isDisplay: false,
  setResponses: null,
  responses: {},
  responding: false,
  projectId: null,
  divisions: [],
  configProps: {},
  showCondensedView: false,
  createTimeTableIds: [],
  bucketTemplateMap: {},
  boardDetailsMap: {},
  subContractMap: {},
  contactAddressBooks: {},
  projectEquipment: {},
  projectIdMap: {},
  costcodeIdMap: {},
  phaseIdMap: {},
  subContractCoMap: {},
  scheduleOfValues: {},
  customerToLabel: {},
  userToLabel: {},
  vendorToLabel: {},
  setCustomerIds: null,
  setProjectIds: null,
  setVendorIds: null,
  setContactIds: null,
  setCostcodeIds: null,
  setUserIds: null,
  setBucketIds: null,
  setSubContractIds: null,
  customerId: null,
  vendorId: null,
  selectedBucket: {},
  selectedBucketTypeToIdMap: {},
  selectedBucketTypes: [],
};
