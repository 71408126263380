import { DateTime } from 'luxon';
import moment from 'moment';

import calculateOT, { isGoodTask } from '../../../helpers/overtime/calculateOT';
import { msToHours } from '../../../helpers/time';

import {
  getDivision,
  getDateKey,
} from '../../reportHelpers';

import {
  DATE_FORMAT,
} from './staffTimesheets.constants';

export default {};

const isMoment = (d) => d && moment.isMoment(d);

export const getDateArray = (dateRange) => {
  if (dateRange.length < 2 || !isMoment(dateRange[0]) || !isMoment(dateRange[1])) return [];
  const startDate = dateRange[0].clone();
  const endDate = dateRange[1];
  const dArr = [];
  while (startDate.isBefore(endDate)) {
    dArr.push(startDate.format(DATE_FORMAT));
    startDate.add(1, 'd');
  }
  return dArr;
};

export const computeData = ({
  onDataChanged,
  onColumnsChanged,
  onFiltersChanged,
  userDivisions,
  isRounded,
  settings,
  timeEntryUserMap = {},
  dateRange,
  users = [],
  projects,
  costcodes,
  columns,
  zeroHoursEnabled,
  isValidTask,
  getAggregateKey,
  divisionMap,
  projectIdMap,
  costcodeIdMap,
  dates,
}) => {
  const data = [];
  const dateAggregate = {};

  const [startMoment, endMoment] = dateRange;
  const range = moment.isMoment(startMoment) && moment.isMoment(endMoment)
    ? [
      DateTime.fromMillis(startMoment.valueOf()).minus({ days: 15 }),
      DateTime.fromMillis(endMoment.valueOf()).plus({ days: 15 }),
    ]
    : [];

  // zero hours
  if (zeroHoursEnabled) {
    const usersWithNoHours = [];

    users.forEach((user) => {
      const tasks = timeEntryUserMap[user?.id] ?? [];

      const validTasks = tasks.filter((task) => (
        isGoodTask(task)
          && isValidTask(task)
      ));

      // if user has any valid tasks, then they have time logged
      if (!validTasks.length) {
        usersWithNoHours.push(user);
      }
    });

    const aggKey = getAggregateKey('None', 'None', null);

    const usersWithNoHoursData = usersWithNoHours.map((user) => ({
      name: user.name,
      employeeId: user.employeeId || '',
      division: getDivision({
        project: {},
        divisionMap,
        userDivisions,
        user,
      }),
      key: user.id + aggKey,
      totalHours: 0,
    }));

    onColumnsChanged(columns);
    onDataChanged(usersWithNoHoursData);
    onFiltersChanged({
      users,
      projects,
      costcodes,
    });
    return usersWithNoHoursData;
  }

  // non zero-hours
  users.forEach((user) => {
    const tasks = timeEntryUserMap[user?.id] ?? [];
    calculateOT({
      tasks,
      settings: {
        ...settings,
        roundingInterval: isRounded ? settings.roundingInterval : null,
      },
      range,
    }).forEach((task) => {
      if (!isValidTask(task)) return;

      const project = projectIdMap[task.projectId];
      const costcode = costcodeIdMap[task.costcodeId];
      const date = getDateKey(task, DATE_FORMAT);
      const runTime = task.endTime - task.startTime;
      const totalHours = msToHours(runTime);
      const regularHours = Math.min(8.0, totalHours);
      const otHours = (totalHours - regularHours);

      const dataObject = {
        name: user.name,
        employeeId: user.employeeId || '',
        division: getDivision({
          project, divisionMap, userDivisions, user,
        }),
        project: project ? project.name : null,
        costcode: costcode ? costcode.code : null,
        regularHours,
        otHours,
        totalHours,
        wage: user.wage,
      };

      if (!(user.id in dateAggregate)) {
        dateAggregate[user.id] = {
          ...dataObject,
          values: {},
        };
      }
      const userObj = dateAggregate[user.id];
      const costCode = costcode ? costcode.code : 'None';
      const projectName = project ? project.name : 'None';
      const aggKey = getAggregateKey(projectName, costCode, task.serviceLocation);

      if (!(aggKey in userObj.values)) {
        userObj.values[aggKey] = {
          employeeId: user.employeeId || '',
          regularHours: 0,
          otHours: 0,
          totalHours: 0,
          regOT: 0,
          doubleOT: 0,
          costcode: costcode ? costcode.code : null,
          project: project ? project.name : null,
          [date]: 0,
          key: user.id + aggKey,
          serviceLocation: task.serviceLocation,
          division: getDivision({
            project, divisionMap, userDivisions, user,
          }),
        };
      }

      const aggValue = userObj.values[aggKey];

      const hrReg = msToHours(task.regularTime);
      const hourOT = msToHours(task.overtime);
      const hourDouble = msToHours(task.doubleOT);
      const satHrReg = msToHours(task.saturdayTime || 0);
      const satHourOT = msToHours(task.saturdayOT || 0);
      const satHourDouble = msToHours(task.saturdayDoubleOT || 0);
      const sunHrReg = msToHours(task.sundayTime || 0);
      const sunHourOT = msToHours(task.sundayOT || 0);
      const sunHourDouble = msToHours(task.sundayDoubleOT || 0);

      const aggTotalHours = (
        hrReg + hourOT + hourDouble
        + satHrReg + sunHrReg + satHourOT + satHourDouble
        + sunHourOT + sunHourDouble
      );

      const aggReg = (
        hrReg + satHrReg + sunHrReg
      );

      const aggOTReg = (
        hourOT + satHourOT + sunHourOT
      );

      const aggOTDouble = (
        hourDouble + satHourDouble + sunHourDouble
      );

      aggValue.regularHours += aggReg;
      aggValue.otHours += aggOTReg + aggOTDouble;
      aggValue.totalHours += aggTotalHours;
      aggValue.regOT += aggOTReg;
      aggValue.doubleOT += aggOTDouble;

      if (!(date in aggValue)) {
        aggValue[date] = 0;
      }
      aggValue[date] += totalHours;
    });
  });

  // TODO: REFACTOR
  Object.values(dateAggregate).forEach((obj) => {
    Object.values(obj.values).forEach((hours) => {
      const dataObject = {
        name: obj.name,
        employeeId: obj.employeeId,
        division: obj.division,
        project: hours.project,
        costcode: hours.costcode,
        regularHours: hours.regularHours.toFixed(2),
        otHours: hours.otHours.toFixed(2),
        totalHours: hours.totalHours.toFixed(2),
        key: hours.key,
        serviceLocation: hours.serviceLocation,

        // These four fields are not displayed in the report but used for the export
        regHoursRaw: hours.regularHours,
        regularOTRaw: hours.regOT,
        doubleOTRaw: hours.doubleOT,
        wage: `$${obj.wage}`,
      };

      dates.forEach((dateS) => {
        if (dateS in hours) {
          dataObject[dateS] = hours[dateS].toFixed(2);
        }
      });
      data.push(dataObject);
    });
  });

  onColumnsChanged(columns);
  onDataChanged(data);
  onFiltersChanged({
    users,
    projects,
    costcodes,
  });
  return data;
};
