import { DateTime } from 'luxon';

import {
  CREATE_FORM_TEMPLATE,
  UPDATE_FORM_TEMPLATE,
  GET_FORM_TEMPLATES,
  ARCHIVE_FORM_TEMPLATE,
  GET_FORM_TEMPLATE_DETAILS,
  DELETE_FORM_TEMPLATE,
  GET_FORMS,
  GET_FORM_BY_ID,
  GET_FORM_APPROVALS,
  APPROVE_FORM,
  REJECT_FORM,
  GET_ASSIGNED_FORMS,
  GET_ASSIGNED_FORM_TEMPLATES,
  SUBMIT_FORM,
  GET_FORM_STATUSES,
  GET_FORM_TYPES,
  CREATE_CUSTOM_FORM_TABLE,
  GET_CUSTOM_FORM_TABLES,
  UPDATE_CUSTOM_FORM_TABLE,
  DELETE_CUSTOM_FORM_TABLE,
  SAVE_FORM_DRAFT,
  GET_FORM_DRAFTS,
  DELETE_FORM_DRAFT,
  MARK_INVOICE_AS_PAID,
  RESUBMIT_EMAIL,
  CANCEL_FORM,
  CLEAR_SELECTED_FORM,
  COPY_FORM_TEMPLATE,
  FAVORITE_FORM_TEMPLATE,
  GET_FORM_CHILDREN,
  REASSIGN_FORM,
  REASSIGN_DRAFT,
  CREATE_CUSTOM_TYPE,
  DELETE_CUSTOM_TYPE,
  GET_EXTERNAL_FORM_TEMPLATES,
  GET_EXTERNAL_FORM_TEMPLATE_BY_ID,
  GET_USER_FORM_FILTER_VIEWS,
  CREATE_USER_FORM_FILTER_VIEW,
  UPDATE_USER_FORM_FILTER_VIEW,
  DELETE_USER_FORM_FILTER_VIEW,
  UPDATE_FORM_FILTERS,
  GET_LIGHTWEIGHT_FORMS,
  GET_CHILD_FORMS,
  GET_FORM_SNAPSHOTS,
  GET_SUB_CONTRACT_DETAILS,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import FormsService from './forms.service';
import { counters, counterWasRecentlyCalled } from '../../helpers/counters';

const {
  forms: formCounters,
} = counters;

export const createTemplate = (payload) => async (dispatch) => {
  Analytics.track('Forms/CreateTemplate');
  const { data } = await FormsService.createTemplate(payload);
  if (!data) return false;
  const {
    preview,
    formTemplate: { id, newStatuses } = {},
  } = data;
  dispatch({
    type: CREATE_FORM_TEMPLATE,
    payload: {
      formTemplate: { ...payload, id },
      preview,
      newStatuses,
    },
  });
  return true;
};

export const editTemplate = (id, payload) => async (dispatch) => {
  Analytics.track('Forms/EditTemplate');
  const { data } = await FormsService.editTemplate(id, payload);
  if (!data) return false;
  const {
    formTemplate: { newStatuses } = {},
    preview,
  } = data;
  dispatch({
    type: UPDATE_FORM_TEMPLATE,
    payload: {
      formTemplate: { id, ...payload },
      preview,
      newStatuses,
    },
  });
  return true;
};

export const getTemplates = (query) => async (dispatch) => {
  const { data } = await FormsService.getTemplates(query);
  if (!data) return false;
  dispatch({
    type: GET_FORM_TEMPLATES,
    payload: data,
  });
  return true;
};

export const archiveTemplate = (id, active = false) => async (dispatch) => {
  Analytics.track('Forms/ArchiveTemplate', { active });
  const didArchive = await FormsService.archive(id, active);
  if (!didArchive) return false;
  dispatch({
    type: ARCHIVE_FORM_TEMPLATE,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const deleteTemplate = (id) => async (dispatch) => {
  Analytics.track('FormsDeleteTemplate');
  const { data } = await FormsService.deleteTemplate(id);
  if (!data) return false;
  dispatch({
    type: DELETE_FORM_TEMPLATE,
    payload: {
      id,
    },
  });
  return true;
};

export const getTemplateDetails = (id, draftId) => async (dispatch) => {
  Analytics.track('Forms/GetDetails');
  const { data } = await FormsService.getTemplateDetails(id, draftId);
  if (!data) return false;
  dispatch({
    type: GET_FORM_TEMPLATE_DETAILS,
    payload: {
      formTemplate: data,
    },
  });
  return true;
};

export const getForms = (query) => async (dispatch) => {
  const { data } = await FormsService.getForms(query);
  if (!data) return false;
  dispatch({
    type: GET_FORMS,
    payload: {
      forms: data,
    },
  });
  return true;
};

export const getLightWeightForms = (query) => async (dispatch) => {
  const { data } = await FormsService.getLightWeightForms(query);
  if (!data) return false;
  dispatch({
    type: GET_LIGHTWEIGHT_FORMS,
    payload: {
      forms: data,
      templateId: query?.templateId,
      subContractTemplateId: query?.subContractTemplateId,
    },
  });
  return true;
};

export const getFormById = (id, query) => async (dispatch) => {
  const { data } = await FormsService.getFormById(id, query);
  if (!data) return false;
  dispatch({
    type: GET_FORM_BY_ID,
    payload: {
      form: data,
    },
  });
  return true;
};

export const getFormApprovals = () => async (dispatch) => {
  const { data } = await FormsService.getFormApprovals();
  if (!data) return false;
  dispatch({
    type: GET_FORM_APPROVALS,
    payload: {
      approvals: data,
    },
  });
  return true;
};

export const approveRejectForm = (formId, payload) => async (dispatch) => {
  const isApprove = payload.approved;
  Analytics.track(`Forms/${isApprove ? 'Approve' : 'Reject'}`);
  const { data } = await FormsService.approveRejectForm(formId, payload);
  if (!data) return false;
  const { data: form } = await FormsService.getFormById(formId);
  if (!form) return false;
  dispatch({
    type: isApprove ? APPROVE_FORM : REJECT_FORM,
    payload: {
      form,
      approvalId: payload.approvalId,
    },
  });
  return true;
};

export const getAssignedForms = () => async (dispatch) => {
  const { data: forms } = await FormsService.getAssigned();
  if (!forms) return false;
  dispatch({
    type: GET_ASSIGNED_FORMS,
    payload: {
      forms,
    },
  });
  return true;
};

export const getAssignedFormTemplates = () => async (dispatch) => {
  if (counterWasRecentlyCalled('forms.getAssignedFormTemplates')) return true;
  formCounters.getAssignedFormTemplates = DateTime.local();
  const {
    data: formTemplates,
  } = await FormsService.getAssignedTemplates();
  if (!formTemplates) {
    formCounters.getAssignedFormTemplates = null;
    return false;
  }
  dispatch({
    type: GET_ASSIGNED_FORM_TEMPLATES,
    payload: {
      formTemplates,
    },
  });
  return true;
};

export const submitForm = (payload, isExternalForm = false) => async (dispatch) => {
  Analytics.track('Forms/Submit');
  const { data: submittedForm } = await FormsService.submitForm(payload, isExternalForm);
  if (!submittedForm) return false;
  dispatch({
    type: payload.isDraft ? SAVE_FORM_DRAFT : SUBMIT_FORM,
    payload: {
      submittedForm,
      isAssignedForm: payload.id === submittedForm.id,
      data: payload,
    },
  });
  return true;
};

export const getStatuses = () => async (dispatch) => {
  const { data: statuses } = await FormsService.getFormStatuses();
  if (!statuses) return false;
  dispatch({
    type: GET_FORM_STATUSES,
    payload: {
      statuses,
    },
  });
  return true;
};

export const getFormTypes = () => async (dispatch) => {
  const { data: types } = await FormsService.getFormTypes();
  if (!types) return false;
  dispatch({
    type: GET_FORM_TYPES,
    payload: {
      types,
    },
  });
  return true;
};

export const createCustomTable = (payload) => async (dispatch) => {
  Analytics.track('Forms/CreateCustomTable');
  const { data } = await FormsService.createCustomTable(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_CUSTOM_FORM_TABLE,
    payload: {
      customTable: data,
    },
  });
  return true;
};

export const getCustomTables = () => async (dispatch) => {
  if (counterWasRecentlyCalled('forms.getCustomTables')) return true;
  formCounters.getCustomTables = DateTime.local();
  const { data: customTables } = await FormsService.getCustomTables();
  if (!customTables) {
    formCounters.getCustomTables = null;
    return false;
  }
  dispatch({
    type: GET_CUSTOM_FORM_TABLES,
    payload: {
      customTables,
    },
  });
  return true;
};

export const updateCustomTable = (id, payload) => async (dispatch) => {
  const { data: newData } = await FormsService.updateCustomTable(id, payload);
  if (!newData) return false;
  dispatch({
    type: UPDATE_CUSTOM_FORM_TABLE,
    payload: {
      id,
      newData,
    },
  });
  return true;
};

export const deleteCustomTable = (id) => async (dispatch) => {
  const { data } = await FormsService.deleteCustomTable(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CUSTOM_FORM_TABLE,
    payload: {
      id,
    },
  });
  return true;
};

export const getDrafts = (query) => async (dispatch) => {
  const { data: drafts } = await FormsService.getDrafts(query);
  if (!drafts) return false;
  dispatch({
    type: GET_FORM_DRAFTS,
    payload: {
      drafts,
    },
  });
  return true;
};

export const deleteDraft = ({ formId, id }) => async (dispatch) => {
  const { data: drafts } = await FormsService.deleteDraft(id);
  if (!drafts) return false;
  dispatch({
    type: DELETE_FORM_DRAFT,
    payload: {
      formId, id,
    },
  });
  return true;
};

export const markInvoiceAsPaid = (id) => async (dispatch) => {
  const { data } = await FormsService.markInvoiceAsPaid(id);
  if (!data) return false;
  dispatch({
    type: MARK_INVOICE_AS_PAID,
    payload: {
      id,
    },
  });
  return true;
};

export const resubmitEmail = ({ formId, id, stepId }) => async (dispatch) => {
  const { data } = await FormsService.resubmitEmail(formId, { id, stepId });
  if (!data) return false;
  dispatch({
    type: RESUBMIT_EMAIL,
    payload: {
      steps: data,
    },
  });
  return true;
};

export const cancelWorkflow = (id) => async (dispatch) => {
  const { data } = await FormsService.cancelWorkflow(id);
  if (!data) return false;
  dispatch({
    type: CANCEL_FORM,
    payload: {
      id,
    },
  });
  return true;
};
export const clearFormSelection = () => ({ type: CLEAR_SELECTED_FORM });

export const copyFormTemplate = (id) => async (dispatch) => {
  const { data } = await FormsService.copyFormTemplate(id);
  if (!data) return false;
  dispatch({
    type: COPY_FORM_TEMPLATE,
    payload: data,
  });
  return true;
};

export const favoriteFormTemplate = (id, isFavorite) => async (dispatch) => {
  Analytics.track('Forms/Favorite');
  const toggledFavorite = !isFavorite;
  const { data } = await FormsService.favoriteFormTemplate(id, { isFavorite: toggledFavorite });
  if (!data) return false;
  dispatch({
    type: FAVORITE_FORM_TEMPLATE,
    payload: {
      id,
      favorite: toggledFavorite,
    },
  });
  return true;
};

export const getSharedFormDraftChildren = (id) => async (dispatch) => {
  const { data: children } = await FormsService.getSharedFormDraftChildren(id);
  if (!children) return false;
  dispatch({
    type: GET_FORM_CHILDREN,
    payload: {
      children,
    },
  });
  return true;
};

export const reassignForm = (id, payload) => async (dispatch) => {
  const { data } = await FormsService.reassignForm(id, payload);
  if (!data) return false;
  dispatch({
    type: REASSIGN_FORM,
    payload: data,
  });
  return true;
};

export const reassignDraft = (id, payload) => async (dispatch) => {
  const { data } = await FormsService.reassignDraft(id, payload);
  if (!data) return false;
  dispatch({
    type: REASSIGN_DRAFT,
    payload: data,
  });
  return true;
};

export const createCustomType = (payload) => async (dispatch) => {
  const { data } = await FormsService.createCustomType(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_CUSTOM_TYPE,
    payload: {
      name: data,
    },
  });
  return true;
};

export const deleteCustomType = (id) => async (dispatch) => {
  const { data } = await FormsService.deleteCustomType(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CUSTOM_TYPE,
    payload: {
      id,
    },
  });
  return true;
};

export const updateCustomType = (id, payload) => async (dispatch) => {
  const { data } = await FormsService.updateCustomType(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_CUSTOM_TYPE,
    payload: {
      id,
      name: payload.name,
    },
  });
  return true;
};

export const getExternalFormTemplates = () => async (dispatch) => {
  const { data } = await FormsService.getExternalFormTemplates();
  if (!data) return false;
  dispatch({
    type: GET_EXTERNAL_FORM_TEMPLATES,
    payload: {
      externalFormTemplates: data,
    },
  });
  return true;
};

export const getExternalFormTemplateById = (id) => async (dispatch) => {
  const { data } = await FormsService.getExternalFormTemplateById(id);
  if (!data) return false;
  dispatch({
    type: GET_EXTERNAL_FORM_TEMPLATE_BY_ID,
    payload: {
      formTemplate: data,
    },
  });
  return true;
};

export const importForm = (payload) => async () => {
  const { data } = await FormsService.importForm(payload);
  return !!data;
};

export const massApproveRejectForms = (payload) => async (dispatch) => {
  const { data } = await FormsService.massApproveRejectForms(payload);
  if (!data) return false;
  dispatch(getFormApprovals());
  return true;
};

export const getUserFormFilterViews = () => async (dispatch) => {
  const { data } = await FormsService.getUserFormFilterViews();
  if (!data) return false;
  dispatch({
    type: GET_USER_FORM_FILTER_VIEWS,
    payload: {
      formFilterViews: data,
    },
  });
  return true;
};

export const createUserFormFilterView = (payload) => async (dispatch) => {
  const {
    data: {
      newFilterView,
      filterViews,
    } = {},
  } = await FormsService.createUserFormFilterView(payload);
  if (!newFilterView) return false;
  dispatch({
    type: CREATE_USER_FORM_FILTER_VIEW,
    payload: {
      formFilterViews: filterViews,
    },
  });
  return newFilterView;
};

export const updateUserFormFilterView = (filterViewId, payload) => async (dispatch) => {
  const { data } = await FormsService.updateUserFormFilterView(filterViewId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_USER_FORM_FILTER_VIEW,
    payload: {
      formFilterViews: data,
    },
  });
  return true;
};

export const deleteUserFormFilterView = (filterViewId) => async (dispatch) => {
  const { data } = await FormsService.deleteUserFormFilterView(filterViewId);
  if (!data) return false;
  dispatch({
    type: DELETE_USER_FORM_FILTER_VIEW,
    payload: {
      formFilterViews: data,
    },
  });
  return true;
};

export const updateFormFilters = (filters) => ({
  type: UPDATE_FORM_FILTERS,
  payload: {
    formFilters: filters,
  },
});

export const getChildForms = (templateId) => async (dispatch) => {
  const { data } = await FormsService.getChildForms(templateId);
  if (!data) return false;
  dispatch({
    type: GET_CHILD_FORMS,
    payload: {
      childForms: data,
    },
  });
  return true;
};

export const getFormSnapshots = (formId) => async (dispatch) => {
  const { data } = await FormsService.getFormSnapshots(formId);
  if (!data) return false;
  dispatch({
    type: GET_FORM_SNAPSHOTS,
    payload: {
      formId,
      snapshots: data,
    },
  });
  return true;
};

export const getSubContractDetails = (subContractIds) => async (dispatch) => {
  const counterKey = subContractIds?.join(',');
  if (counterWasRecentlyCalled(`forms.getSubContractDetails.${counterKey}`)) return true;
  formCounters.getSubContractDetails[counterKey] = DateTime.local();
  const { data } = await FormsService.getSubContractDetails(subContractIds);
  if (!data) {
    formCounters.getSubContractDetails[counterKey] = null;
    return false;
  }
  dispatch({
    type: GET_SUB_CONTRACT_DETAILS,
    payload: {
      details: data,
    },
  });
  return true;
};
