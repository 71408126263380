import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import HoverHelp from '../../../common/HoverHelp';

import ReportSkeleton from '../ReportSkeleton';
import { ReportColumn } from '../ReportColumnHeader';
import getEmployeeProjectFilters from '../EmployeeProjectFilters';

import {
  filterOnCols,
  getUnique,
  isValidTaskHelper,
} from '../../reportHelpers';

import config from '../../../config';
import { getIdMap, getNames } from '../../../helpers/helpers';

import {
  computeData,
  getPhaseMap,
} from './employeePayroll.helpers';

import Debouncer from '../../../helpers/Debouncer';

const debouncer = new Debouncer();
const SUMMARY_EXCLUDE_DATA = ['project', 'phase', 'costcode', 'serviceLocation', 'date'];

const TYPE_MAP = {
  Project: ['work', 'overtime'],
  Service: ['service'],
  Break: ['break'],
};

const DEFAULT_ENABLED_FILTERS = {
  project: true,
  phase: true,
  costcode: true,
  serviceLocation: true,
  date: true,
  approved: true,
  unapproved: true,
  notes: true,
};

const DEFAULT_WORK_TYPE = new Set(['work', 'service', 'overtime', 'break']);

const getAllColumns = () => {
  let allColumns = [
    ReportColumn('Employee', {
      fixed: 'left',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare?.(b?.name),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
    ReportColumn('Employee ID', {
      key: 'employeeId',
      dataIndex: 'employeeId',
      sorter: (a, b) => a?.employeeId?.localeCompare?.(b?.employeeId),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    })
  ];
  if (config.showDivisions) {
    allColumns.push(ReportColumn('Division', {
      sorter: (a, b) => a?.division?.localeCompare?.(b?.division),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }));
  }
  allColumns = allColumns.concat([
    ReportColumn('Date', {
      sorter: (a, b) => {
        if (!a?.date && !b?.date) return 1;
        if (!a?.date) return -1;
        if (!b?.date) return 1;

        const dateA = moment(a.date, 'MMM Do YYYY').unix();
        const dateB = moment(b.date, 'MMM Do YYYY').unix();
        return dateA - dateB;
      },
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
    ReportColumn('Calculated Wage', { dataIndex: 'wage', key: 'wage' }),
    ReportColumn('Project', {
      sorter: (a, b) => a?.project?.localeCompare?.(b?.project),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }),
    ReportColumn('Phase'),
    ReportColumn('Cost Code', {
      key: 'costcode',
      dataIndex: 'costcode',
    }),
    ReportColumn('Service Location', {
      key: 'serviceLocation',
      dataIndex: 'serviceLocation',
    }),
    ReportColumn('Reg Hrs', {
      key: 'regularHours',
      dataIndex: 'regularHours',
    }),
    ReportColumn('Break Hrs', {
      key: 'breakHours',
      dataIndex: 'breakHours',
    }),
    ReportColumn('OT Hrs', {
      key: 'otHours',
      dataIndex: 'otHours',
    }),
    ReportColumn('Double OT Hrs', {
      key: 'doubleOT',
      dataIndex: 'doubleOT',
    }),
    ReportColumn('Sat Reg Hrs', {
      key: 'saturday',
      dataIndex: 'saturday',
    }),
    ReportColumn('Sat OT Hrs', {
      key: 'satOTHours',
      dataIndex: 'satOTHours',
    }),
    ReportColumn('Sun Reg Hrs', {
      key: 'sunday',
      dataIndex: 'sunday',
    }),
    ReportColumn('Sun OT Hrs', {
      key: 'sunOTHours',
      dataIndex: 'sunOTHours',
    }),
    ReportColumn('Total Hrs', {
      key: 'totalHours',
      dataIndex: 'totalHours',
    }),
    ReportColumn('Total Cost', {
      key: 'totalCost',
      dataIndex: 'totalCost',
    }),
    ReportColumn('Approved'),
    ReportColumn('Unapproved'),
    ReportColumn('Notes', { width: 500 }),
  ]);

  return allColumns;
};

function EmployeePayroll({
  renderColFilters,
  renderRowFilters,
  columns,
  users,
  projects,
  costcodes,
  phases,
  onColumnsChanged,
  zeroHoursEnabled: propZeroHoursEnabled,
  onZeroHoursChanged,
  onSummaryChanged,
  height,
  onDataChanged,
  onFiltersChanged,
  settings,
  isRounded,
  userDivisions,
  timeEntryUserMap,
  dateRange,
  classMap,
  divisions,
  onRef,
}) {
  const { t } = useTranslation();
  const allColumns = useMemo(() => getAllColumns(), []);

  const [checkedColumns, setCheckedColumns] = useState(
    new Set(allColumns.map((col) => col.key).filter(filterOnCols(columns))),
  );
  const [stateColumns, setStateColumns] = useState(
    allColumns.filter((col) => checkedColumns.has(col.key)),
  );
  const [filters, setFilters] = useState({
    users,
    projects: new Set(getNames(projects).concat('None')),
    costcodes: new Set(costcodes.map((cc) => cc.code).concat('None')),
    phases: new Set(getUnique(phases, 'name').concat('None')),
  });
  const [enabledFilters, setEnabledFilters] = useState(DEFAULT_ENABLED_FILTERS);
  const [summaryEnabled, setSummaryEnabled] = useState(!!stateColumns?.includes('summaryEnabled'));
  const [workType, setWorkType] = useState(DEFAULT_WORK_TYPE);
  const [zeroHoursEnabled, setZeroHoursEnabled] = useState(propZeroHoursEnabled);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseMap = useMemo(() => getPhaseMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const divisionMap = useMemo(() => getIdMap(divisions), [divisions]);

  const onZeroHoursToggle = useCallback(() => {
    setZeroHoursEnabled(!zeroHoursEnabled);
    onZeroHoursChanged(!zeroHoursEnabled);
  }, [zeroHoursEnabled]);

  const toggleFilter = useCallback((item, checked) => {
    const {
      key: itemKey,
    } = item;

    const newChecked = new Set(checkedColumns);

    if (checked) {
      newChecked.add(itemKey);
    } else {
      newChecked.delete(itemKey);
    }
    const newCols = allColumns.filter((col) => newChecked.has(col.key));
    setStateColumns(newCols);
    setCheckedColumns(newChecked);
  }, [
    checkedColumns,
    allColumns,
  ]);

  const updateRowFilters = useCallback((key, checked) => {
    setEnabledFilters({
      ...enabledFilters,
      [key]: checked,
    });
    if (SUMMARY_EXCLUDE_DATA.includes(key) && checked) {
      setSummaryEnabled(false);
    }
    toggleFilter({ key }, checked);
  }, [enabledFilters, toggleFilter]);

  const onSummaryToggle = useCallback(() => {
    SUMMARY_EXCLUDE_DATA.forEach((key) => updateRowFilters(key, summaryEnabled));
    setSummaryEnabled(!summaryEnabled);
    onSummaryChanged(!summaryEnabled);
  }, [updateRowFilters, summaryEnabled, onSummaryChanged]);

  const isValidTask = useCallback(isValidTaskHelper({
    dateRange,
    filters,
    projectIdMap,
    costcodeIdMap,
    phaseMap,
    checkPhase: true,
  }), [
    dateRange,
    filters,
    projectIdMap,
    costcodeIdMap,
    phaseMap,
  ]);

  const getAggregateKey = useCallback((
    projectName,
    phaseName,
    costcodeName,
    serviceLocationText,
    taskId,
  ) => {
    const {
      date,
      costcode,
      phase,
      project,
      serviceLocation,
    } = enabledFilters;
    if (summaryEnabled) return 'All';
    const keys = [projectName, phaseName, costcodeName, serviceLocationText, taskId];
    const taskFilters = [date, serviceLocation, costcode, phase, project];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const index in taskFilters) {
      const filter = taskFilters[index];
      if (filter) return keys.join('-');
      keys.pop();
    }
    return 'All';
  }, [
    summaryEnabled,
    enabledFilters,
    filters,
  ]);

  const colFilters = useMemo(() => {
    const cols = config.showDivisions
      ? [{
        title: 'Division',
        key: 'division',
        checked: checkedColumns.has('division'),
      },
      ]
      : [];
    return cols.concat([{
      title: 'Calculated Wage',
      key: 'wage',
      checked: checkedColumns.has('wage'),
    }, {
      title: 'Hours (Regular)',
      key: 'regularHours',
      checked: checkedColumns.has('regularHours'),
    }, {
      title: 'Hours (Break)',
      key: 'breakHours',
      checked: checkedColumns.has('breakHours'),
    }, {
      title: 'Hours (OT)',
      key: 'otHours',
      checked: checkedColumns.has('otHours'),
    }, {
      title: 'Hours (DT)',
      key: 'doubleOT',
      checked: checkedColumns.has('doubleOT'),
    }, {
      title: 'Sat Reg Hrs',
      key: 'saturday',
      checked: checkedColumns.has('saturday'),
    }, {
      title: 'Sat OT Hrs',
      key: 'satOTHours',
      checked: checkedColumns.has('satOTHours'),
    }, {
      title: 'Sun Reg Hrs',
      key: 'sunday',
      checked: checkedColumns.has('sunday'),
    }, {
      title: 'Sun OT Hrs',
      key: 'sunOTHours',
      checked: checkedColumns.has('sunOTHours'),
    }, {
      title: 'Total Hours',
      key: 'totalHours',
      checked: checkedColumns.has('totalHours'),
    }, {
      title: 'Total Cost',
      key: 'totalCost',
      checked: checkedColumns.has('totalCost'),
    }, {
      title: 'Notes',
      key: 'notes',
      checked: checkedColumns.has('notes'),
    }]);
  }, [checkedColumns]);

  const rowFilters = useMemo(() => {
    const { projects: selectedProjects } = filters;
    const relevantCostcodes = costcodes.filter((costcode) => {
      if (selectedProjects.size === 0) return !costcode.projectId;
      const project = projectIdMap[costcode.projectId];
      return project && selectedProjects.has(project.name);
    });

    const relevantPhases = selectedProjects.size === 0
      ? phases
      : phases.filter((phase) => {
        const project = projectIdMap[phase.projectId];
        return project && selectedProjects.has(project.name);
      });

    const rfs = getEmployeeProjectFilters({
      onFilterToggle: (type, checked) => {
        if (type === 'users') {
          setFilters({
            ...filters,
            users: users.filter((user) => checked.has(user.name)),
          });
          return;
        }
        if (type === 'workType') {
          const newWorkType = new Set();
          Array.from(checked).forEach((checkedType) => {
            TYPE_MAP[checkedType]?.forEach((newType) => {
              newWorkType.add(newType);
            });
          });
          setWorkType(newWorkType);
          return;
        }
        setFilters({
          ...filters,
          [type]: checked,
        });
      },
      onCheckChanged: (type, checked) => updateRowFilters(type, checked),
      users,
      projects,
      phases: relevantPhases,
      costcodes: relevantCostcodes,
      checkedColumns,
      showWorkType: true,
      showBreak: true,
      showServiceLocations: true,
      settings,
      t,
      filters,
    });

    return rfs.concat([
      {
        title: 'Date',
        key: 'date',
        onCheckChanged: (checked) => updateRowFilters('date', checked),
        checked: checkedColumns.has('date'),
      }, {
        title: 'Approved',
        key: 'approved',
        onCheckChanged: (checked) => updateRowFilters('approved', checked),
        checked: checkedColumns.has('approved'),
      }, {
        title: 'Unapproved',
        key: 'unapproved',
        onCheckChanged: (checked) => updateRowFilters('unapproved', checked),
        checked: checkedColumns.has('unapproved'),
      },
    ]);
  }, [
    checkedColumns,
    users,
    projects,
    phases,
    costcodes,
    projectIdMap,
    updateRowFilters,
  ]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const newData = await debouncer.debounce(() => (
        computeData({
          ...filters,
          onDataChanged,
          onFiltersChanged,
          settings,
          isRounded,
          userDivisions,
          timeEntryUserMap,
          enabledFilters,
          workType,
          zeroHoursEnabled,
          isValidTask,
          getAggregateKey,
          divisionMap,
          projectIdMap,
          costcodeIdMap,
          classIdMap: classMap,
          phaseMap,
          dateRange,
        })
      ), 200);
      setLoading(false);
      setData(newData);
    };
    getData();
  }, [
    onDataChanged,
    onFiltersChanged,
    settings,
    isRounded,
    userDivisions,
    timeEntryUserMap,
    enabledFilters,
    workType,
    zeroHoursEnabled,
    isValidTask,
    getAggregateKey,
    divisionMap,
    projectIdMap,
    costcodeIdMap,
    classMap,
    phaseMap,
    filters,
    dateRange,
  ]);

  useEffect(() => {
    setFilters({
      users,
      projects: new Set(getNames(projects).concat('None')),
      costcodes: new Set(costcodes.map((cc) => cc.code).concat('None')),
      phases: new Set(getUnique(phases, 'name').concat('None')),
    });
  }, [
    users,
    projects,
    phases,
    costcodes,
  ]);

  useEffect(() => {
    onRef({
      onFilterToggle: toggleFilter,
    });
  }, [onRef, toggleFilter]);

  useEffect(() => {
    onSummaryChanged(summaryEnabled);
    onZeroHoursChanged(zeroHoursEnabled);
  }, [
    onSummaryChanged,
    onZeroHoursChanged,
  ]);

  useEffect(() => {
    onColumnsChanged(stateColumns);
  }, [
    stateColumns,
  ]);

  return (
    <ReportSkeleton
      onDataChanged={onDataChanged}
      data={data}
      columns={stateColumns}
      height={height}
      loading={loading}
      colFilters={renderColFilters(colFilters)}
      rowFilters={renderRowFilters(rowFilters)}
      summaryConfig={{
        checked: summaryEnabled,
        onCheckChanged: onSummaryToggle,
      }}
      zeroHoursConfig={{
        checked: zeroHoursEnabled,
        onCheckChanged: onZeroHoursToggle,
        hoverHelp: (
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Check this box to show all the users with zero hour time cards.
              </div>
            )}
          />
        ),
      }}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
EmployeePayroll.propTypes = {
  renderColFilters: PropTypes.func.isRequired,
  renderRowFilters: PropTypes.func.isRequired,
  columns: PropTypes.array,
  users: PropTypes.array,
  projects: PropTypes.array,
  phases: PropTypes.array,
  costcodes: PropTypes.array,
  onColumnsChanged: PropTypes.func.isRequired,
  zeroHoursEnabled: PropTypes.bool,
  onZeroHoursChanged: PropTypes.func.isRequired,
  onSummaryChanged: PropTypes.func.isRequired,
  height: PropTypes.number,
  onDataChanged: PropTypes.func.isRequired,
  onFiltersChanged: PropTypes.func.isRequired,
  settings: PropTypes.object,
  isRounded: PropTypes.bool,
  userDivisions: PropTypes.object,
  timeEntryUserMap: PropTypes.object,
  dateRange: PropTypes.array.isRequired,
  classMap: PropTypes.object,
  divisions: PropTypes.array,
  onRef: PropTypes.func.isRequired,
};

EmployeePayroll.defaultProps = {
  columns: [],
  users: [],
  projects: [],
  phases: [],
  costcodes: [],
  zeroHoursEnabled: false,
  height: 450,
  settings: {},
  isRounded: false,
  userDivisions: {},
  timeEntryUserMap: {},
  classMap: {},
  divisions: [],
};

export default EmployeePayroll;
