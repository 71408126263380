import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import {
  Checkbox, Col, Divider, Row, Spin, Table, message,
} from 'antd';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { isNullOrUndefined } from 'ontraccr-common/lib/Common';
import { InfoCircleTwoTone } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import TimeCardDatePicker from '../../timecards/TimeCardDatePicker';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { CUSTOM_EXPORT_PROP_TYPE } from '../../settings/Exports/exports.constants';
import ReportExcelExport from '../reportExcelExport';
import Debouncer from '../../helpers/Debouncer';
import { request } from '../../helpers/requests';

import {
  constructAfterExportPayload,
  getBatchNumber,
  getDataKey,
  updateFormNumbers,
} from './exports.helpers';
import HoverHelp from '../../common/HoverHelp';
import { LINE_NUMBER_COLUMN, PATH_MAP } from './exports.constants';

const exporter = new ReportExcelExport();
const debouncer = new Debouncer();

function CustomExportsTable({
  selectedExport = {},
  scroll,
}) {
  const [startDate, endDate] = useSelector((state) => state.timecards.timeRange);

  const [hideExported, setHideExported] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const {
    id: exportId,
    title: exportTitle,
    columns: exportColumns = [],
    addLineNumbers = false,
    generateBatchNumber = false,
    generateFormNumber = false,
    summarizeDays = false,
    summarizeProject = false,
    approvedOnly = false,
    divisionId = null,
    formTemplateId = null,
    type,
  } = selectedExport || {};

  // Allows only detecting field changes to avoid re-rendering the table when renaming columns
  const fieldIds = useMemo(() => (
    exportColumns
      .map(({ field }) => field)
      .sort()
      .join(', ')
  ), [exportColumns]);

  const specialColumns = useMemo(() => {
    if (!exportId) return [];
    const innerSpecialColumns = [];
    if (addLineNumbers) innerSpecialColumns.push(LINE_NUMBER_COLUMN);
    innerSpecialColumns.push({
      title: '',
      render: (_1, record) => {
        const { exportIds = [], batchNumber } = record ?? {};
        if (!exportIds?.includes(exportId)) return null;
        const suffix = batchNumber ? ` in batch ${batchNumber}` : '';
        const recordType = type === 'forms' ? 'form' : 'time card';
        return (
          <HoverHelp
            content={`This ${recordType} has already been exported${suffix}`}
            Icon={InfoCircleTwoTone}
            iconProps={{ twoToneColor: '#fdb81b' }}
          />
        );
      },
      width: 50,
    });

    return innerSpecialColumns;
  }, [addLineNumbers, exportId, type]);

  const columns = useMemo(() => {
    if (!exportId) return [];

    const parsedColumns = [...specialColumns];

    exportColumns.forEach((col) => {
      parsedColumns.push({
        title: col.title,
        dataIndex: getDataKey(col),
      });
    });

    return parsedColumns;
  }, [specialColumns, exportColumns, exportId]);

  useEffect(() => {
    const fetchExportData = async () => {
      setLoading(true);
      const path = PATH_MAP[type];
      const params = {
        params: {
          startTime: startDate.toMillis(),
          endTime: endDate.toMillis(),
          exportId,
          divisionId,
          columns: exportColumns,
        },
      };

      if (type === 'forms') {
        params.params.formTemplateId = formTemplateId;
      }

      if (type === 'timeEntries') {
        params.params.approvedOnly = approvedOnly;
        params.params.summarizeDays = summarizeDays;
        params.params.summarizeProject = summarizeProject;
      }

      try {
        const { data: exportData } = await axios.get(path, params);
        setRows(exportData ?? []);
      } catch (err) {
        message.error('Failed to load export data');
        Sentry.withScope(() => {
          Sentry.captureException(err);
        });
        setRows([]);
      } finally {
        setLoading(false);
      }
    };

    debouncer.debounce(fetchExportData, 500);

    return () => {
      debouncer.clear();
    };
  }, [
    // Avoid re-fetching data when the column title changes by only including fieldIds
    fieldIds,
    exportId,
    formTemplateId,
    approvedOnly,
    summarizeDays,
    summarizeProject,
    divisionId,
    startDate,
    endDate,
    type,
  ]);

  const filteredRows = useMemo(() => {
    if (!hideExported) return rows;
    const filtered = rows.filter(({ exportIds }) => !exportIds.includes(exportId));
    return filtered;
  }, [rows, hideExported]);

  const onExport = useCallback(async () => {
    if (!selectedExport) return;
    let batchNumber;

    if (generateBatchNumber) {
      batchNumber = await getBatchNumber({ exportId });
      if (isNullOrUndefined(batchNumber)) return;
    }

    const exportConfig = {
      addLineNumbers,
      columns: exportColumns,
      data: filteredRows,
      batchNumber,
    };

    const didExport = exporter.createCustomExport(exportTitle, exportConfig);

    if (didExport) {
      const payload = constructAfterExportPayload({
        type,
        rows: filteredRows,
        generateFormNumber,
        batchNumber,
      });

      if (Object.keys(payload).length === 0) return;

      const { data: formNumberMap = {} } = await request({
        call: axios.put(`/exports/${exportId}/after`, payload),
        hideSuccessToast: true,
      });

      if (type === 'forms') {
        const updatedRows = updateFormNumbers({ data: filteredRows, formNumberMap });
        setRows(updatedRows);
      }
    }
  }, [
    selectedExport,
    generateBatchNumber,
    generateFormNumber,
    addLineNumbers,
    exportColumns,
    exportTitle,
    exportId,
    filteredRows,
    type,
  ]);

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Row justify="start" gutter={16} align="middle">
            <TimeCardDatePicker />
          </Row>
        </Col>
        <Col>
          <Row justify="end" gutter={4} align="middle">
            <Col>
              <Checkbox
                checked={hideExported}
                onChange={(e) => setHideExported(e?.target?.checked)}
              >
                Hide previous exports?

              </Checkbox>
            </Col>
            {exportId !== 'preview' && (
            <Col>
              <OnTraccrButton
                title="Export"
                onClick={onExport}
                type="cancel"
              />
            </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider />
      {
        loading
          ? (
            <Row justify="center" align="middle" style={{ height: '100%', width: '100%' }}>
              <Col>
                <Spin />
              </Col>
            </Row>
          )
          : (
            <Table
              columns={columns}
              dataSource={filteredRows}
              pagination={false}
              scroll={{
                x: 'fit-content',
                y: scroll,
              }}
              rowKey="id"
              style={{
                overflowX: 'hidden',
              }}
            />
          )
      }

    </>
  );
}

CustomExportsTable.propTypes = {
  selectedExport: CUSTOM_EXPORT_PROP_TYPE,
  scroll: PropTypes.string,
};

CustomExportsTable.defaultProps = {
  selectedExport: null,
  scroll: null,
};

export default CustomExportsTable;
