import { DateTime, Duration } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';
import moment from 'moment';
import { message } from 'antd';

import Permissions from '../auth/Permissions';
import PDFTableExport from '../common/pdf/PDFExport/PDFTableExport';

import { roundTotalRuntime } from '../helpers/time';
import { isNullOrUndefined, mergeSets, toTitleCase } from '../helpers/helpers';
import { taskIsOutsideWorkingHours } from '../common/clock/clock.helpers';
import { timeKeyToDBKey } from './state/Timecard.constants';

export default {};

// CONSTANTS:
export const EXPORT_TYPE_PDF = 'PDF Export';
export const EXPORT_TYPE_EXCEL = 'Excel Export';

const TIMECARDS_PDF_EXPORT_TABLE_HEADER = [
  { text: 'Date', style: 'tableHeader' },
  { text: 'Name', style: 'tableHeader' },
  { text: 'Type', style: 'tableHeader' },
  { text: 'Regular Start', style: 'tableHeader' },
  { text: 'Regular Stop', style: 'tableHeader' },
  { text: 'Break Start', style: 'tableHeader' },
  { text: 'Break Stop', style: 'tableHeader' },
  { text: 'Overtime Start', style: 'tableHeader' },
  { text: 'Overtime Stop', style: 'tableHeader' },
  { text: 'DT Overtime Start', style: 'tableHeader' },
  { text: 'DT Overtime Stop', style: 'tableHeader' },
  { text: 'Details', style: 'tableHeader' },
  { text: '', style: 'tableHeader' },
  { text: 'Entry', style: 'tableHeader' },
  { text: 'Total', style: 'tableHeader' },
];

export const msToString = (ms) => {
  if (ms === 86400000) {
    return '24:00:00';
  }
  if (ms > 24 * 60 * 60 * 1000) {
    return `${Math.floor(moment.duration(ms).asHours())}:${moment.utc(ms % (60 * 60 * 1000)).format('mm:ss')}`;
  }
  return `${moment.utc(ms).format('HH:mm:ss')}`;
};

// HELPERS:

export const getCanEditUnapproved = (userId, position) => (
  Permissions.has(`TIMECARD_EDIT_${Permissions.formatPosition(position)}`)
    || (userId === Permissions.id && Permissions.has('TIMECARD_EDIT_SELF'))
);

export const getCanEditApproved = (isIndividual, userId, position) => (
  (isIndividual && Permissions.has('APPROVED_TIMECARD_EDIT_SELF'))
    || (userId === Permissions.id && Permissions.has('APPROVED_TIMECARD_EDIT_SELF'))
    || (position && userId !== Permissions.id && Permissions.has(`APPROVED_TIMECARD_EDIT_${Permissions.formatPosition(position)}`))
);

export const decorateTasks = ({
  tasks,
  checkGeofence,
  projectIdMap = {},
  costcodeIdMap = {},
  isIndividual,
  canEdit: canEditAll,
  user = {},
  workingHours = {},
  userMap = {},
  tabletMap,
  isSummary,
}) => (
  tasks.map((task) => {
    const {
      projectId,
      costcodeId,
      startLatitude,
      startLongitude,
      endLatitude,
      endLongitude,
      state,
      userId,
      enteredMetadata,
      startMetadata,
      endMetadata,
    } = task;
    let {
      id,
      position,
    } = user;
    let canEditSpecificTask = false;
    if (isSummary) {
      const ourUser = userMap?.[userId] ?? {};
      id = ourUser?.id ?? null;
      position = ourUser?.position ?? null;

      canEditSpecificTask = getCanEditUnapproved(id, position);
    }
    const isApproved = state === 'approved';
    const canEditTask = (!isApproved && (canEditAll || canEditSpecificTask)) || (
      isApproved && getCanEditApproved(isIndividual, id, position)
    );
    const {
      [projectId]: project = {},
    } = projectIdMap;

    const {
      [costcodeId]: costcode = {},
    } = costcodeIdMap;

    let geofenceViolation = false;
    if (checkGeofence) {
      if (startLatitude && startLongitude) {
        geofenceViolation = TaskHelpers.isGeofenceViolation({
          location: {
            latitude: startLatitude,
            longitude: startLongitude,
          },
          project,
        });
      }
      if (endLatitude && endLongitude) {
        geofenceViolation = geofenceViolation || TaskHelpers.isGeofenceViolation({
          location: {
            latitude: endLatitude,
            longitude: endLongitude,
          },
          project,
        });
      }
    }

    const {
      enteredViaMetadata,
      enteredViaText,
    } = TaskHelpers.parseEnteredViaMetadata({
      enteredMetadata,
      startMetadata,
      endMetadata,
      userMap,
      tabletMap,
    }) ?? {};

    return {
      ...task,
      projectName: project?.name,
      projectNumber: project?.number,
      costCode: costcode.code,
      fullCostcode: costcode.id ? `${costcode.code} - ${costcode.name}` : null,
      geofenceViolation,
      canEdit: canEditTask,
      outsideWorkingHours: taskIsOutsideWorkingHours(task, workingHours[position] ?? {}),
      enteredViaMetadata,
      enteredVia: enteredViaText,
    };
  })
);

/**
 * Gets formatted date for pdf export
 * @param {number} timestamp
 * @returns {string}
 */
export const formatDate = (timestamp, zone) => {
  if (!timestamp) return { text: '' };
  return DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
};

/**
 * Gets 12-hour time formatted for pdf export
 * @param {number} timestamp
 * @returns {object}
 */
const formatTime = (timestamp, zone) => {
  if (!timestamp || typeof timestamp !== 'number') return { text: '' };
  const time = DateTime.fromMillis(timestamp, { zone })
    .toLocaleString({
      hour: '2-digit',
      minute: 'numeric',
      timeZoneName: 'short',
    });
  return { text: time ? time : '' };
};

/**
 * Gets project details formatted for pdf export
 * @param {string} projectName
 * @param {string} phase
 * @param {string} costcode
 * @returns {object}
 */
const formatProjectDetails = ({
  t,
  projectName,
  phase,
  costcode,
}) => ({
  text: `${t('Project')}: ${projectName || '-'}\nPhase: ${phase || '-'}\nCost Code: ${costcode || '-'}`,
  alignment: 'left',
});

/**
 * Gets formatted duration for pdf export
 * @param {number} runtime
 * @param {object}
 */
const formatRunTime = (runtime) => {
  if (isNullOrUndefined(runtime) || typeof runtime !== 'number') return { text: '' };
  const minutesRounded = Math.round(runtime / 60000);
  const duration = Duration.fromObject({ minutes: minutesRounded }).toFormat('hh:mm');
  return { text: duration ?? '' };
};

/**
 * Creates a pdf row(s) representing a task
 * @param {boolean} isFirst - is first task of day
 * @param {boolean} isLast - is last task of day
 * @param {number} spacerCount - the number of empty rows between days
 * @param {object} task
 * @param {object} runtimeMap
 * @param {number} roundingInterval
 * @param {object} userMap
 * @returns {array} pdf task row(s) to add
 */
const createTaskRow = ({
  t,
  isFirst,
  isLast,
  spacerCount = 3,
  task = {},
  runtimeMap = {},
  roundingInterval,
  userMap = {},
  roundingType,
  roundingSetting,
}) => {
  const {
    projectName,
    costCode,
    phase,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    note,
    type,
    hourBased,
    timezone,
    userId,
  } = task;
  const taskEndTime = TaskHelpers.getEndTime(task);
  const taskStartTime = TaskHelpers.getStartTime(task);
  const timestamp = taskEndTime || taskStartTime;
  const runtimes = TaskHelpers.getRuntimes(task);
  const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
  let rows = [];
  const formattedType = toTitleCase(type);
  const ourUsername = userMap[userId]?.name;
  if (isFirst) {
    const formattedDate = formatDate(timestamp, timezone);
    const descriptiveDayRow = [
      formattedDate,
      ourUsername,
      formattedType,
      hourBased ? '' : formatTime(startTime, timezone),
      hourBased ? '' : formatTime(endTime, timezone),
      hourBased ? '' : formatTime(breakStartTime, timezone),
      hourBased ? '' : formatTime(breakEndTime, timezone),
      hourBased ? '' : formatTime(otStartTime, timezone),
      hourBased ? '' : formatTime(otEndTime, timezone),
      hourBased ? '' : formatTime(doubleOTStartTime, timezone),
      hourBased ? '' : formatTime(doubleOTEndTime, timezone),
      formatProjectDetails({
        t,
        projectName,
        phase,
        costcode: costCode,
      }),
      { text: note, alignment: 'left' },
      formatRunTime(runtime),
      formatRunTime(
        roundTotalRuntime(
          runtimeMap[`${userId}-${formattedDate}`],
          roundingInterval,
          roundingType,
          roundingSetting,
        ),
      ),
    ];
    rows.push(descriptiveDayRow);
  } else {
    const regularTaskRow = [
      { text: '' },
      ourUsername,
      formattedType,
      hourBased ? '' : formatTime(startTime, timezone),
      hourBased ? '' : formatTime(endTime, timezone),
      hourBased ? '' : formatTime(breakStartTime, timezone),
      hourBased ? '' : formatTime(breakEndTime, timezone),
      hourBased ? '' : formatTime(otStartTime, timezone),
      hourBased ? '' : formatTime(otEndTime, timezone),
      hourBased ? '' : formatTime(doubleOTStartTime, timezone),
      hourBased ? '' : formatTime(doubleOTEndTime, timezone),
      formatProjectDetails({
        t,
        projectName,
        phase,
        costcode: costCode,
      }),
      { text: note, alignment: 'left' },
      formatRunTime(runtime),
      { text: '' },
    ];
    rows.push(regularTaskRow);
  }
  if (isLast) {
    const emptySpacerRow = new Array(TIMECARDS_PDF_EXPORT_TABLE_HEADER.length).fill('');
    rows = rows.concat(Array(spacerCount).fill(emptySpacerRow));
  }
  return rows;
};

/**
 * Creates daily run time map
 * @param {array} tasks
 * @returns {object}
 */
const getDailyRuntimeMap = (tasks = []) => {
  const map = {};
  tasks.forEach((task) => {
    const startTime = TaskHelpers.getStartTime(task);
    const endTime = TaskHelpers.getEndTime(task);
    if (!endTime) return;

    const {
      timezone,
      userId,
    } = task || {};

    const timestamp = endTime || startTime;
    const runtimes = TaskHelpers.getRuntimes(task);
    const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
    const key = `${userId}-${formatDate(timestamp, timezone)}`;
    if (!map[key]) {
      map[key] = runtime;
      return;
    }
    map[key] += runtime;
  });
  return map;
};

/**
 * Prepares the task in pdf export format (array of arrays),
 * If there are no tasks return empty row
 * @param {object} t - i18n translation function
 * @param {number} roundingInterval
 * @param {bool} isSummary
 * @param {object} userMap
 * @param {array} tasks
 * @param {string} roundingType
 * @returns {string[][]} - pdf rows
 */
const refinePDFExportTaskData = ({
  t,
  roundingInterval,
  isSummary,
  userMap,
  tasks = [],
  roundingType,
  roundingSetting,
}) => {
  let sortedTasks = tasks;
  if (!isSummary) {
    sortedTasks = tasks
      .map((task) => ({ ...task })) // use map to return new tasks to allow sort (mutability)
      .sort((a, b) => TaskHelpers.getEndTime(a) - TaskHelpers.getEndTime(b));
  }
  const dailyRuntimeMap = getDailyRuntimeMap(sortedTasks);
  const dayLabelsAdded = new Set();
  const taskRows = [];
  sortedTasks.forEach((task, index) => {
    const {
      timezone,
      userId,
    } = task || {};
    const nextTask = sortedTasks[index + 1] || {};
    const currentTaskStartTimeStamp = TaskHelpers.getStartTime(task);
    const currentTaskEndTimeStamp = TaskHelpers.getEndTime(task);
    const nextTaskStartTimeStamp = TaskHelpers.getStartTime(nextTask);
    const nextTaskEndTimeStamp = TaskHelpers.getEndTime(nextTask);
    const currentTaskTS = currentTaskEndTimeStamp || currentTaskStartTimeStamp;
    const nextTaskTS = nextTaskStartTimeStamp || nextTaskEndTimeStamp;
    const currentTaskFormattedDateKey = `${userId}-${formatDate(currentTaskTS, timezone)}`;
    const nextTaskFormattedDateKey = `${userId}-${formatDate(nextTaskTS, timezone)}`;
    let isFirstTaskOfDay = false;
    let isLastTaskOfDay = false;
    if (!dayLabelsAdded.has(currentTaskFormattedDateKey)) {
      isFirstTaskOfDay = true;
      dayLabelsAdded.add(currentTaskFormattedDateKey);
    }
    if (nextTaskFormattedDateKey !== currentTaskFormattedDateKey) {
      isLastTaskOfDay = true;
    }
    taskRows.push(...createTaskRow({
      t,
      isFirst: isFirstTaskOfDay,
      isLast: isLastTaskOfDay,
      task,
      runtimeMap: dailyRuntimeMap,
      roundingInterval,
      userMap,
      roundingType,
      roundingSetting,
    }));
  });
  return taskRows;
};

/**
 * Adjusts/prepares timecard data structure for pdf export
 * @param {object} t - i18n translation function
 * @param {object} companyImageURL
 * @param {object} user
 * @param {number} start - millis
 * @param {number} end - millis
 * @param {array} tasks
 */
export const timecardPDFExportAdapter = async ({
  t,
  companyImageURL,
  user: {
    name: userName = '',
    employeeId = '',
  } = {},
  start = 0,
  end = 0,
  tasks,
  roundingInterval,
  isSummary,
  userMap = {},
  roundingType,
  roundingSetting,
}) => {
  const pdfTableExport = new PDFTableExport();
  // Prepare/Construct Header:
  const logoEntry = await PDFTableExport.getCompanyLogo(companyImageURL);
  const timeRangeLabel = PDFTableExport.getTimeRangeLabel(start, end);
  const leftColumn = PDFTableExport.createColumnList({
    content: [
      { text: isSummary ? 'Summary' : userName, style: 'header' },
      employeeId ? { text: `ID: ${employeeId}`, style: 'subHeader' } : { text: '' },
    ],
  });
  const rightColumn = PDFTableExport.createColumnList({
    content: [
      { text: timeRangeLabel, style: 'header' },
      { text: '' },
    ],
  });
  const header = PDFTableExport.createHeader({
    useLogo: true,
    logoEntry,
    columns: [leftColumn, rightColumn],
  });
  // Prepare/Construct Body:
  const bodyRows = refinePDFExportTaskData({
    t,
    roundingInterval: roundingInterval || 1,
    isSummary,
    userMap,
    tasks,
    roundingType,
    roundingSetting,
  });
  const bodyTable = PDFTableExport.createTable({
    widths: [50, 50, 30, 50, 50, 50, 50, 50, 50, 50, 50, 80, '*', 30, 30],
    header: TIMECARDS_PDF_EXPORT_TABLE_HEADER,
    rows: bodyRows,
  });

  // Export PDF:
  const intervalStart = DateTime.fromMillis(start).toLocaleString(DateTime.DATE_MED);
  const intervalEnd = DateTime.fromMillis(end).toLocaleString(DateTime.DATE_MED);
  const title = `${isSummary ? 'Summary' : userName} ${intervalStart} - ${intervalEnd} Timecard Breakdown`;
  pdfTableExport.export({
    name: title,
    header,
    body: [bodyTable],
    pageOrientation: 'landscape',
    pageSize: 'A3',
  });
};

export const getRuntime = ({
  startTime,
  endTime,
  type,
}) => {
  if (!(endTime && endTime > startTime) || type === 'break') {
    return 0;
  }

  return endTime - startTime;
};

export const roundDayRuntime = (runtime, roundingInterval, roundingType, roundingSetting) => {
  if (roundingType === 'taskTime') return runtime;

  return TaskHelpers.getRoundedRuntime(
    runtime,
    { roundingInterval, roundingSetting },
  );
};

/**
 * Takes an array of distributionRows and distributes the runtimes based on the initialStartTimes
 * of each hour type of the original entry
 * @param {array} initialStartTimes - an object with the initial start times for each hour type
 *
 * {regularTime: DateTime, breakTime: DateTime, overtime: DateTime, doubleOT: DateTime}
 * @param {array} distributionRows - an array of distribution rows
 *
 * {regularTime: millis, breakTime: millis, overtime: millis, doubleOT: millis}
 * @param {object} entry - the original entry
 * @returns {array} - an array of new entries with the distributed runtimes
 */
export const distributeRuntimes = ({
  initialStartTimes = {},
  distributionRows = [],
  entry = {},
}) => {
  const taskDate = TaskHelpers.getTaskDate(entry);
  if (!taskDate) {
    message.error('Failed to distribute hours');
    return null;
  }
  const newEntries = {};

  const dayStart = taskDate.startOf('day');

  Object.entries(timeKeyToDBKey).forEach(([key, value]) => {
    const offset = initialStartTimes[key];
    if (!offset?.isValid) return;
    distributionRows.forEach((row) => {
      const ourStart = row.id === entry.id
        ? offset
        : dayStart;
      if (!newEntries[row.id]) {
        newEntries[row.id] = {
          id: row.id,
          costcodeId: row.costcodeId,
          note: row.note,
        };
      }

      const startKey = value === '' ? 'startTime' : `${value}StartTime`;
      const endKey = value === '' ? 'endTime' : `${value}EndTime`;

      // Grab hour and minute offsets of current row's time
      const time = row[key];
      const currentTime = time ? DateTime.fromMillis(time) : null;
      const { hour, minute } = currentTime || {};

      if (hour || minute) {
        // Calculate new start and end times based on offset
        const newTime = ourStart.plus({
          hours: hour,
          minutes: minute,
        });

        // Set startTime and endTime for the current entry
        const startTime = ourStart.toMillis();
        const endTime = newTime.toMillis();

        newEntries[row.id][startKey] = startTime;
        newEntries[row.id][endKey] = endTime;
      } else {
        newEntries[row.id][startKey] = null;
        newEntries[row.id][endKey] = null;
      }
    });
  });

  return Object.values(newEntries) ?? [];
};

// returns a time range with added buffer for any ot pay calculations
export const getTimesFromTimeRange = (timeRange) => {
  let [startDT, endDT] = timeRange;
  if (startDT) {
    startDT = startDT.minus({ days: 15 }).startOf('day'); // ot buffer (calculateOT)
  } else {
    startDT = DateTime.local().minus({ days: 15 }).startOf('day');
  }
  if (endDT) {
    endDT = endDT.plus({ days: 5 }).endOf('day');
  } else {
    endDT = DateTime.local().plus({ days: 5 }).endOf('day');
  }
  return {
    startTime: startDT.toMillis(),
    endTime: endDT.toMillis(),
  };
};

export const getUsersFromSelectedDivisions = ({
  users,
  selectedDivisions,
  divisions,
}) => {
  const selectedDivUsers = Object.values(divisions)
    .filter(({ id }) => selectedDivisions.has(id))
    .map(({ users: divUsers = new Set() }) => divUsers);
  const mergedSelectedDivUsers = mergeSets(selectedDivUsers);
  return users.filter((user) => mergedSelectedDivUsers.has(user.id) && user.active);
};
