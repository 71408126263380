import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, DatePicker, Select } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormTextInput from '../common/inputs/FormTextInput';
import FilteredUserSelector from '../common/inputs/FilteredUserSelector';
import DisplayText from '../common/text/DisplayText';
import FileReelWithUpload from '../common/files/FileReelWithUpload';
import { getIdMap } from '../helpers/helpers';
import { PRIORITY_OPTS, STATUS_OPTS } from '../helpers/subtasks.constants';

import SubtaskReminderEmailSelector from './SubtaskReminderEmailSelector';
import SubtaskReminderSelector from './SubtaskReminderSelector';
import SubtaskRelativeDueDateSelector from './SubtaskRelativeDueDateSelector';
import ScheduleDateRepeatPopover from '../schedule/ScheduleDateRepeatPopover';
import { validateRepeatEndDate } from '../helpers/subtaskHelpers';
import SelectFormInput from '../common/inputs/SelectFormInput';
import { changeSubtaskStatus } from './state/subtasks.actions';
import OnTraccrCheckbox from '../common/inputs/OnTraccrCheckbox';

export default function SubtaskForm({
  visible,
  form,
  isDisplay,
  isTemplate,
  isBoardWorkflow,
  isTriggeredTask,
  selectedTask = {},
  files = [],
  reminderEmails = [],
  reminders = [],
  style = {},
  initialFormData,
  onFormDataChanged,
  relativeOptions = [],
}) {
  const {
    id,
    title,
    description,
    assigneeId,
    autoAssignUser,
    priority,
    dueDate,
    repeat,
    repeatEndDate,
    groupId,
    templateName,
    templateDescription,
    formTemplateId,
    formId,
    status,
  } = selectedTask;
  const dispatch = useDispatch();
  const subtasks = useSelector((state) => state.subtasks.subtasks);
  const originalTask = useMemo(() => {
    if (!groupId || !subtasks) return false;
    const list = Object.values(subtasks);
    const ourTasks = list.filter((task) => task.groupId === groupId);
    const orderedTasks = ourTasks.sort((a, b) => (a.dueDate - b.dueDate));
    return orderedTasks[0];
  }, [subtasks, selectedTask]);

  const [dueDateValue, setDueDateValue] = useState(dueDate);
  const [repeatValue, setRepeatValue] = useState(repeat);
  const [autoAssignUserValue, setAutoAssignUserValue] = useState(autoAssignUser);

  const rawDueDateValue = useMemo(
    () => (moment.isMoment(dueDateValue)
      ? dueDateValue.valueOf()
      : dueDateValue),
    [dueDateValue],
  );

  const repeatStartDate = useMemo(() => {
    if (isTemplate || isTriggeredTask) {
      return null;
    }
    // Use dueDateValue for individual and new new tasks.
    if (!originalTask) {
      return rawDueDateValue || null;
    }
    // Otherwise use original task's duedate
    return originalTask.dueDate || null;
  }, [
    isTemplate,
    isTriggeredTask,
    originalTask,
    rawDueDateValue,
  ]);

  useEffect(() => {
    if (dueDate) {
      setDueDateValue(dueDate);
    }
    setRepeatValue(repeat);
  }, [dueDate, repeat]);

  useEffect(() => {
    if (!visible) {
      setDueDateValue();
      setRepeatValue();
    }
    if (visible) {
      setAutoAssignUserValue(autoAssignUser);
    }
  }, [visible, autoAssignUser]);

  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);

  const activeUsers = useSelector((state) => (
    state.users.users.filter((user) => user.active)
  ));

  const assignedUserName = useMemo(() => {
    const {
      [assigneeId]: {
        name,
      } = {},
    } = userMap;
    return name;
  }, [assigneeId, userMap]);

  const dueDateDT = (dueDate && !isTemplate && Number.isInteger(dueDate))
    ? DateTime.fromMillis(dueDate)
    : null;
  const dateText = dueDateDT
    ? dueDateDT.toLocaleString(DateTime.DATE_MED)
    : 'Not Set';

  const endDateDT = (repeatEndDate && !isTemplate && Number.isInteger(repeatEndDate))
    ? DateTime.fromMillis(repeatEndDate)
    : null;
  const endDateText = endDateDT
    ? endDateDT.toLocaleString(DateTime.DATE_MED)
    : 'Not Set';

  const startDateDT = (repeatStartDate && !isTemplate && Number.isInteger(repeatStartDate))
    ? DateTime.fromMillis(repeatStartDate)
    : null;
  const startDateText = startDateDT
    ? startDateDT.toLocaleString(DateTime.DATE_MED)
    : 'Not Set';

  const isOverdue = dueDateDT && DateTime.local() > dueDateDT;

  const onFormValuesChanged = useCallback((values, newValues) => {
    if (isDisplay && newValues.status !== status) {
      dispatch(changeSubtaskStatus(id, newValues.status));
    }
    const {
      dueDate: newDueDate,
      repeat: newRepeat,
      autoAssignUser: newAutoAssignUser,
    } = values;
    if (newDueDate) {
      setDueDateValue(newDueDate);
    }
    if (newRepeat) {
      setRepeatValue(repeat);
    }
    setAutoAssignUserValue(newAutoAssignUser);
  }, [id, title, isDisplay]);

  const dueDateSelection = useCallback((type, text) => {
    const showRelativeOptions = isTriggeredTask;
    let filteredRelativeOptions = relativeOptions;
    if (relativeOptions && type === 'End Date') {
      filteredRelativeOptions = relativeOptions.filter(({ type: fieldType }) => fieldType !== 'dateRange');
    } else if (relativeOptions && type === 'Due Date') {
      filteredRelativeOptions = relativeOptions.filter(({ value }) => value !== 'dueDate');
    }
    if (isTemplate || isTriggeredTask) {
      return (
        <SubtaskRelativeDueDateSelector
          isDisplay={isDisplay}
          type={type}
          relativeOptions={showRelativeOptions ? filteredRelativeOptions : undefined}
        />
      );
    }
    return (
      isDisplay
        ? <DisplayText title={text} style={isOverdue ? { color: 'red' } : {}} />
        : <DatePicker format="MMM Do YY" allowClear />
    );
  }, [
    isTemplate,
    isDisplay,
    isTriggeredTask,
    isOverdue,
  ]);

  const parsedDueDate = useMemo(() => {
    if (isTemplate) {
      return dueDate || {};
    }
    return dueDate ? moment(dueDate) : undefined;
  }, [
    isTemplate,
    dueDate,
  ]);

  const parsedRepeatEndDate = useMemo(() => {
    if (isTemplate) {
      return repeatEndDate || {};
    }
    return repeatEndDate ? moment(repeatEndDate) : undefined;
  }, [
    isTemplate,
    repeatEndDate,
  ]);

  const repeatEndValidator = useCallback(() => {
    const {
      dueDate: formDueDate,
      repeat: formRepeat,
      repeatEndDate: formRepeatEndDate,
    } = form.getFieldsValue();

    const dueDateMs = formDueDate && moment.isMoment(formDueDate)
      ? formDueDate.valueOf() : null;
    const endDateMs = formRepeatEndDate && moment.isMoment(formRepeatEndDate)
      ? formRepeatEndDate.valueOf() : null;
    return validateRepeatEndDate({
      startDate: dueDateMs,
      repeat: formRepeat,
      repeatEndDate: endDateMs,
      relativeEndDate: isTriggeredTask ? formRepeatEndDate : null,
      isTemplate,
      isTriggeredTask,
      type: 'Due Date',
    });
  }, [form, isTemplate, isTriggeredTask]);

  const showRepeatStart = !isTemplate && !isTriggeredTask
    && repeatValue && repeatStartDate
    && originalTask && originalTask.id !== id;

  useEffect(() => {
    form.setFieldsValue({
      ...selectedTask,
      dueDate: parsedDueDate,
      repeatEndDate: parsedRepeatEndDate,
      files,
      reminderEmails,
      reminders,
    });
  }, []);

  return (
    <Form
      form={form}
      onValuesChange={onFormValuesChanged}
      layout="vertical"
      style={{
        overflowY: 'auto',
        position: 'absolute',
        left: 24,
        right: 24,
        top: 55,
        bottom: 53,
        ...style,
      }}
    >
      { (isTemplate && !isTriggeredTask) && (
        <>
          <FormTextInput
            isNotDisplay={!isDisplay}
            label="Template Name"
            name="templateName"
            rules={[{ required: true, message: 'Name is required' }]}
            value={templateName}
          />
          <FormTextInput
            isNotDisplay={!isDisplay}
            label="Template Description"
            name="templateDescription"
            textarea
            value={templateDescription}
          />
        </>
      )}
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Title"
        name="title"
        rules={[{ required: (!isTemplate || isTriggeredTask), message: 'Title is required' }]}
        value={title}
      />
      <FormTextInput
        isNotDisplay={!isDisplay}
        label="Description"
        name="description"
        textarea
        value={description}
      />
      <Form.Item
        label="Assigned User"
        name="assigneeId"
        style={{ marginBottom: isDisplay ? 0 : 10 }}
        labelCol={{
          style: {
            paddingBottom: 0,
            marginTop: 5,
          },
        }}
      >
        {
          isDisplay
            ? <DisplayText title={assignedUserName} />
            : (
              <FilteredUserSelector
                disabled={autoAssignUserValue && isBoardWorkflow}
                users={activeUsers}
                mode="single"
                allowClear={isTemplate && !isTriggeredTask}
              />
            )
        }
      </Form.Item>
      { isBoardWorkflow && (
      <Form.Item
        name="autoAssignUser"
        style={{ marginBottom: isDisplay ? 0 : 10 }}
        labelCol={{
          style: {
            paddingBottom: 0,
            marginTop: 5,
          },
        }}
      >
        <OnTraccrCheckbox
          label="Auto Assign from Card User?"
          hoverNode={(
            <div style={{ width: 250 }}>
              Selecting this will auto assign the task to the board&apos;s assigned user
              at the time of the status change. <br /> <br />
              Note that if there are multiple card users, a user will be selected at random.
            </div>
          )}
        />
      </Form.Item>
      )}
      <Form.Item
        label="Due Date"
        name="dueDate"
      >
        { dueDateSelection('Due Date', dateText)}
      </Form.Item>
      { dueDateValue && (dueDateValue?.type !== 'dateRange') && (
        <Form.Item
          name="repeat"
          style={showRepeatStart ? {} : { marginTop: -10 }}
        >
          <ScheduleDateRepeatPopover
            isDisplay={isDisplay}
            isTemplate={isTemplate || isTriggeredTask}
            startTime={repeatStartDate}
            onChange={setRepeatValue}
          />
        </Form.Item>
      )}
      { showRepeatStart && (
        <Form.Item
          label="Repeat Start Date"
          style={{ marginTop: -10 }}
        >
          {
          isDisplay
            ? (
              <DisplayText
                label="Repeat Start Date"
                title={startDateText}
              />
            )
            : (
              <DatePicker
                label="Repeat Start Date"
                format="MMM Do YY"
                value={moment(repeatStartDate)}
                disabled
              />
            )
          }
        </Form.Item>
      )}
      { repeatValue && (dueDateValue?.type !== 'dateRange') && (
        <Form.Item
          label="Repeat End Date"
          name="repeatEndDate"
          style={{ marginTop: -10 }}
          rules={[{
            required: !!repeatValue && (!isTemplate || isTriggeredTask),
            validator: repeatEndValidator,
          }]}
        >
          { dueDateSelection('End Date', endDateText)}
        </Form.Item>
      )}
      <Form.Item
        name="reminderEmails"
        label="Reminder Emails"
      >
        <SubtaskReminderEmailSelector isDisplay={isDisplay} />
      </Form.Item>
      <Form.Item
        name="reminders"
        label="Reminders"
      >
        <SubtaskReminderSelector isDisplay={isDisplay} />
      </Form.Item>
      <Form.Item
        label="Priority"
        name="priority"
        rules={[{ required: (!isTemplate || isTriggeredTask), message: 'Priority is required' }]}
      >
        {
          isDisplay
            ? <DisplayText title={priority} />
            : (
              <Select
                options={PRIORITY_OPTS}
                allowClear={isTemplate && !isTriggeredTask}
              />
            )
        }
      </Form.Item>

      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: (!isTemplate || isTriggeredTask), message: 'Status is required' }]}
      >
        <Select
          options={STATUS_OPTS}
          allowClear={isTemplate && !isTriggeredTask}
        />
      </Form.Item>
      { !isTriggeredTask && (
        <SelectFormInput
          isDisplay={isDisplay || formId}
          initialForm={formTemplateId}
          initialFormData={initialFormData}
          onFormDataChanged={onFormDataChanged}
        />
      )}
      { !isTemplate && !isTriggeredTask && (
        <Form.Item
          name="files"
          label="Attachments"
          className="schedule-form-item"
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 5,
            },
          }}
          valuePropName="files"
        >
          <FileReelWithUpload
            isDisplay={isDisplay}
            downloadEnabled={isDisplay}
          />
        </Form.Item>
      )}
    </Form>
  );
}

SubtaskForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  isDisplay: PropTypes.bool,
  isTemplate: PropTypes.bool,
  selectedTask: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    assigneeId: PropTypes.string,
    priority: PropTypes.string,
    status: PropTypes.string,
    dueDate: PropTypes.number,
    repeat: PropTypes.string,
    repeatEndDate: PropTypes.number,
    groupId: PropTypes.string,
    templateName: PropTypes.string,
    templateDescription: PropTypes.string,
    formTemplateId: PropTypes.string,
  }),
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  })),
  reminderEmails: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  })),
  reminders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    time: PropTypes.string,
  })),
  style: PropTypes.shape({}),
  initialFormData: PropTypes.shape({}),
  onFormDataChanged: PropTypes.func,
};

SubtaskForm.defaultProps = {
  isDisplay: false,
  isTemplate: false,
  selectedTask: {},
  files: [],
  reminderEmails: [],
  reminders: [],
  style: {},
  initialFormData: {},
  onFormDataChanged: () => {},
};
