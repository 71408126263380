import { DateTime } from 'luxon';

import { isNullOrUndefined } from 'ontraccr-common/lib/Common';
import { getId as extractId, getIdMap } from '../helpers/helpers';

import Colors from '../constants/Colors';
import {
  QUARTER_HEIGHT,
  RIGHT_OFFSET,
  ITEM_GAP,
  WEEKLY_BASE_OFFSET,
  WEEKLY_HEADER_BASE_HEIGHT,
  WEEKLY_HEADER_PADDING,
  WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT,
} from './schedule.constants';
import { REMINDER_OPTIONS } from './reminder/reminder.constants';
import { isObjectEmpty } from '../common/helpers';

export const getId = () => DateTime.local().toMillis();
// Luxon starts week on Mon, so we substract one to make first day Sun
// https://github.com/moment/luxon/issues/373
export const getWeekStart = (day) => (
  day.weekdayShort === 'Sun'
    ? day
    : day.startOf('week').minus({ day: 1 })
);
export const getWeekEnd = (day) => day.endOf('week').minus({ day: 1 });
export const getBiWeeklyEnd = (day) => {
  const weekstart = getWeekStart(day);
  return weekstart.plus({ day: 14 });
};

export const getTimeOnNewDay = (startOfDay, time) => {
  const timeDT = DateTime.fromMillis(time);
  const newDT = startOfDay.plus({ hours: timeDT.hour, minutes: timeDT.minute });
  return newDT.toMillis();
};

export const getDayKey = (day) => day.toLocaleString(DateTime.DATE_FULL);
export const positionToQuarter = (pos, scale = 1) => Math.floor(pos / (QUARTER_HEIGHT * scale));
export const offsetToQuarter = (offset, scale = 1) => (
  positionToQuarter(offset, scale)
);
export const quarterToText = (quarterHours) => {
  let now = DateTime.local().startOf('day');
  if (!Number.isNaN(quarterHours)) {
    now = now.plus({ minutes: quarterHours * 15 });
  }
  return now.toLocaleString(DateTime.TIME_SIMPLE);
};

export const yToHour = ({
  scrollTop, e, isWeek, scale = 1, headerHeight = 75, containerY,
}) => {
  const {
    left: parentLeft,
    right: parentRight,
  } = e.currentTarget.getBoundingClientRect();
  const { clientY, clientX } = e;
  const xOffset = clientX - parentLeft;
  const headerOffset = (isWeek ? WEEKLY_BASE_OFFSET + headerHeight : containerY || 160);
  const offset = scrollTop + clientY - headerOffset; // Top offset of div

  // Each 15 minute interval is 25px.
  const quarterHours = offsetToQuarter(offset, scale);

  return {
    offset,
    top: (quarterHours * 25),
    text: quarterToText(quarterHours),
    xOffset,
    parentWidth: parentRight - parentLeft,
  };
};

export const decorateShiftWithTimes = (shift, day = DateTime.local()) => {
  const { top } = shift;
  let { bottom } = shift;

  if (!bottom) bottom = top + QUARTER_HEIGHT;
  if (Number.isNaN(top) || Number.isNaN(bottom)) return shift;
  const startTime = day
    .startOf('day').plus({ minutes: 15 * (top / QUARTER_HEIGHT) }).toMillis();
  const endTime = day
    .startOf('day').plus({ minutes: 15 * (bottom / QUARTER_HEIGHT) }).toMillis();
  return {
    ...shift,
    startTime,
    endTime,
  };
};

export const timestampToPosition = (ts, reference = DateTime.local().startOf('day')) => {
  const dt = DateTime.fromMillis(ts);
  const diff = dt.diff(reference).as('minutes');
  return (diff / 15) * QUARTER_HEIGHT;
};

export const isInExisting = ({ sortedShifts, shift }) => {
  // xOffset is the x coordinate of the click
  if (shift.xOffset >= shift.parentWidth - 25) return false;
  const xClickPercent = 100 * (shift.xOffset / shift.parentWidth);

  const existing = sortedShifts.find((existingShift) => (
    shift.top >= existingShift.top
    && shift.top <= existingShift.bottom
    && xClickPercent >= existingShift.left
    && xClickPercent <= existingShift.left + existingShift.width
  ));

  if (!existing) return false;
  const { top, bottom } = existing;
  const { top: clickPoint } = shift;
  const editShift = { ...existing };
  // Cant drag shifts when they are too small
  if (bottom - top <= 80) return editShift;
  if (Math.abs(clickPoint - top) <= 30) {
    // User wants to stretch top
    editShift.topStretch = true;
    editShift.cursor = 'ns-resize';
  } else if (Math.abs(bottom - clickPoint) <= 30) {
    // User wants to stretch end
    editShift.bottomStretch = true;
    editShift.cursor = 'ns-resize';
  } else {
    editShift.topDragOffset = top - clickPoint;
    editShift.cursor = 'move';
  }
  return editShift;
};

export const onMouseDown = ({
  day,
  e,
  shifts,
  scrollTop,
  onShiftEdit,
  onNewShiftChange,
  isWeek,
  scale,
  headerHeight,
  containerY,
}) => {
  const sortedShifts = shifts.sort((a, b) => a.top - b.top);
  const shift = yToHour({
    scrollTop, e, isWeek, scale, headerHeight, containerY,
  });
  const clickedExisting = isInExisting({ sortedShifts, shift });

  if (clickedExisting) {
    return onShiftEdit(clickedExisting);
  }
  onNewShiftChange({
    ...shift,
    anchorPoint: shift.top,
    id: DateTime.local().toMillis(),
    day,
  });
};

export const onMouseUp = ({
  day,
  e,
  shifts,
  scrollTop,
  editShift,
  onShiftEdit,
  newShift,
  onShiftSelect,
  onNewShiftChange,
  onShiftCreate,
  isWeek,
  scale,
  headerHeight,
  containerY,
}) => {
  document.body.style.cursor = 'auto';
  if (editShift && !isObjectEmpty(editShift)) {
    const selectShift = { ...editShift };
    delete selectShift.topStretch;
    delete selectShift.bottomStretch;
    onShiftEdit(selectShift);
    onShiftSelect(selectShift, true);
    return;
  }
  const clickedExisting = isInExisting({
    sortedShifts: shifts,
    shift: yToHour({
      scrollTop, e, isWeek, scale, headerHeight, containerY,
    }),
  });
  if (clickedExisting) {
    onShiftSelect(clickedExisting, true);
    return;
  }

  if (!newShift || isObjectEmpty(newShift)) return;
  const shift = {
    ...newShift,
    complete: true,
    day,
  };
  if (!shift.bottom) shift.bottom = shift.top + 100;
  onNewShiftChange(shift);
  onShiftCreate({
    ...decorateShiftWithTimes(shift, day),
    id: getId(),
  });
};

export const onMouseMove = ({
  day,
  e,
  scrollTop,
  editShift,
  newShift,
  onShiftEdit,
  onNewShiftChange,
  isWeek,
  scale,
  headerHeight,
  containerY,
}) => {
  const { top: newTop, offset: newOffset } = yToHour({
    scrollTop, e, isWeek, scale, headerHeight, containerY,
  });
  if (editShift && !isObjectEmpty(editShift)) {
    document.body.style.cursor = editShift.cursor;
    const dragTop = newTop + editShift.topDragOffset;
    const afterMove = { ...editShift };
    if (editShift.topStretch) {
      afterMove.top = newTop;
    } else if (editShift.bottomStretch) {
      afterMove.bottom = newTop;
    } else {
      afterMove.top = dragTop;
      afterMove.bottom = editShift.bottom + (dragTop - editShift.top);
    }
    onShiftEdit(decorateShiftWithTimes(afterMove, day));
    return;
  }
  if (!newShift || newShift.complete || isObjectEmpty(newShift)) return;
  document.body.style.cursor = 'row-resize';
  const { offset, anchorPoint } = newShift;

  if (newTop >= anchorPoint) {
    const oldQuarter = offsetToQuarter(offset, scale);
    const newQuarter = offsetToQuarter(newOffset, scale);

    onNewShiftChange({
      ...newShift,
      bottom: newQuarter * 25,
      text: `${quarterToText(oldQuarter)} - ${quarterToText(newQuarter)}`,
    });
  } else {
    const newQuarter = offsetToQuarter(newOffset, scale);
    const oldQuarter = offsetToQuarter(offset, scale);
    onNewShiftChange({
      ...newShift,
      top: newTop,
      text: `${quarterToText(newQuarter)} - ${quarterToText(oldQuarter)}`,
    });
  }
};

const includes = (a, b) => a.toLowerCase().includes(b.toLowerCase());

const filterDayShifts = ({
  shifts = [],
  searchText = '',
  userMap = {},
  selectedTitle,
  selectedDivisions,
  selectedUsers,
  selectedProjects,
  selectedCostcodes,
  selectedPhases,
  selectedForms,
  selectedEventType,
}) => shifts.filter((shift) => {
  const {
    users = [],
    title,
    description,
    divisionId,
    projectId,
    costcodeId,
    phaseId,
    formTemplateId,
    isEvent,
  } = shift;
  if (selectedProjects.size && !selectedProjects.has(projectId)) return false;
  if (selectedCostcodes.size && !selectedCostcodes.has(costcodeId)) return false;
  if (selectedPhases.size && !selectedPhases.has(phaseId)) return false;
  if (selectedDivisions.size && !selectedDivisions.has(divisionId)) return false;
  if (selectedForms.size && !selectedForms.has(formTemplateId)) return false;
  if (selectedUsers.size && !users.some((user) => selectedUsers.has(user))) return false;
  if (!isNullOrUndefined(selectedEventType) && (selectedEventType === 'event') && !isEvent) return false;
  if (selectedTitle && !includes(title, selectedTitle)) return false;

  const relevantUsers = users.some((userId) => userId in userMap
        && userMap[userId].name
        && includes(userMap[userId].name, searchText));
  return (
    (title && includes(title, searchText))
        || (description && includes(description, searchText))
        || relevantUsers
  );
});
export const filterShifts = ({
  shifts = {},
  day = DateTime.local(),
  userMap = {},
  searchText,
  viewType,
  rangeStartDate = DateTime.local(),
  rangeEndDate = DateTime.local(),
  filters = {},
}) => {
  const {
    title,
    eventType,
    users,
    divisions,
    projects,
    costcodes,
    phases,
    forms,
  } = filters;
  const selectedUsers = new Set(users);
  const selectedDivisions = new Set(divisions);
  const selectedProjects = new Set(projects);
  const selectedCostcodes = new Set(costcodes);
  const selectedPhases = new Set(phases);
  const selectedForms = new Set(forms);

  const res = {};
  const start = viewType === 'Month' ? getWeekStart(day.startOf('month')) : getWeekStart(day);
  let duration = 7;
  if (viewType === 'UserBiWeekly') {
    duration = Math.round(rangeEndDate.diff(rangeStartDate, 'days').as('days'));
  }
  if (viewType === 'Month') {
    duration = Math.ceil(day.endOf('month').endOf('week').diff(start).as('days'));
  }

  new Array(duration).fill(1).forEach((_, idx) => {
    const dayKey = getDayKey(start.plus({ day: idx }));
    const {
      [dayKey]: dayShifts = [],
    } = shifts;
    res[dayKey] = filterDayShifts({
      shifts: dayShifts,
      day,
      searchText,
      userMap,
      selectedTitle: title,
      selectedDivisions,
      selectedUsers,
      selectedProjects,
      selectedCostcodes,
      selectedPhases,
      selectedForms,
      selectedEventType: eventType,
    });
  });
  return res;
};

// Used to calculate how wide a shift should be,
// based off the number of interlapping shifts on the same hour of the same day
export const calculateWidths = ({
  dayShifts = [],
  day = DateTime.local(),
  parentWidth,
  leftOffset,
}) => {
  const minBlockWidth = Math.min(parentWidth / 10, 50);
  const dayStart = day.startOf('day');
  // Count all shifts in each quarter to figure out widths
  const quarterCounts = new Array(24 * 4).fill(0);

  const sortedShifts = [...dayShifts];
  sortedShifts.sort((a, b) => (b.endTime - b.startTime) - (a.endTime - a.startTime));
  const shiftMap = {};
  sortedShifts.forEach(({ id, startTime, endTime }) => {
    const start = parseInt(timestampToPosition(startTime, dayStart) / QUARTER_HEIGHT, 10);
    const end = parseInt(timestampToPosition(endTime, dayStart) / QUARTER_HEIGHT, 10);
    shiftMap[id] = { start, end };
    let i = start;
    const upper = end === start ? end + 1 : end;
    while (i < upper) {
      quarterCounts[i] += 1;
      i += 1;
    }
  });
  const newShifts = [];

  // Keep track of which shifts have been allocated so far to put them side by side
  // Need to keep track of how far left we've pushed as well
  const quarterShifts = new Array(24 * 4).fill(0);
  const maxLeft = new Array(24 * 4).fill(leftOffset);

  // Create grid that matches schedule
  const usedBounds = new Array(24 * 4).fill().map(() => new Array(parentWidth).fill(false));

  sortedShifts.forEach((shift) => {
    const { start, end } = shiftMap[shift.id];
    let maxCount = 1;
    let leftCount = 0;
    let currentLeftPush = leftOffset;
    let i = start;
    const upper = end === start ? end + 1 : end;
    while (i < upper) {
      maxCount = Math.max(maxCount, quarterCounts[i]);
      leftCount = Math.max(leftCount, quarterShifts[i]);
      currentLeftPush = Math.max(currentLeftPush, maxLeft[i]);
      quarterShifts[i] += 1;
      i += 1;
    }
    if (Number.isNaN(maxCount)) maxCount = 1;
    if (Number.isNaN(leftCount)) leftCount = 0;
    let width = (parentWidth - leftOffset - RIGHT_OFFSET - ITEM_GAP) / maxCount;
    let left = currentLeftPush + ITEM_GAP;
    const gapWidth = ITEM_GAP * leftCount;
    const expectedLeft = (width * leftCount) + leftOffset + gapWidth;

    if (left > expectedLeft + ITEM_GAP) {
      /*
        Shift is pushed further right than expected
        Scan left to right searching for gaps
      */
      for (let k = leftOffset; k < parentWidth; k += 1) {
        let possibleWidth = width;
        let maxL = k;
        let canFit = true;
        for (let j = start; j < upper; j += 1) {
          let l = k;
          while (l < Math.min(k + width, parentWidth) && !usedBounds[j][l]) {
            l += 1;
          }
          possibleWidth = Math.min(possibleWidth, l - k);
          if (possibleWidth <= minBlockWidth) {
            canFit = false;
            break;
          }
          maxL = Math.max(l, maxL);
        }
        if (canFit) {
          left = k + ITEM_GAP;
          width = possibleWidth - ITEM_GAP;
          break;
        }
        k = maxL;
      }
    }

    let j = start;
    while (j < upper) {
      maxLeft[j] = Math.max(left + width, maxLeft[j]);
      for (let k = parseInt(left, 10); k <= Math.min(parentWidth - 1, left + width); k += 1) {
        usedBounds[j][k] = true;
      }
      j += 1;
    }
    newShifts.push({
      ...shift,
      width: (100 * width) / parentWidth,
      left: (100 * left) / parentWidth,
      zIndex: maxCount - leftCount,
    });
  });

  return newShifts;
};

// Used to calculate the top offset and total height
// for the weekly header, based off interlapping
// All Day or Multi Day tasks on the same day.
export const calculateHeights = ({
  weekStart,
  shifts = [],
}) => {
  // Largest blocks on top
  const sortedShifts = [...shifts].sort((a, b) => (
    (b.endTime - b.startTime) - (a.endTime - a.startTime)
  ));

  const heightOffsets = new Array(7).fill(WEEKLY_HEADER_PADDING);
  let myHeight = WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT + WEEKLY_HEADER_BASE_HEIGHT;
  const blocks = [];
  sortedShifts.forEach((shift) => {
    const {
      startTime,
      endTime,
    } = shift;
    const dayStart = DateTime.fromMillis(startTime).startOf('day');
    const endDt = DateTime.fromMillis(endTime);
    const dayEnd = endDt.equals(endDt.startOf('day'))
      ? endDt.minus({ day: 1 }).endOf('day') : endDt.endOf('day');

    const ourStart = DateTime.max(dayStart, weekStart);
    const ourEnd = DateTime.min(dayEnd, weekStart.plus({ days: 6 }).endOf('day'));
    const dayDuration = Math.ceil(ourEnd.diff(ourStart).as('days'));
    const firstDay = Math.ceil(ourStart.diff(weekStart).as('days'));

    let myTop = WEEKLY_HEADER_PADDING;
    let i = firstDay;
    while (i < firstDay + dayDuration) {
      const dayHeight = heightOffsets[i];
      myTop = Math.max(dayHeight, myTop);
      i += 1;
    }
    blocks.push({
      shift,
      dayDuration,
      firstDay,
      top: myTop,
    });
    const newHeight = myTop + WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT;
    i = firstDay;
    while (i < firstDay + dayDuration) {
      heightOffsets[i] = newHeight;
      i += 1;
    }
    myHeight = Math.max(myHeight, newHeight + WEEKLY_HEADER_BASE_HEIGHT);
  });

  return {
    blocks,
    height: myHeight,
  };
};

/**
 * Removes duplicate reminders as well as default none reminders
 * @param {array<object>} remindersArr - array of reminders
 * @returns {array<number>} array of unduplicated reminder values
 */
export const refineReminders = (remindersArr = []) => {
  const remindersSet = new Set();
  return remindersArr
    .filter((reminder) => {
      const alreadyAdded = remindersSet.has(reminder.value);
      const isDefault = reminder.value === REMINDER_OPTIONS.DEFAULT_REMINDER.value;
      remindersSet.add(reminder.value);
      return !alreadyAdded && !isDefault;
    })
    .map((reminder) => reminder.value);
};

/**
 * Converts reminder values (minutesBefore) into objects containing value and label
 * @param {array<number>} reminderValues - array of reminder minutesBefore
 * @returns {array<object>}
 */
export const reformatRemindersFromValues = (reminderValues) => {
  const reminderOptionsMap = getIdMap(Object.values(REMINDER_OPTIONS), 'value');
  return reminderValues.map((value) => reminderOptionsMap[value]);
};

/**
 * Compares whether two sets contain equivalent values
 * @param {set} remindersA
 * @param {set} remindersB
 * @returns
 */
const setsAreEquivalent = (remindersA, remindersB) => {
  if (remindersA.size !== remindersB.size) return false;
  return Array.from(remindersA).every((reminderVal) => remindersB.has(reminderVal));
};

/**
 * Reminders should be updated if:
 *  - the reminders have changed
 *  - repeat has changed
 *  - start time has changed (accounts for day changes)
 * @param {set} prvReminders - reminders before edit
 * @param {set} newReminders - reminders after edit
 * @param {string} prvRepeat - repeat before edit
 * @param {string} newRepeat - repeat after edit
 * @param {string} prvRepeatEndDate - repeat end date before edit
 * @param {string} newRepeatEndDate - repeat end date after edit
 * @param {number} prvStartTime - startTime before edit (in millis)
 * @param {number} newStartTime - startTime after edit (in millis)
 * @returns {boolean} whether reminders should be updated
 */
const shouldUpdateReminders = ({
  prvReminders,
  newReminders,
  prvRepeat,
  newRepeat,
  prvRepeatEndDate,
  newRepeatEndDate,
  prvStartTime,
  newStartTime,
}) => !setsAreEquivalent(prvReminders, newReminders)
  || prvRepeat !== newRepeat
  || prvRepeatEndDate !== newRepeatEndDate
  || prvStartTime !== newStartTime;

export const prepareShiftUpdatePayload = (existingShift = {}, editShift = {}, isGroup = false) => {
  const shouldIncludeRepeat = isGroup
    || editShift.repeat !== existingShift.repeat
    || editShift.repeatEndDate !== existingShift.repeatEndDate;
  const repeat = shouldIncludeRepeat
    ? editShift.repeat
    : null;
  const repeatEndDate = shouldIncludeRepeat
    ? editShift.repeatEndDate
    : null;
  const payload = {
    title: editShift.title,
    description: editShift.description,
    startTime: editShift.startTime,
    endTime: editShift.endTime,
    projectId: editShift.projectId,
    phaseId: editShift.phaseId,
    costcodeId: editShift.costcodeId,
    divisionId: editShift.divisionId,
    repeat,
    repeatEndDate,
    isEvent: !!editShift.isEvent,
    isGroup,
    color: editShift.color,
    reminders: refineReminders(editShift.reminders),
    emailNotification: editShift.emailNotification,
    pushNotification: editShift.pushNotification,
    shouldLockClockIn: !!editShift.shouldLockClockIn,
    teams: editShift.teams,
  };

  if (payload.isEvent) {
    payload.formTemplateId = editShift.formTemplateId;
    payload.formData = editShift.formData;
  }

  const {
    files: existingFiles = [],
    users: existingUsers = [],
    reminders: existingReminders = [],
    day: existingDay,
    startTime: existingStartTime,
    repeat: existingRepeat,
    repeatEndDate: existingRepeatEndDate,
  } = existingShift;
  const existingUserSet = new Set(existingUsers);
  const existingFileSet = new Set(existingFiles.map(extractId));
  const existingRemindersSet = new Set(existingReminders);

  const {
    files: newFiles = [],
    users: newUsers = [],
    reminders: newReminders = [],
    day: newDay,
    startTime: newStartTime,
    repeat: newRepeat,
    repeatendDate: newRepeatEndDate,
  } = editShift;
  const newUserSet = new Set(newUsers);
  const newFileSet = new Set(newFiles.filter(extractId).map(extractId));
  const newRemindersSet = new Set(newReminders);

  payload.shouldUpdateReminders = shouldUpdateReminders({
    prvReminders: existingRemindersSet,
    newReminders: newRemindersSet,
    prvStartTime: existingStartTime,
    newStartTime,
    prvRepeat: existingRepeat,
    newRepeat,
    prvRepeatEndDate: existingRepeatEndDate,
    newRepeatEndDate,
    prvDay: existingDay,
    newDay,
  });
  payload.usersToDelete = existingUsers.filter((user) => !newUserSet.has(user));
  payload.filesToDelete = existingFiles
    .filter((file) => file.id && !newFileSet.has(file.id))
    .map(extractId);

  payload.usersToAdd = newUsers.filter((user) => !existingUserSet.has(user));
  payload.filesToAdd = newFiles.filter((file) => !file.id || !existingFileSet.has(file.id));

  return payload;
};

export const splitMultiDayShifts = (shifts = [], day) => {
  const multiDay = [];
  const interDay = [];
  shifts.forEach((shift) => {
    const { startTime, endTime } = shift;
    if (startTime && endTime && day) {
      const startDT = DateTime.fromMillis(startTime);
      const endDT = DateTime.fromMillis(endTime);
      if (startDT <= day.startOf('day') || endDT >= day.endOf('day')) {
        multiDay.push(shift);
      } else {
        interDay.push(shift);
      }
    }
  });
  return { multiDay, interDay };
};

export const parseTime = ({ day, ourMoment }) => {
  const ts = ourMoment.valueOf();
  return DateTime
    .fromMillis(ts)
    .set({
      day: day.day,
      month: day.month,
      year: day.year,
      second: 0,
      millisecond: 0,
    })
    .toMillis();
};

export const getTextColor = (color = '') => {
  if (color.length < 9) return 'ghostwhite';
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  const a = parseInt(color.substring(7, 9), 16);
  if (a < 160) return 'black';
  const total = r * 0.299 + g * 0.587 + b * 0.114;
  return total > 200 ? 'black' : 'ghostwhite';
};

/**
 * Gets shift/event entry background color
 * @param {*} users
 * @param {*} userMap
 * @param {string} color
 * @param {boolean} isMyShift
 * @param {boolean} isEvent
 * @returns color string in hex form (e.g. #FCBA03)
 */
export const getBackgroundColor = (users, userMap, color, isMyShift, isEvent) => {
  if (color) return color;
  if (isEvent) return Colors.ONTRACCR_DARK_YELLOW;
  const hasSingleUser = users && users.length === 1;
  const backgroundColor = isMyShift ? Colors.ONTRACCR_RED : Colors.ONTRACCR_GRAY;
  if (!hasSingleUser) return backgroundColor;
  const [onlyUserId] = users;
  const { [onlyUserId]: { scheduleColor } = {} } = userMap;
  return hasSingleUser && scheduleColor ? scheduleColor : backgroundColor;
};

export const dateRangeValidator = (_, value) => {
  if (!value || value.length === 0 || Number.isNaN(value[0]) || Number.isNaN(value[1])) {
    // Handled by required: true
    return Promise.resolve();
  }
  if (value[0] > value[1]) {
    return Promise.reject(new Error('Start time must be before end time'));
  }
  return Promise.resolve();
};

export const prepareDragShiftUpdatePayload = ({
  shift,
  startOfNewDay,
  previousUserId,
  newUserId,
  emailNotification,
  pushNotification,
  updateUsers,
}) => {
  const startTime = getTimeOnNewDay(startOfNewDay, shift.startTime);
  const endTime = startTime + (shift.endTime - shift.startTime);
  const newUsers = [...shift.users];
  if (updateUsers) {
    const oldUserIndex = newUsers.indexOf(previousUserId);
    if (oldUserIndex !== -1) newUsers.splice(oldUserIndex, 1);
    newUsers.push(newUserId);
  }

  // hardcoded for now, change later
  const newShift = {
    color: shift.color,
    costCodeId: shift.costcodeId,
    dateRange: [startTime, endTime],
    description: shift.description,
    divisionId: shift.divisionId,
    endTime,
    files: shift.files,
    formData: undefined,
    formTemplateId: shift.formTemplateId,
    isEvent: !!shift.isEvent,
    phaseId: shift.phaseId,
    projectId: shift.projectId,
    reminders: shift.reminders,
    repeat: shift.repeat,
    startTime,
    title: shift.title,
    users: newUserId || !updateUsers ? newUsers : [],
  };
  return prepareShiftUpdatePayload(shift, {
    ...shift,
    ...newShift,
    emailNotification,
    pushNotification,
  });
};

export const decorateShift = (shift = {}) => {
  const { startTime, endTime } = shift;
  const day = DateTime.fromMillis(startTime);
  const dayStart = day.startOf('day');
  return {
    ...shift,
    date: startTime,
    day,
    top: timestampToPosition(startTime, dayStart),
    bottom: timestampToPosition(endTime, dayStart),
  };
};

export const addShift = (shifts = {}, shift = {}) => {
  const newShifts = {};
  Object.keys(shifts).forEach((shiftKey) => {
    newShifts[shiftKey] = [...shifts[shiftKey]];
  });
  const newShift = decorateShift(shift);
  let startDay = DateTime.fromMillis(newShift.startTime);
  const endDay = DateTime.fromMillis(newShift.endTime);
  while (startDay <= endDay) {
    const dayKey = getDayKey(startDay);
    if (!(dayKey in shifts)) {
      newShifts[dayKey] = [];
    }
    newShifts[dayKey].push(newShift);
    startDay = startDay.plus({ day: 1 });
  }
  return newShifts;
};

export const deleteShift = (shifts = {}, shift = {}) => {
  const newShifts = { ...shifts };
  const dShift = decorateShift(shift);
  let startDay = DateTime.fromMillis(dShift.startTime);
  const endDay = DateTime.fromMillis(dShift.endTime);
  while (startDay <= endDay) {
    const dayKey = getDayKey(startDay);
    const {
      [dayKey]: dayShifts = [],
    } = shifts;
    newShifts[dayKey] = [...dayShifts.filter((dayShift) => dayShift.id !== dShift.id)];
    startDay = startDay.plus({ day: 1 });
  }
  return newShifts;
};

export const addUsersToMap = (userMap, shift = []) => {
  const newUserMap = { ...userMap };
  const { users = [] } = shift;
  users.forEach((userId) => {
    if (!(userId in newUserMap)) newUserMap[userId] = [];
    newUserMap[userId].push(shift.id);
  });
  return newUserMap;
};

export const createShallowShift = (shift) => {
  const id = 'shadow';
  const defaultColor = '#dddddd';

  const { color } = shift;
  let parsedColor = color;

  if (color?.length > 7) {
    parsedColor = color.substring(0, 7);
  }

  const shadowColor = Colors.opacity(parsedColor ?? defaultColor, 0.5);

  const shadow = {
    id,
    ...shift,
    color: shadowColor,
  };

  return decorateShift(shadow);
};

export const addShallowShiftToShifts = ({
  shift = {},
  shifts = {},
  shiftMap = {},
}) => {
  const { id } = shift;
  let newShift = shift;
  let newShifts = { ...shifts };

  if (id in shiftMap) {
    const oldShift = shiftMap[id];
    newShift = { ...oldShift, ...shift };
    newShifts = deleteShift(newShifts, oldShift);
  }

  return addShift(newShifts, newShift);
};

export const getMaxStartFromRestriction = (weeksRestriction) => (
  DateTime.local()
    .plus({ weeks: weeksRestriction + 1 })
    .startOf('week')
    .minus({ days: 2 })
    .endOf('day')
    .toMillis()
);
