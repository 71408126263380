import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import BoardListSection from '../boards/BoardListView/BoardListSection';
import useBoardPermissions from '../common/hooks/useBoardPermissions';

const styleByIndex = ({ idx, statuses = [] }) => {
  const style = {};
  if (idx === 0) return style;
  style.marginTop = 20;
  if (idx < statuses.length - 1) return style;
  style.marginBottom = 50;
  return style;
};

export default function BoardResultsList({
  cardsByStatus,
  boardId,
}) {
  const boards = useSelector((state) => state.boards.boards);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);
  const boardDetails = boardDetailsMap[boardId] || {};
  const board = boards[boardId] || {};

  const {
    users = [],
    statuses = [],
  } = boardDetails;
  const {
    showCardNumber,
    cardTypeId,
    isLocked,
    creatorId,
  } = board;

  const {
    [cardTypeId]: cardType,
  } = useSelector((state) => state.boards.cardTemplates);

  const sortedStatuses = useMemo(() => {
    const sorted = statuses.filter(({ id }) => cardsByStatus[id]?.length);
    sorted.sort((a, b) => a.orderIndex - b.orderIndex);
    return sorted;
  }, [statuses, cardsByStatus]);

  const userBoardPermissions = useBoardPermissions(users, creatorId);

  const statusSections = useMemo(() => (
    <DragDropContext>
      {sortedStatuses.map((status, idx) => (
        <BoardListSection
          key={status.id}
          status={status}
          showCardNumber={cardType?.useCardNumberAsCardTitle ? false : showCardNumber}
          cardTemplate={cardType}
          cards={cardsByStatus[status.id] ?? []}
          style={styleByIndex({ idx, statuses })}
          isLocked
          userBoardPermissions={userBoardPermissions}
        />
      ))}
    </DragDropContext>
  ), [sortedStatuses, cardType, showCardNumber, cardsByStatus, isLocked, userBoardPermissions]);

  return (
    <Col style={{ overflowX: 'scroll' }}>
      {statusSections}
    </Col>
  );
}

BoardResultsList.propTypes = {
  boardId: PropTypes.string.isRequired,
  cardsByStatus: PropTypes.shape({}),
};

BoardResultsList.defaultProps = {
  cardsByStatus: {},
};
